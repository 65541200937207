import React, { useState, useMemo } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import Modal from 'components/Modal';
import { useAuth } from 'lib/providers/AuthProvider';
import { ExternalApply } from '../ExternalApply/ExternalApply.component';
import styles from './ExternalApplyModal.module.scss';

interface ModalInstanceProps {
  apply_url: string;
  isProfileCompExeptDemographicReq?: boolean;
  isProfileCompExceptDemographics?: boolean;
}

const ModalInstance: React.FC<ModalInstanceProps & InstanceProps<number>> = ({
  isOpen,
  onResolve,
  onReject,
  apply_url,
  isProfileCompExeptDemographicReq,
  isProfileCompExceptDemographics,
}) => {
  const [showNextModal, setShowNextModal] = useState(false);
  const { candidate } = useAuth();
  const {
    extension_installed,
    profile_complete,
    show_chrome_externsion_banner,
    show_complete_profile_banner,
  } = candidate || {};

  const isProfileComplete = isProfileCompExeptDemographicReq
    ? isProfileCompExceptDemographics
    : profile_complete;

  const renderModalContent = useMemo(() => {
    return !extension_installed || !isProfileComplete ? (
      !showNextModal && !isProfileComplete && show_complete_profile_banner ? (
        <ExternalApply
          onClose={onReject}
          onApplyOpportunity={onResolve}
          isProfileComplete={isProfileComplete || false}
          setShowNextModal={setShowNextModal}
          apply_url={apply_url}
        />
      ) : (
        show_chrome_externsion_banner &&
        !extension_installed && (
          <ExternalApply
            onClose={onReject}
            onApplyOpportunity={onResolve}
            isProfileComplete={true}
            setShowNextModal={setShowNextModal}
            apply_url={apply_url}
          />
        )
      )
    ) : null;
  }, [
    extension_installed,
    profile_complete,
    isProfileCompExceptDemographics,
    show_chrome_externsion_banner,
    show_complete_profile_banner,
    showNextModal,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
    >
      {renderModalContent}
    </Modal>
  );
};

export const externalApplyModalInstance = create(ModalInstance);
