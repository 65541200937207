import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';

import {
  useCompaniesSearch as useCompaniesSearchInternal,
  ReturnType as useCompaniesSearchReturnType,
} from 'hooks/useCompaniesSearch';

const initialContext: useCompaniesSearchReturnType = {
  companies: [],
  resetFilter: noop,
  search: '',
  setSearch: noop,
  size: 0,
  setSize: () => noop,
  hasMoreRecords: false,
  isLoading: false,
  selectedOptions: {},
  setSelectedOptions: noop,
  selectedLabelsRef: { current: {} },
  isLoadingMore: false,
};

const CompaniesSearchContext =
  createContext<useCompaniesSearchReturnType>(initialContext);

export const useCompaniesSearch = (): useCompaniesSearchReturnType =>
  useContext(CompaniesSearchContext);

export const CompaniesSearchProvider: React.FC = ({ children }) => {
  const value = useCompaniesSearchInternal();

  return (
    <CompaniesSearchContext.Provider value={value}>
      {children}
    </CompaniesSearchContext.Provider>
  );
};
