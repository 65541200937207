import cn from 'classnames';
import { noop } from 'lodash';
import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { IconButton, Link, Spinner } from 'components';
import { ActionOption } from 'components/ActionMenu';
import Logo from 'components/Logo';
import EntityCard from 'components/candidate/EntityCard';
import { registrationDetailsModalInstance } from 'components/candidate/RegistrationDetailsModal';
import TouchpointCard from 'components/candidate/TouchpointCard';
import { Button, Input } from 'components/form';
import { Row } from 'components/layout';
import {
  TouchpointNotifications,
  NotificationType,
} from 'components/candidate/Notifications/TouchpointNotifications.component';

import { ButtonV3 } from 'components/ComponentV2';
import { filterModalInstance } from 'components/candidate/FilterModal';
import { useCommon } from 'hooks/useCommon';
import useDebounce from 'hooks/useDebounce';
import { SelectedFilterOptions } from 'hooks/useFilter';
import { useNotification } from 'hooks/useNotification';
import { useResponsive } from 'hooks/useResponsive';
import { FLAG_STATUS_CODES, useCandidate } from 'hooks/useCandidate';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { withAuthCandidate } from 'lib/hocs/withAuthCandidate';
import { CompanyAttributes } from 'lib/models/company';
import { JobTouchpointable } from 'lib/models/job';
import { OpportunityAttributes, TouchpointState } from 'lib/models/opportunity';
import { StudentSocietyAttributes } from 'lib/models/student-society';
import { TagAttributes, TagType } from 'lib/models/tag';
import { TouchpointStatus, TouchpointType } from 'lib/models/touchpoint';
import { UniversityAttributes } from 'lib/models/university';
import { PAGE_ROUTES } from 'lib/page-routes';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { FollowingAttributes } from 'lib/models/following';
import { useManagerFilters } from 'lib/contexts/manager-filters-context';
import {
  ApplicationResponseModalEnum,
  CandidateApplicationType,
} from 'lib/models/candidate-applications';
import { EventTouchpointable } from 'lib/models/event';
import { useCandidateDashboard } from 'lib/providers/CandidateDashboardProvider';
import { getEventButtonText, getIndustryName } from 'utils/format';
import { getOpportunityPublicPagePathname } from 'utils/urls';
import { useManagerFollowings } from '../../../hooks/useManagerFollowings';
import { useManagerTouchpoints } from '../../../hooks/useManagerTouchpoints';
import { ApplicationBanner } from '../applications/ApplicationBanner/ApplicationBanner.component';
import ResponseModal from '../applications/ResponseModal/ResponseModal.component';
import ManagerFilters from './ManagerFilters/index.page';

import styles from './Manager.module.scss';

type StatusObject = {
  [key: string]: string;
};

export enum ManagerFilterType {
  Saved = 'Saved',
  Saved_Expired = 'Saved_Expired',
  Started = 'Started',
  Started_Expired = 'Started_Expired',
  Saved_And_Started = 'Saved_And_Started',
  Saved_And_Started_Expired = 'Saved_And_Started_Expired',
  Applied = 'Applied',
  Registered = 'Registered',
  Societies = 'Communities',
  Companies = 'Companies',
  Expired = 'Expired',
}

type ManagerData = {
  isVisible?: boolean;
  title?: string;
  displayTitle?: string;
  list?: OpportunityAttributes[] | FollowingAttributes[];
  size?: number;
  setSize?: (size: number) => void;
  hasMoreRecords?: boolean;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  metaInfo?: { total?: number; search_page?: string | null };
};
const convertToCandidateApplicationType = (list: OpportunityAttributes[]) => {
  return list.map((item) => ({
    id: item.id,
    title: item.title,
    kind: item.kind,
    touchpointable_type: item.touchpointable_type,
    creatable_for: item.creatable_for,
    apply_url: item.apply_url,
    slug: item.slug,
    posted_at: item.posted_at,
    is_eligible_for_auto_apply: item.is_eligible_for_auto_apply,
  }));
};

const countFilters = (obj: SelectedFilterOptions) => {
  let count = 0;

  for (const key in obj) {
    const value = obj[key];

    if (!value) {
      continue;
    }

    if (Array.isArray(value)) {
      count += value.length;
    } else if (typeof value === 'object') {
      count += countFilters(value);
    } else {
      count++;
    }
  }

  return count;
};

const isAnyLoading = (managerData: ManagerData[]) => {
  for (const data of managerData) {
    if (data.isLoading || data.isLoadingMore) {
      return true;
    }
  }

  return false;
};

const hasNonEmptyListProperty = (data: ManagerData[]) => {
  return data.some((item: ManagerData) => item.list && item.list.length > 0);
};

const Manager = (): ReactElement => {
  const screens = useResponsive();

  const [activeStatusTouchpoint, setActiveStatusTouchpoints] =
    useState<StatusObject>({});
  const [scrolled, setScrolled] = useState(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [isBannerShown, setIsBannerShown] = useState<boolean>(false);
  const [showResponseModal, setShowResponseModal] = useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] =
    useState<CandidateApplicationType>();
  const [modalType, setModalType] = useState<
    ApplicationResponseModalEnum | undefined
  >(ApplicationResponseModalEnum.questionResponse);

  const toggleBanner = () => {
    setShowBanner(!showBanner);
  };

  const toggleResponseModal =
    (modalType?: ApplicationResponseModalEnum) =>
    (application?: CandidateApplicationType) => {
      if (modalType === ApplicationResponseModalEnum.coverLetter) {
        redirectToCoverLetterBuilder(selectedApplication?.id);
      } else {
        setModalType(modalType);
        setShowResponseModal(!showResponseModal);
      }
      setSelectedApplication(application);
    };

  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);

  const [search, setSearch] = useState('');
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const debouncedSearch = useDebounce(search.trim(), 250);
  const {
    onShare,
    onApplyClick,
    sendInvalidURLReport,
    unfollowEntity,
    applyOpportunity,
    removeOpportunity,
  } = useCommon();
  const { redirectToCoverLetterBuilder } = useCandidate();
  const optionHeight = 44;

  const touchpointNotificationInstance = TouchpointNotifications();
  const { candidateDashboard } = useCandidateDashboard();

  const { extension_installed } = useMemo(() => {
    const { extension_installed } = candidateDashboard || {};

    return { extension_installed };
  }, [candidateDashboard]);

  const {
    selectedLabelsRef,
    selectedOptions,
    onClickFilter,
    onChangeFilter,
    setSelectedOptions,
    resetFilter,
  } = useManagerFilters();

  const selectedTouchpoint = Array.isArray(selectedOptions['touchpoint_type'])
    ? selectedOptions['touchpoint_type'][0]
    : selectedOptions['touchpoint_type'];

  const [showExpired, setShowExpired] = useState(false);

  const {
    touchpoints: savedTouchpoints,
    mutate: savedTouchpointsMutate,
    setSize: setSavedSize,
    size: savedSize,
    hasMoreRecords: hasMoreSavedRecords,
    isLoading: savedLoading,
    isLoadingMore: savedLoadingMore,
    touchPointsMetaInfo: savedMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Saved,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Saved ? selectedOptions : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: savedExpiredTouchpoints,
    mutate: savedExpiredTouchpointsMutate,
    setSize: setSavedExpiredSize,
    size: savedExpiredSize,
    hasMoreRecords: hasMoreSavedExpiredRecords,
    isLoading: savedExpiredLoading,
    isLoadingMore: savedExpiredLoadingMore,
    touchPointsMetaInfo: savedExpiredMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Saved_Expired,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Saved_Expired
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: startedTouchpoints,
    mutate: startedTouchpointsMutate,
    setSize: setStartedSize,
    size: startedSize,
    hasMoreRecords: hasMoreStartedRecords,
    isLoading: startedLoading,
    isLoadingMore: startedLoadingMore,
    touchPointsMetaInfo: startedMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Started,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Started ? selectedOptions : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: startedExpiredTouchpoints,
    mutate: startedExpiredTouchpointsMutate,
    setSize: setStartedExpiredSize,
    size: startedExpiredSize,
    hasMoreRecords: hasMoreStartedExpiredRecords,
    isLoading: startedExpiredLoading,
    isLoadingMore: startedExpiredLoadingMore,
    touchPointsMetaInfo: startedExpiredMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Started_Expired,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Started_Expired
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: savedAndStartedExpiredTouchpoints,
    mutate: savedAndStartedExpiredTouchpointsMutate,
    setSize: setSavedAndStartedExpiredSize,
    size: savedAndStartedExpiredSize,
    hasMoreRecords: hasMoreSavedAndStartedExpiredRecords,
    isLoading: savedAndStartedExpiredLoading,
    isLoadingMore: savedAndStartedExpiredLoadingMore,
    touchPointsMetaInfo: savedAndStartedExpiredMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Saved_And_Started_Expired,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Saved_And_Started_Expired
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: savedAndStartedTouchpoints,
    mutate: savedAndStartedTouchpointsMutate,
    setSize: setSavedAndStartedSize,
    size: savedAndStartedSize,
    hasMoreRecords: hasMoreSavedAndStartedRecords,
    isLoading: savedAndStartedLoading,
    isLoadingMore: savedAndStartedLoadingMore,
    touchPointsMetaInfo: savedAndStartedMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Saved_And_Started,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Saved_And_Started
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: appliedTouchpoints,
    mutate: appliedTouchpointsMutate,
    setSize: setAppliedSize,
    size: appliedSize,
    hasMoreRecords: hasMoreAppliedRecords,
    isLoading: appliedLoading,
    isLoadingMore: appliedLoadingMore,
    touchPointsMetaInfo: appliedMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Applied,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Applied ? selectedOptions : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    touchpoints: registeredTouchpoints,
    mutate: registeredTouchpointsMutate,
    setSize: setRegisteredSize,
    size: registeredSize,
    hasMoreRecords: hasMoreRegisteredRecords,
    isLoading: registeredLoading,
    isLoadingMore: registeredLoadingMore,
    touchPointsMetaInfo: registeredMetaInfo,
  } = useManagerTouchpoints({
    eventType: ManagerFilterType.Registered,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Registered
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    followings: followedSocieties,
    mutate: followedSocietiesMutate,
    setSize: setSocietiesSize,
    size: societiesSize,
    hasMoreRecords: hasMoreSocietiesRecords,
    isLoading: societiesLoading,
    isLoadingMore: societiesLoadingMore,
    followingsMetaInfo: societiesMetaInfo,
  } = useManagerFollowings({
    eventType: ManagerFilterType.Societies,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Societies
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const {
    followings: followedCompanies,
    mutate: followedCompaniesMutate,
    setSize: setCompaniedSize,
    size: companiesSize,
    hasMoreRecords: hasMoreCompaniesRecords,
    isLoading: companiesLoading,
    isLoadingMore: companiesLoadingMore,
    followingsMetaInfo: companiesMetaInfo,
  } = useManagerFollowings({
    eventType: ManagerFilterType.Companies,
    activeFilters:
      selectedTouchpoint === ManagerFilterType.Companies
        ? selectedOptions
        : null,
    search: debouncedSearch ? (debouncedSearch as string) : null,
  });

  const managerData = [
    {
      isVisible:
        !selectedTouchpoint || selectedTouchpoint === ManagerFilterType.Saved,
      title: ManagerFilterType.Saved,
      displayTitle: ManagerFilterType.Saved,
      list: savedTouchpoints,
      size: savedSize,
      setSize: setSavedSize,
      hasMoreRecords: hasMoreSavedRecords,
      isLoading: savedLoading,
      isLoadingMore: savedLoadingMore,
      metaInfo: savedMetaInfo,
    },
    {
      isVisible: showExpired && selectedTouchpoint === ManagerFilterType.Saved,
      title: ManagerFilterType.Saved_Expired,
      displayTitle: ManagerFilterType.Saved_Expired,
      list: savedExpiredTouchpoints,
      size: savedExpiredSize,
      setSize: setSavedExpiredSize,
      hasMoreRecords: hasMoreSavedExpiredRecords,
      isLoading: savedExpiredLoading,
      isLoadingMore: savedExpiredLoadingMore,
      metaInfo: savedExpiredMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint || selectedTouchpoint === ManagerFilterType.Started,
      title: ManagerFilterType.Started,
      displayTitle: ManagerFilterType.Started,
      list: startedTouchpoints,
      size: startedSize,
      setSize: setStartedSize,
      hasMoreRecords: hasMoreStartedRecords,
      isLoading: startedLoading,
      isLoadingMore: startedLoadingMore,
      metaInfo: startedMetaInfo,
    },
    {
      isVisible:
        showExpired && selectedTouchpoint === ManagerFilterType.Started,
      title: ManagerFilterType.Started_Expired,
      displayTitle: ManagerFilterType.Started_Expired,
      list: startedExpiredTouchpoints,
      size: startedExpiredSize,
      setSize: setStartedExpiredSize,
      hasMoreRecords: hasMoreStartedExpiredRecords,
      isLoading: startedExpiredLoading,
      isLoadingMore: startedExpiredLoadingMore,
      metaInfo: startedExpiredMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint ||
        selectedTouchpoint === ManagerFilterType.Saved_And_Started,
      title: ManagerFilterType.Saved_And_Started,
      displayTitle: ManagerFilterType.Saved_And_Started,
      list: savedAndStartedTouchpoints,
      size: savedAndStartedSize,
      setSize: setSavedAndStartedSize,
      hasMoreRecords: hasMoreSavedAndStartedRecords,
      isLoading: savedAndStartedLoading,
      isLoadingMore: savedAndStartedLoadingMore,
      metaInfo: savedAndStartedMetaInfo,
    },
    {
      isVisible:
        showExpired &&
        ![ManagerFilterType.Saved, ManagerFilterType.Started].includes(
          selectedTouchpoint as ManagerFilterType
        ),
      title: ManagerFilterType.Saved_And_Started_Expired,
      displayTitle: ManagerFilterType.Saved_And_Started_Expired,
      list: savedAndStartedExpiredTouchpoints,
      size: savedAndStartedExpiredSize,
      setSize: setSavedAndStartedExpiredSize,
      hasMoreRecords: hasMoreSavedAndStartedExpiredRecords,
      isLoading: savedAndStartedExpiredLoading,
      isLoadingMore: savedAndStartedExpiredLoadingMore,
      metaInfo: savedAndStartedExpiredMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint || selectedTouchpoint === ManagerFilterType.Applied,
      title: ManagerFilterType.Applied,
      displayTitle: ManagerFilterType.Applied,
      list: appliedTouchpoints,
      size: appliedSize,
      setSize: setAppliedSize,
      hasMoreRecords: hasMoreAppliedRecords,
      isLoading: appliedLoading,
      isLoadingMore: appliedLoadingMore,
      metaInfo: appliedMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint ||
        selectedTouchpoint === ManagerFilterType.Registered,
      title: ManagerFilterType.Registered,
      displayTitle: ManagerFilterType.Registered,
      list: registeredTouchpoints,
      size: registeredSize,
      setSize: setRegisteredSize,
      hasMoreRecords: hasMoreRegisteredRecords,
      isLoading: registeredLoading,
      isLoadingMore: registeredLoadingMore,
      metaInfo: registeredMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint ||
        selectedTouchpoint === ManagerFilterType.Societies,
      title: ManagerFilterType.Societies,
      displayTitle: 'My communities',
      list: followedSocieties,
      size: societiesSize,
      setSize: setSocietiesSize,
      hasMoreRecords: hasMoreSocietiesRecords,
      isLoading: societiesLoading,
      isLoadingMore: societiesLoadingMore,
      metaInfo: societiesMetaInfo,
    },
    {
      isVisible:
        !selectedTouchpoint ||
        selectedTouchpoint === ManagerFilterType.Companies,
      title: ManagerFilterType.Companies,
      displayTitle: 'Following companies',
      list: followedCompanies,
      size: companiesSize,
      setSize: setCompaniedSize,
      hasMoreRecords: hasMoreCompaniesRecords,
      isLoading: companiesLoading,
      isLoadingMore: companiesLoadingMore,
      metaInfo: companiesMetaInfo,
    },
  ];

  const savedApplications = convertToCandidateApplicationType(
    managerData[0]?.list as OpportunityAttributes[]
  );
  const startedApplications = convertToCandidateApplicationType(
    managerData[1]?.list as OpportunityAttributes[]
  );
  const applications = [...savedApplications, ...startedApplications];

  const filteredManagerData = selectedTouchpoint
    ? managerData
    : managerData.filter(
        ({ title }) =>
          title !== ManagerFilterType.Saved &&
          title !== ManagerFilterType.Started
      );

  const router = useRouter();

  useEffect(() => {
    setShowExpired(false);
  }, [router]);

  const noDataInfo = [
    {
      id: 1,
      menu: selectedTouchpoint,
      emoji: '🙁',
      title: '',
      description: 'No results',
    },
    {
      id: 1,
      menu: ManagerFilterType.Saved || ManagerFilterType.Saved_And_Started,
      emoji: '😱',
      title: 'Nothing saved',
      description:
        'No worries, just check your matches or browse through other opportunities and hit save on the ones you like.',
    },
    {
      id: 2,
      menu: ManagerFilterType.Started,
      emoji: '😱',
      title: 'Nothing started',
      description:
        'No worries, just check your matches or browse through other opportunities and hit apply on the ones you like.',
    },
    {
      id: 3,
      menu: ManagerFilterType.Applied,
      emoji: '🙄',
      title: 'No applications',
      description:
        'Any job, event or internship you apply/register to will show up here so you can manage your applications and check on their progress.',
    },
    {
      id: 4,
      menu: ManagerFilterType.Registered,
      emoji: '👽',
      title: 'You’ll get there!',
      description:
        'With Huzzle you get matched to jobs and internships you’re likely to get accepted to. Move them here when you’re accepted.',
    },
    {
      id: 5,
      menu: ManagerFilterType.Societies,
      emoji: '🐶',
      title: 'Much Wow, Such empty',
      description:
        'Follow companies or communities to be updated on new jobs and opportunities they post.',
    },
    {
      id: 6,
      menu: ManagerFilterType.Companies,
      emoji: '🐶',
      title: 'Much Wow, Such empty',
      description:
        'Follow companies or communities to be updated on new jobs and opportunities they post.',
    },
    {
      id: 7,
      menu: ManagerFilterType.Saved_And_Started,
      emoji: '😱',
      title: 'Nothing saved',
      description:
        'No worries, just check your matches or browse through other opportunities and hit save on the ones you like.',
    },
  ];

  const onUnfollow = async (id: string, entityType: string) => {
    try {
      const response = await unfollowEntity({ id, headers });
      if (response) {
        if (entityType === 'Company') {
          notificationInstance.success({
            title: 'Unfollowed company successfully',
            message: '',
          });
          followedCompaniesMutate();
        } else if (entityType === 'Society') {
          notificationInstance.success({
            title: 'Left community successfully',
            message: '',
          });
          followedSocietiesMutate();
        }
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const loadTouchPoints = (currentStatus: string) => {
    if (currentStatus === 'saved' || currentStatus === 'started') {
      savedTouchpointsMutate();
      startedTouchpointsMutate();
      savedAndStartedTouchpointsMutate();
      savedAndStartedExpiredTouchpointsMutate();
      savedExpiredTouchpointsMutate();
      startedExpiredTouchpointsMutate();
    }
  };

  const onReportItemClick = async (
    id: string,
    status: string,
    currentStatus: string
  ) => {
    try {
      const response = await removeOpportunity(id, headers, status);
      if (response) {
        loadTouchPoints(currentStatus);
        notificationInstance.success({
          title: 'Success!',
          message: `Opportunity ${status} successfully.`,
        });
      } else {
        let errorMessage = '';
        if (['applied', 'accepted'].includes(currentStatus)) {
          if (status === 'removed') {
            errorMessage =
              "You have applied / registered through Huzzle so you can't remove this";
          } else {
            errorMessage =
              'You have applied / registered through Huzzle so we automatically change the status for you accordingly';
          }
        } else if (
          currentStatus === 'saved' &&
          ['applied', 'accepted'].includes(status)
        ) {
          errorMessage = 'Please apply through Huzzle first';
        }
        notificationInstance.error({
          title: 'Error!',
          message: errorMessage,
        });
      }
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onApplyOpportunityMark = async (id: string) => {
    if (id) {
      try {
        const response = await applyOpportunity(id);
        if (response) {
          setActiveStatusTouchpoints({
            ...activeStatusTouchpoint,
            [id]: TouchpointStatus.APPLIED,
          });
          savedTouchpointsMutate();
          startedTouchpointsMutate();
          savedAndStartedTouchpointsMutate();
          appliedTouchpointsMutate();
          registeredTouchpointsMutate();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      return;
    }
  };
  const onApplyOpportunity = async (
    id: string,
    apply_url: string,
    type: string,
    willJoinWaitlist: boolean
  ) => {
    if (id) {
      await onApplyClick({ touchpointId: id });
      setActiveStatusTouchpoints({
        ...activeStatusTouchpoint,
        [id]: TouchpointStatus.STARTED,
      });
      loadTouchPointsData();
      if (apply_url) {
        window.open(apply_url, '_blank');
      } else {
        try {
          const flag = await registrationDetailsModalInstance({
            touchpointId: id,
            className: styles.registrationModal,
          });
          if (
            flag === FLAG_STATUS_CODES.NEW_USER_REGISTER_SUCCESS ||
            flag === FLAG_STATUS_CODES.EXISTING_USER_REGISTER_SUCCESS
          ) {
            if (type === TouchpointType.Event) {
              touchpointNotificationInstance({
                notificationType: willJoinWaitlist
                  ? NotificationType.INTERNAL_EVENT_WAITLIST
                  : NotificationType.INTERNAL_EVENT_REGISTERED,
              });
            } else {
              touchpointNotificationInstance({
                notificationType: NotificationType.INTERNAL_JOB_APPLY,
                type: type,
              });
            }
            loadTouchPointsData();
          } else {
            return;
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const getSavedActionMenu = (
    id: string,
    apply_url: string,
    title: string,
    publicUrl: string,
    applied: boolean,
    type: string,
    started: boolean | null,
    touchpointableResponse: EventTouchpointable,
    state: TouchpointState,
    applications: number,
    is_eligible_for_auto_apply: boolean
  ) => {
    const touchpointable = parseResponse(
      touchpointableResponse as EventTouchpointable
    );
    const {
      enforce_capacity,
      waitlist,
      enforce_waitlist_for_all,
      capacity,
      is_capacity_reached,
    } = touchpointable;
    const willJoinWaitlist =
      enforce_waitlist_for_all || (is_capacity_reached && waitlist);

    return [
      {
        id: '1',
        label:
          type === TouchpointType.Event
            ? getEventButtonText(
                state,
                enforce_capacity || false,
                capacity || 0,
                (touchpointable && capacity - applications) || undefined,
                waitlist || false,
                enforce_waitlist_for_all || false
              )
            : applied
            ? 'Applied'
            : is_eligible_for_auto_apply
            ? 'Quick Apply'
            : 'Apply',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onApplyOpportunity(id, apply_url, type, willJoinWaitlist);
          event.stopPropagation();
        },
      },
      ...(started
        ? [
            {
              id: '2',
              label:
                type === TouchpointType.Event
                  ? 'Mark as registered'
                  : 'Mark as applied',
              onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault();
                onApplyOpportunityMark(id);
                event.stopPropagation();
              },
            },
          ]
        : []),
      {
        id: '3',
        label: 'Share',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onShare(title, publicUrl);
        },
      },
      {
        id: '4',
        label: 'Report',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          sendInvalidURLReport({ id });
        },
      },
      {
        id: '5',
        label: 'Remove',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onReportItemClick(id, 'removed', 'saved');
        },
        danger: true,
      },
    ];
  };

  const getExpiredActionMenu = (
    id: string,
    title: string,
    publicUrl: string
  ) => {
    return [
      {
        id: '1',
        label: 'Share',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onShare(title, publicUrl);
        },
      },
      {
        id: '3',
        label: 'Remove',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onReportItemClick(id, 'removed', 'expired');
        },
        danger: true,
      },
    ];
  };

  const getAppliedActionMenu = (
    id: string,
    title: string,
    publicUrl: string
  ) => {
    return [
      {
        id: '2',
        label: 'Share',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onShare(title, publicUrl);
        },
      },
    ];
  };

  const getAcceptedActionMenu = (
    id: string,
    title: string,
    publicUrl: string
  ) => {
    return [
      {
        id: '1',
        label: 'Share',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onShare(title, publicUrl);
        },
      },
    ];
  };

  const getFollowedActionMenu = (
    id: string,
    name: string,
    publicUrl: string,
    entityId: string,
    entityType: string
  ) => {
    return [
      {
        id: '1',
        label: entityType === 'Company' ? 'Unfollow' : 'Leave',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onUnfollow(id, entityType);
        },
      },
      {
        id: '2',
        label: 'Share',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          onShare(name, publicUrl);
        },
      },
    ];
  };

  const getFilteredCompanies = (companies: FollowingAttributes[]) => {
    return companies?.map(({ id, name, icon_url, followable }) => {
      const companyAttributes = followable.data.attributes as CompanyAttributes;
      const {
        address,
        is_verified,
        industry: industryResponse,
        slug,
        entityId,
        custom_industry_name,
        huzzle_verified,
      } = companyAttributes;
      const industryInfo =
        parseResponse<TagAttributes<TagType.Industry>>(industryResponse);
      const industry = getIndustryName(custom_industry_name, industryInfo);

      const publicPageUrl = `${window.location.origin}/companies/${slug}`;

      return (
        <div key={id} className={styles.touchpointCard}>
          <EntityCard
            className={styles.whiteCard}
            cardClassName={styles.touchCard}
            icon_url={icon_url}
            title={name}
            address={address}
            is_verified={is_verified}
            industry={industry}
            url={publicPageUrl}
            menuOptions={
              getFollowedActionMenu(
                id,
                name,
                publicPageUrl,
                entityId || '',
                'Company'
              ) as Array<ActionOption>
            }
            triggerIconClassName={styles.menuTrigger}
            listClassName={styles.menuList}
            huzzle_verified={huzzle_verified}
          />
        </div>
      );
    });
  };

  const getSocietiesCard = (societies: FollowingAttributes[]) => {
    return societies?.map(({ id, name, icon_url, followable }) => {
      const societyAttributes = followable.data
        .attributes as StudentSocietyAttributes;

      const {
        address,
        university: universityResponse,
        slug,
        entityId,
        is_verified,
        huzzle_verified,
      } = societyAttributes;

      const university =
        parseResponse<UniversityAttributes>(universityResponse);

      const publicPageUrl = `${window.location.origin}/societies/${slug}`;
      return (
        <div key={id} className={styles.touchpointCard}>
          <EntityCard
            className={styles.whiteCard}
            cardClassName={styles.touchCard}
            key={id}
            icon_url={icon_url}
            title={name}
            address={address}
            universityName={university?.name}
            url={publicPageUrl}
            menuOptions={
              getFollowedActionMenu(
                id,
                name,
                publicPageUrl,
                entityId || '',
                'Society'
              ) as Array<ActionOption>
            }
            triggerIconClassName={styles.menuTrigger}
            listClassName={styles.menuList}
            is_verified={is_verified}
            huzzle_verified={huzzle_verified}
          />
        </div>
      );
    });
  };

  const loadTouchPointsData = () => {
    return Promise.all([
      savedTouchpointsMutate(),
      startedTouchpointsMutate(),
      savedAndStartedTouchpointsMutate(),
      appliedTouchpointsMutate(),
      registeredTouchpointsMutate(),
    ])
      .then(() => {
        return undefined;
      })
      .catch((error) => {
        console.error(error);
        return undefined;
      });
  };

  const getTouchpointCard = (
    touchpoints: OpportunityAttributes[],
    action_type: string
  ) => {
    return touchpoints?.map(
      ({
        id,
        title,
        start_date,
        creatable_for,
        state,
        application_deadline,
        posted_at,
        created_at,
        touchpointable_type,
        touchpointable: touchpointableResponse,
        apply_url,
        is_applied,
        slug,
        pay_currency,
        cities,
        started,
        cohost_applications: cohostApplicationsResponse,
        following: followingResponse,
        applications,
        is_eligible_for_auto_apply,
      }) => {
        const createdBy = parseResponse<
          CompanyAttributes | StudentSocietyAttributes
        >(creatable_for);

        const isExpired = state === 'expired';
        const { name, icon_url, is_verified, huzzle_verified } = createdBy;
        let maximumSalary, minimumSalary;
        if (
          touchpointable_type === TouchpointType.Job ||
          touchpointable_type === TouchpointType.Internship
        ) {
          const touchpointable = parseResponse(
            touchpointableResponse as JobTouchpointable
          );
          maximumSalary = touchpointable?.maximum_salary;
          minimumSalary = touchpointable?.minimum_salary;
        }
        const publicPageUrl = `${
          window.location.origin
        }${getOpportunityPublicPagePathname(touchpointable_type, slug)}`;

        const getMenuOptions = (id: string) => {
          if (isExpired) return getExpiredActionMenu(id, title, publicPageUrl);
          if (action_type === ManagerFilterType.Applied) {
            return getAppliedActionMenu(id, title, publicPageUrl);
          } else if (action_type === ManagerFilterType.Registered) {
            return getAcceptedActionMenu(id, title, publicPageUrl);
          } else {
            return getSavedActionMenu(
              id,
              apply_url,
              title,
              publicPageUrl,
              is_applied,
              touchpointable_type as string,
              started,
              touchpointableResponse as EventTouchpointable,
              state,
              applications,
              is_eligible_for_auto_apply
            );
          }
        };

        const cohost_applications = parseArrayResponse(
          cohostApplicationsResponse
        );
        const following = parseResponse<FollowingAttributes>(followingResponse);

        const showApplicationProgress =
          action_type === ManagerFilterType.Saved ||
          action_type === ManagerFilterType.Started ||
          action_type === ManagerFilterType.Saved_And_Started;
        return (
          <div
            key={id}
            className={cn(styles.touchpointCard, {
              [styles.touchpointCardScrolled]: scrolled,
            })}
          >
            <TouchpointCard
              icon_url={icon_url}
              id={id}
              title={title}
              slug={slug}
              createdBy={name}
              touchpointable={
                TouchpointType.Event
                  ? (touchpointableResponse as EventTouchpointable | undefined)
                  : null
              }
              applications={applications}
              state={state}
              startDate={start_date}
              created_at={posted_at ? posted_at : created_at}
              application_deadline={application_deadline}
              is_verified={is_verified}
              touchpointable_type={touchpointable_type}
              maximum_salary={maximumSalary}
              minimum_salary={minimumSalary}
              pay_currency={pay_currency || 'GBP'}
              isExpired={isExpired}
              menuOptions={getMenuOptions(id) as Array<ActionOption>}
              triggerIconClassName={styles.menuTrigger}
              listClassName={styles.menuList}
              url={publicPageUrl}
              isEasyApply={
                touchpointable_type !== TouchpointType.Event && !apply_url
              }
              cities={cities}
              huzzle_verified={huzzle_verified}
              isNewSet={false}
              showManagerProgress={showApplicationProgress}
              cohost_applications={cohost_applications}
              following={following}
              activeStatus={
                activeStatusTouchpoint[id] ? activeStatusTouchpoint[id] : null
              }
              isStarted={started}
              apply_url={apply_url}
              is_eligible_for_auto_apply={is_eligible_for_auto_apply}
              extension_installed={extension_installed || true}
              mutateApi={loadTouchPointsData}
              optionHeight={optionHeight}
              showOptionFullContent
              className={cn({
                [styles.whiteCard]: ![
                  ManagerFilterType.Saved_And_Started_Expired,
                  ManagerFilterType.Started_Expired,
                  ManagerFilterType.Saved_Expired,
                ].includes(action_type as ManagerFilterType),
                [styles.savedAndStartedExpired]: [
                  ManagerFilterType.Saved_And_Started_Expired,
                  ManagerFilterType.Started_Expired,
                  ManagerFilterType.Saved_Expired,
                ].includes(action_type as ManagerFilterType),
              })}
            />
          </div>
        );
      }
    );
  };

  useEffect(() => {
    if (applications?.length > 0 && !isBannerShown) {
      setIsBannerShown(true);
      setShowBanner(true);
    }
  }, [applications]);

  useEffect(() => {
    if (router.pathname === PAGE_ROUTES.MANAGER) {
      savedTouchpointsMutate();
      startedTouchpointsMutate();
      savedAndStartedTouchpointsMutate();
      appliedTouchpointsMutate();
      registeredTouchpointsMutate();
      followedSocietiesMutate();
      followedCompaniesMutate();
    }
  }, []);

  useEffect(() => {
    const { status, entity } = router.query;

    if (
      entity === ManagerFilterType.Societies ||
      entity === ManagerFilterType.Companies
    ) {
      setSelectedOptions({
        ...selectedOptions,
        touchpoint_type: entity,
      });
    } else if (status) {
      setSelectedOptions({
        ...selectedOptions,
        touchpoint_type: status,
        touchpointable_types: entity,
      });
    } else {
      setSelectedOptions({});
    }
  }, []);

  useEffect(() => {
    function handler() {
      setScrolled(document.documentElement.scrollTop > 100);
    }

    document.addEventListener('scroll', handler);

    return () => {
      document.removeEventListener('scroll', handler);
    };
  }, []);

  const hasListWithValues = hasNonEmptyListProperty(filteredManagerData);
  const hasLoading = isAnyLoading(managerData);

  const noDataDetails = useMemo(() => {
    return (
      <>
        {!hasLoading && !hasListWithValues && !isSearchClicked && (
          <div className={cn(styles.nodata, styles.nodataCentered)}>
            <p className={styles.nodataEmoji}>😱</p>
            <p className={styles.nodataTitle}>Nothing to see here</p>
            <p className={styles.nodataDescription}>
              No worries, just check your matches or browse through other
              opportunities and hit save on the ones you like.
            </p>
            <div className={styles.nodataButtonGroup}>
              <ButtonV3
                color="primary"
                size="large"
                borderless
                onClick={() => router.push(PAGE_ROUTES.CANDIDATE_DASHBOARD)}
              >
                Check matches
              </ButtonV3>
              <ButtonV3
                color="secondary"
                size="large"
                borderless
                onClick={() => router.push(PAGE_ROUTES.SEARCH_RESULTS)}
              >
                Explore opportunities
              </ButtonV3>
            </div>
          </div>
        )}
      </>
    );
  }, [hasLoading, hasListWithValues, isSearchClicked]);

  const onFilterClick = async () => {
    try {
      await filterModalInstance();
    } catch (error) {
      noop;
    }
  };

  const onClickFilters = (key: string, value: string) => {
    onClickFilter(key, value);

    const qs = getQueryString(key, value);
    if (qs) {
      router.push({ pathname: '/candidate/manager', query: qs });
    }
  };

  const getQueryString = (
    key: string,
    value: string
  ): { status?: string; entity?: string } | null => {
    const status = Array.isArray(router.query.status)
      ? router.query.status[0]
      : router.query.status;

    if (value) {
      const isSocietiesOrCompanies =
        value === ManagerFilterType.Societies ||
        value === ManagerFilterType.Companies;
      const isOpportunityType =
        key === 'touchpointable_types' && !isSocietiesOrCompanies;
      const isTouchpointType =
        key === 'touchpoint_type' && !isSocietiesOrCompanies;
      const isFollowedType =
        key === 'touchpoint_type' && isSocietiesOrCompanies;

      if (isTouchpointType) {
        return {
          status: value,
        };
      }
      if (isOpportunityType || isFollowedType) {
        return {
          status: isFollowedType ? 'Following' : status,
          entity: value,
        };
      }
    }

    return null;
  };

  const isFiltersModalDisabled = () => {
    const entity = Array.isArray(router.query.entity)
      ? router.query.entity[0]
      : router.query.entity;

    if (entity) {
      return false;
    }

    return true;
  };

  const filtersCount = useMemo(() => {
    const {
      touchpoint_type,
      touchpointable_type,
      touchpointable_types,
      ...rest
    } = selectedOptions;
    return countFilters(rest);
  }, [selectedOptions]);

  return (
    <>
      <div className={styles.Manager} data-testid="Saved">
        {showBanner && (
          <ApplicationBanner
            className={styles.ManagerBanner}
            applications={applications}
            onClose={toggleBanner}
            openResponseModal={toggleResponseModal(
              ApplicationResponseModalEnum.questionResponse
            )}
            openCoverLetterModal={toggleResponseModal(
              ApplicationResponseModalEnum.coverLetter
            )}
          />
        )}
        <div className={styles.innerMain}>
          <div
            className={cn(styles.filterContainer, {
              [styles.scrolled]: scrolled,
            })}
          >
            <div>
              {scrolled && !screens.sm && (
                <Link
                  href={PAGE_ROUTES.CANDIDATE_DASHBOARD}
                  className={styles.logoLink}
                >
                  <Logo name="huzzle-new" size="small" color="black" />
                </Link>
              )}
              <div>
                <div className={styles.filter}>
                  <div className={styles.actions}>
                    {isSearchClicked ? (
                      <>
                        <Input
                          className={styles.searchInput}
                          placeholder="Search"
                          startIcon="search"
                          startIconSize="medium"
                          startIconClassName={styles.startIcon}
                          endIconSize="xsmall"
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          endIcon={undefined}
                          onStartIconClick={() => setIsSearchClicked(false)}
                          onEndIconClick={() =>
                            search ? setSearch('') : setIsSearchClicked(false)
                          }
                        />
                        <div className={styles.cancelContiner}>
                          <button
                            className={styles.cancelButton}
                            onClick={() => {
                              setSearch('');
                              setIsSearchClicked(false);
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </>
                    ) : (
                      <div>
                        <p className={styles.Title}>Manage</p>
                      </div>
                    )}
                  </div>
                  <div className={styles.icons}>
                    {!isSearchClicked && (
                      <>
                        <IconButton
                          iconName="search"
                          iconClassName={styles.search}
                          onClick={() => setIsSearchClicked(true)}
                          disabled={!hasLoading && !hasListWithValues}
                        />
                        <IconButton
                          iconName="icon_filter_2"
                          size={'xmediumplus'}
                          className={styles.iconsButton}
                          iconClassName={cn(styles.iconsFilter, {
                            [styles.searchSelectedFilter]: true,
                            [styles.iconsFilterDisabled]:
                              isFiltersModalDisabled(),
                            [styles.iconsFilterApplied]: filtersCount > 0,
                          })}
                          onClick={onFilterClick}
                          disabled={isFiltersModalDisabled()}
                        >
                          {filtersCount > 0 ? (
                            <div className={cn(styles.iconsFilterCount)}>
                              {filtersCount}
                            </div>
                          ) : null}
                        </IconButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {noDataDetails}

          {(hasListWithValues || isSearchClicked) && (
            <ManagerFilters
              filters={selectedOptions}
              onChangeFilter={onChangeFilter}
              onClickFilter={onClickFilters}
              resetFilter={resetFilter}
              setSelectedOptions={setSelectedOptions}
              selectedLabelsRef={selectedLabelsRef}
            />
          )}
          {(hasListWithValues || isSearchClicked) &&
            filteredManagerData.map(
              (
                {
                  isVisible,
                  title,
                  displayTitle,
                  list,
                  size,
                  setSize,
                  hasMoreRecords,
                  isLoading,
                  isLoadingMore,
                  metaInfo,
                },
                index
              ) => {
                const noDataDetails = noDataInfo.find(
                  (item) => item.menu === title
                );

                if (isVisible) {
                  return (
                    <div
                      key={index}
                      className={cn(styles.ManagerItem, {
                        [styles.bottomMargin12]:
                          title === ManagerFilterType.Saved_And_Started &&
                          list.length === metaInfo.total,
                      })}
                    >
                      {title &&
                      displayTitle &&
                      ![
                        ManagerFilterType.Saved_And_Started,
                        ManagerFilterType.Started,
                        ManagerFilterType.Saved,
                      ].includes(title as ManagerFilterType) &&
                      ![
                        ManagerFilterType.Saved_And_Started_Expired,
                        ManagerFilterType.Saved_Expired,
                        ManagerFilterType.Started_Expired,
                      ].includes(title as ManagerFilterType) &&
                      !selectedTouchpoint ? (
                        <div className={styles.title}>
                          <div>
                            <p>{displayTitle}</p>
                            {title === ManagerFilterType.Expired ? (
                              <span>{metaInfo?.total}</span>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {isLoading ? (
                        <Spinner wrapperClassname={styles.spinnerHeight} />
                      ) : list?.length === 0 ? (
                        <div className={styles.nodata}>
                          <p className={styles.nodataEmoji}>
                            {noDataDetails?.emoji}
                          </p>
                          {noDataDetails?.title ? (
                            <p className={styles.nodataTitle}>
                              {noDataDetails?.title}
                            </p>
                          ) : null}
                          <p className={styles.nodataDescription}>
                            {noDataDetails?.description}
                          </p>
                        </div>
                      ) : (
                        <div>
                          {title !== ManagerFilterType.Societies &&
                            title !== ManagerFilterType.Companies &&
                            title !== ManagerFilterType.Expired &&
                            getTouchpointCard(
                              list as OpportunityAttributes[],
                              title
                            )}
                          {title == ManagerFilterType.Societies &&
                            getSocietiesCard(list as FollowingAttributes[])}
                          {title == ManagerFilterType.Companies &&
                            getFilteredCompanies(list as FollowingAttributes[])}
                          {!isLoading && isLoadingMore ? (
                            <Spinner wrapperClassname={styles.spinnerHeight} />
                          ) : null}
                          {size && list?.length > 0 ? (
                            ((ManagerFilterType.Saved_And_Started === title &&
                              savedAndStartedExpiredMetaInfo.total) ||
                              (ManagerFilterType.Saved === title &&
                                savedExpiredMetaInfo.total) ||
                              (ManagerFilterType.Started === title &&
                                startedExpiredMetaInfo.total)) &&
                            list.length === metaInfo.total ? (
                              !showExpired && (
                                <Row
                                  align="center"
                                  columnGap={8}
                                  className={styles.countsRow}
                                >
                                  <Button
                                    variant="text"
                                    color="blue"
                                    onClick={() => {
                                      setShowExpired(true);
                                    }}
                                    className={styles.countsButton}
                                  >
                                    Show{' '}
                                    {title ===
                                    ManagerFilterType.Saved_And_Started
                                      ? savedAndStartedExpiredMetaInfo.total
                                      : title === ManagerFilterType.Saved
                                      ? savedExpiredMetaInfo.total
                                      : title === ManagerFilterType.Started
                                      ? startedExpiredMetaInfo.total
                                      : 0}{' '}
                                    closed
                                  </Button>
                                </Row>
                              )
                            ) : (
                              <Row
                                align="center"
                                columnGap={8}
                                className={styles.countsRow}
                              >
                                {list?.length ? (
                                  <p>
                                    Showing {list?.length} of {metaInfo?.total}
                                  </p>
                                ) : null}
                                {hasMoreRecords ? (
                                  <Button
                                    variant="text"
                                    color="blue"
                                    onClick={() => {
                                      setSize(size + 1);
                                    }}
                                    className={styles.countsButton}
                                  >
                                    Show more
                                  </Button>
                                ) : null}
                              </Row>
                            )
                          ) : null}
                        </div>
                      )}
                    </div>
                  );
                }
              }
            )}
        </div>
      </div>
      <ResponseModal
        modalType={modalType}
        isOpen={showResponseModal}
        application={selectedApplication}
        onClose={toggleResponseModal()}
      />
    </>
  );
};

const ManagerWrapper = withAuthCandidate(Manager);

export default ManagerWrapper;
