import React, { Dispatch, SetStateAction, memo, useMemo } from 'react';

import { noop } from 'lodash';
import Locations from 'components/candidate/Onboarding/Locations';
import RemotePreference from 'components/candidate/Onboarding/RemotePreference';
import { SelectedFilterOptions } from 'hooks/useFilter';
import { useCommonList } from 'lib/contexts/common-list-context';

export interface LocationFilterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
}

export const LocationFilter: React.FC<LocationFilterProps> = memo(
  ({ selectedOptions, setSelectedOptions }: LocationFilterProps) => {
    const { cityCountryList, countries } = useCommonList();

    const candidateLocations = useMemo(() => {
      return (selectedOptions?.city_ids as Array<string>) || [];
    }, [selectedOptions]);

    const candidateChoice = useMemo(() => {
      return (selectedOptions?.country_ids as Array<string>) || [];
    }, [selectedOptions]);

    const content = useMemo(() => {
      return selectedOptions?.is_online_selected ? (
        <RemotePreference
          candidateChoice={candidateChoice}
          countries={countries}
          onNextClick={noop}
          isLoading={false}
          isBackLoading={false}
          isMatchingPreferences={false}
          openOnModal={true}
          setSelectedOptions={setSelectedOptions}
        />
      ) : (
        <Locations
          cityCountryList={cityCountryList}
          countries={countries}
          candidateLocations={candidateLocations}
          remote_opportunity={false}
          onNextClick={noop}
          isLoading={false}
          isBackLoading={false}
          isMatchingPreferences={false}
          openOnModal={true}
          setSelectedOptions={setSelectedOptions}
        />
      );
    }, [selectedOptions, candidateLocations, candidateChoice]);

    return content;
  }
);

LocationFilter.displayName = 'LocationFilter';
