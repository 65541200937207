import { useRecoilValue } from 'recoil';
import useSWRInfinite from 'swr/infinite';
import queryString from 'query-string';
import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import { ManagerFilterType } from 'pages/candidate/manager/Manager.component';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { Option } from 'lib/models/option';
import { TagAttributes, TagsResponse, TagType } from 'lib/models/tag';
import { parseArrayResponse } from 'lib/utils/parser';
import {
  UniversitiesResponse,
  UniversityAttributes,
} from 'lib/models/university';
import { BenefitAttributes, BenefitsResponse } from 'lib/models/benefit';
import {
  EventCategoriesList,
  EventCategoriesAttributes,
} from 'lib/models/event-categories';
import {
  OpportunityTypesResponse,
  OpportunitiesResponse,
} from 'lib/models/opportunity';

import { PAGE_ROUTES } from 'lib/page-routes';
import { CompaniesResponse } from 'lib/models/company';
import { StudentSocietiesResponse } from 'lib/models/student-society';

import { useCompaniesSearch } from 'lib/contexts/companies-search-context';
import { useSocietiesSearch } from 'lib/contexts/societies-search-context';
import { useOpportunitiesSearch } from 'lib/contexts/opportunities-search-context';
import { useCommonList } from 'lib/contexts/common-list-context';
import { TouchpointType } from 'lib/models/touchpoint';
import { useManagerFilters } from 'lib/contexts/manager-filters-context';
import { useNotification } from './useNotification';

export enum fieldType {
  SINGLE_SELECT_CHIP = 'single-select-chip',
  MULTI_SELECT_CHIP = 'multi-select-chip',
  SINGLE_SELECT_TICK = 'single-select-tick',
  MULTI_SELECT_TICK = 'multi-select-tick',
  TOGGLE = 'toggle',
}

export type SelectedFilterOptions = Record<
  string,
  Array<string> | string | boolean | undefined
>;

export type SelectedFilterOptionLabels = Record<string, Array<string> | string>;

interface ReturnType {
  getOptions: (field: string) => Promise<Array<Option> | null | undefined>;
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  total: number | undefined;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
}

export const useFilter = (): ReturnType => {
  const router = useRouter();
  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);

  const { cityCountryList, countries } = useCommonList();

  const selectedLabelsRef = useRef<SelectedFilterOptionLabels>({});
  const [selectedOptions, setSelectedOptions] = useState<SelectedFilterOptions>(
    {}
  );
  const {
    selectedOptions: selectedCompanyOptions,
    selectedLabelsRef: selectedCompanyLabelsRef,
  } = useCompaniesSearch();
  const {
    selectedOptions: selectedSocietyOptions,
    selectedLabelsRef: selectedSocietyLabelsRef,
  } = useSocietiesSearch();
  const {
    selectedOptions: selectedOpportunitiesOptions,
    selectedLabelsRef: selectedOpportunitiesLabelsRef,
  } = useOpportunitiesSearch();
  const {
    selectedOptions: selectedManagerOptions,
    selectedLabelsRef: selectedManagerLabelsRef,
  } = useManagerFilters();

  useEffect(() => {
    if (router.pathname === PAGE_ROUTES.MANAGER && selectedManagerOptions) {
      selectedLabelsRef.current = { ...selectedManagerLabelsRef.current };
      setSelectedOptions(selectedManagerOptions);
    } else if (
      router.pathname === PAGE_ROUTES.COMPANIES_SEARCH &&
      selectedCompanyOptions
    ) {
      selectedLabelsRef.current = { ...selectedCompanyLabelsRef.current };
      setSelectedOptions(selectedCompanyOptions);
    } else if (
      router.pathname === PAGE_ROUTES.SOCIETIES_SEARCH &&
      selectedSocietyOptions
    ) {
      selectedLabelsRef.current = { ...selectedSocietyLabelsRef.current };
      setSelectedOptions(selectedSocietyOptions);
    } else if (selectedOpportunitiesOptions) {
      selectedLabelsRef.current = { ...selectedOpportunitiesLabelsRef.current };
      setSelectedOptions(selectedOpportunitiesOptions);
    }
  }, [
    router,
    selectedManagerOptions,
    selectedCompanyOptions,
    selectedSocietyOptions,
    selectedOpportunitiesOptions,
  ]);

  const getInterests = async () => {
    try {
      const response = await get<TagsResponse<TagType.Topic>>(
        API_ROUTES.TOPICS,
        headers
      );
      const list = parseArrayResponse<TagAttributes<TagType.Topic>>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getOfferings = async () => {
    try {
      const response = await get<TagsResponse<TagType.Offering>>(
        API_ROUTES.OFFERINGS,
        headers
      );
      const list =
        parseArrayResponse<TagAttributes<TagType.Offering>>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getUniversities = async () => {
    try {
      const response = await get<UniversitiesResponse>(
        API_ROUTES.UNIVERSITIES,
        headers
      );
      const list = parseArrayResponse<UniversityAttributes>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getBenefits = async () => {
    try {
      const response = await get<BenefitsResponse>(
        API_ROUTES.BENEFITS,
        headers
      );
      const list = parseArrayResponse<BenefitAttributes>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getCompanySizes = async () => {
    try {
      const response = await get<Array<string>>(
        API_ROUTES.COMPANY_EMPLOYEES_COUNTS,
        headers
      );
      return response?.map((value) => ({
        id: value,
        value: value,
        label: value,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getIndustry = async () => {
    try {
      const response = await get<TagsResponse<TagType.Industry>>(
        API_ROUTES.INDUSTRY,
        headers
      );
      const list =
        parseArrayResponse<TagAttributes<TagType.Industry>>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getEventCategories = async () => {
    try {
      const response = await get<EventCategoriesList>(
        API_ROUTES.EVENT_CATEGORIES,
        headers
      );
      const list = parseArrayResponse<EventCategoriesAttributes>(response);
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getAreaOfResponsibility = async () => {
    try {
      const response = await get<TagsResponse<TagType.AreaOfResponsibility>>(
        API_ROUTES.AREA_OF_RESPONSIBILITY,
        headers
      );
      const list =
        parseArrayResponse<TagAttributes<TagType.AreaOfResponsibility>>(
          response
        );
      return list.map(({ id, name }) => ({
        id: id,
        value: id,
        label: name,
      }));
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const getTouchpointableTypesList = async (type: string) => {
    try {
      const list = await get<OpportunityTypesResponse>(
        API_ROUTES.OPPORTUNITY_TYPES,
        headers
      );
      const types = list.find((item) => item?.display_name === type);
      return (
        types?.touchpointable_kinds.map((kind) => ({
          id: kind,
          value: kind,
          label: kind,
        })) ?? []
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const touchpointTypeList = [
    {
      id: 'touchpointType1',
      value: 'Job',
      label: 'Jobs',
    },
    {
      id: 'touchpointType2',
      value: 'Internship',
      label: 'Internships',
    },
    {
      id: 'touchpointType3',
      value: 'Event',
      label: 'Events',
    },
    {
      id: 'touchpointType6',
      value: 'Internship&Spring Weeks',
      label: 'Insight Weeks',
    },
    {
      id: 'touchpointType7',
      value: 'Event&Social',
      label: 'Networking & Social',
    },
    {
      id: 'touchpointType8',
      value: 'Internship&Career Fairs',
      label: 'Career Fairs',
    },
    {
      id: 'touchpointType9',
      value: 'Event&Insight Days',
      label: 'Insight Days',
    },
    {
      id: 'touchpointType10',
      value: 'Event&Workshops',
      label: 'Workshops',
    },
    {
      id: 'touchpointType11',
      value: 'Event&Talks',
      label: 'Conferences & Talks',
    },
    {
      id: 'touchpointType12',
      value: 'Event&Competitions',
      label: 'Competitions',
    },
  ];

  const workTypeList = [
    {
      id: 'Full time',
      value: 'Full time',
      label: 'Full time',
    },
    {
      id: 'Part time',
      value: 'Part time',
      label: 'Part time',
    },
  ];

  const workModeList = [
    {
      id: 'remote',
      value: 'remote',
      label: 'Remote',
    },
    {
      id: 'hybrid',
      value: 'hybrid',
      label: 'Hybrid',
    },
    {
      id: 'office',
      value: 'office',
      label: 'Office',
    },
  ];

  const datePostedList = [
    {
      id: 'datePosted1',
      value: format(addMonths(new Date(), -3), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Last 3 months',
    },
    {
      id: 'datePosted2',
      value: format(addMonths(new Date(), -2), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Last 2 months',
    },
    {
      id: 'datePosted3',
      value: format(addMonths(new Date(), -1), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Last month',
    },
    {
      id: 'datePosted4',
      value: format(addWeeks(new Date(), -2), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Last two weeks',
    },
    {
      id: 'datePosted5',
      value: format(addWeeks(new Date(), -1), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Last week',
    },
    {
      id: 'datePosted6',
      value: format(new Date(), "yyyy-MM-dd'T'00:00:00.000xxx"),
      label: 'Today',
    },
  ];

  const getOptions = async (field: string) => {
    switch (field) {
      case 'interests':
        try {
          const list = await getInterests();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'university':
        try {
          const list = await getUniversities();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'offerings':
        try {
          const list = await getOfferings();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'benefits':
        try {
          const list = await getBenefits();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'industry':
        try {
          const list = await getIndustry();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'event_category':
        try {
          const list = await getEventCategories();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'areaOfResponsibility':
        try {
          const list = await getAreaOfResponsibility();
          return list;
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'company_sizes':
        try {
          return await getCompanySizes();
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'internship_types':
        try {
          return await getTouchpointableTypesList('Internships');
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'event_types':
        try {
          return await getTouchpointableTypesList('Events');
        } catch (error) {
          notificationInstance.handleExceptionError(error);
        }
        break;
      case 'touchpointType':
        return touchpointTypeList;
      case 'workType':
        return workTypeList;
      case 'workMode':
        return workModeList;
      case 'datePosted':
        return datePostedList;
      case 'location':
        return cityCountryList;
      case 'remotePreference':
        return countries;
      default:
        return null;
    }
  };

  const workTypeOptions = useMemo(() => {
    if (Array.isArray(selectedOptions?.work_type)) {
      const workTypes = selectedOptions?.work_type;
      const options = workTypes.map((item) => `&work_type[]=${item}`).join('');
      return options;
    } else return '';
  }, [selectedOptions]);

  // Changes made here, needs to be done on useOpportunitiesSearch hook also.
  const getKey = () => {
    let type: TouchpointType | null = null;

    const {
      touchpointableType: typeFromQueryString,
      status,
      entity,
    } = router.query;

    if (router.pathname === PAGE_ROUTES.EVENTS_SEARCH) {
      type = TouchpointType.Event;
    } else if (router.pathname === PAGE_ROUTES.INTERNSHIPS_SEARCH) {
      type = TouchpointType.Internship;
    } else if (router.pathname === PAGE_ROUTES.JOBS_SEARCH) {
      type = TouchpointType.Job;
    } else if (typeFromQueryString) {
      type = typeFromQueryString as TouchpointType;
    }
    const touchpointableType = selectedOptions.touchpointable_type;
    const [touchpointable_type, kind] =
      typeof touchpointableType === 'string'
        ? touchpointableType.split('&')
        : [type, null];

    // Removing work_type from selectedOptions
    // as this needs to be sent as the above format instead of arrays
    const {
      work_type: _work_type,
      is_online_selected: _is_online_selected,
      country_ids,
      touchpoint_type,
      ...rest
    } = selectedOptions;

    const params = {
      page: 0,
      per_page: 1,
      remote_country_ids: country_ids,
      ...rest,
      touchpointable_type,
      kind,
    };

    let updatedParams = {};

    if (router.pathname === PAGE_ROUTES.MANAGER && entity && status) {
      const {
        kind: _kind,
        touchpointable_type: _touchpointable_type,
        ...otherParams
      } = params ?? {};
      updatedParams = otherParams;
    } else {
      updatedParams = params;
    }

    const qs = queryString.stringify(updatedParams, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });

    if (router.pathname === PAGE_ROUTES.MANAGER) {
      if (entity === ManagerFilterType.Companies) {
        return [
          `${API_ROUTES.FOLLOW}?${qs}&with_details=true&followable_types[]=Company`,
          headers,
        ];
      } else if (entity === ManagerFilterType.Societies) {
        return [
          `${API_ROUTES.FOLLOW}?${qs}&with_details=true&followable_types[]=StudentSociety`,
          headers,
        ];
      } else if ((entity as TouchpointType) && typeof status === 'string') {
        return [
          `${API_ROUTES.CAMPAIGNS}?${qs}${workTypeOptions}&action_type=${
            status === ManagerFilterType.Registered
              ? 'accepted'
              : status.toLowerCase()
          }`,
          headers,
        ];
      }
    } else if (router.pathname === PAGE_ROUTES.COMPANIES_SEARCH) {
      return `${API_ROUTES.COMPANY_LIST}?${qs}`;
    } else if (router.pathname === PAGE_ROUTES.SOCIETIES_SEARCH) {
      return `${API_ROUTES.STUDENT_SOCIETIES}?${qs}`;
    } else {
      return `${API_ROUTES.CAMPAIGNS}?${qs}${workTypeOptions}`;
    }
  };
  const { data: response } = useSWRInfinite<
    CompaniesResponse | StudentSocietiesResponse | OpportunitiesResponse
  >(getKey, get, { revalidateFirstPage: false });

  const total = useMemo(() => {
    return Array.isArray(response) ? response[0]?.meta.total : undefined;
  }, [response]);

  return {
    getOptions,
    selectedOptions,
    setSelectedOptions,
    total,
    selectedLabelsRef,
  };
};
