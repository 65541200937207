import React, { useState, KeyboardEvent } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import cn from 'classnames';
import { useForm } from 'react-hook-form';

import { useRouter } from 'next/router';
import { IconButton } from 'components';
import Modal from 'components/Modal';
import { ButtonV2, Input, Form as FormComponent } from 'components/form';
import VerifyOTP from 'components/candidate/VerifyOTP';
import { useCandidate } from 'hooks/useCandidate';
import { useNotification } from 'hooks/useNotification';
import { useApp } from 'lib/contexts/save-in-app-context';
import { useAuth } from 'lib/providers/AuthProvider';

import { parseResponse } from 'lib/utils/parser';
import { isCandidateUserable } from 'utils/user';
import styles from '../RegistrationDetailsModal.module.scss';
import stylesContent from '../RegistrationForm.module.scss';

const ModalInstance: React.FC<
  InstanceProps<boolean | undefined> & { isJoinWaitlist?: boolean }
> = ({ isOpen, isJoinWaitlist, onResolve, onReject }) => {
  const notificationInstance = useNotification();
  const router = useRouter();

  const { verifyCandidateOTP } = useCandidate();
  const [isInvalidOTP, setIsInvalidOTP] = useState(false);
  const { email } = useApp();
  const { updateUserApiResponse } = useAuth();

  const isEvent = router.pathname?.includes('event');

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const otpValue = watch('otp');

  const onSubmitForm = async (data: { otp: string }) => {
    const { otp } = data;
    try {
      const response = await verifyCandidateOTP({
        email: email as string,
        otp,
      });
      const userApiAttributes = parseResponse(response);
      if (isCandidateUserable(userApiAttributes)) {
        onResolve(true);
        updateUserApiResponse(response);
      } else {
        setIsInvalidOTP(true);
      }
    } catch (error) {
      console.error();
      notificationInstance.handleExceptionError(error as Error);
    }
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission (if applicable)
      handleSubmit(onSubmitForm)();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={cn(styles.container, {
        [styles.containerVerify]: !isEvent,
      })}
      className={styles.modal}
    >
      {isEvent ? (
        <div className={stylesContent.RegistrationForm}>
          <FormComponent
            autoComplete="off"
            className={stylesContent.form}
            onSubmit={handleSubmit(onSubmitForm)}
          >
            <div className={cn(stylesContent.section, stylesContent.top)}>
              <div className={stylesContent.header}>
                <IconButton
                  iconName="chevron-left"
                  onClick={() => onResolve()}
                  className={stylesContent.back}
                  iconClassName={stylesContent.backIcon}
                />

                <p className={stylesContent.title}>Verify your account</p>
                <IconButton
                  size="xsmall"
                  iconName="close"
                  className={stylesContent.cancelIcon}
                  onClick={() => onReject()}
                />
              </div>
            </div>

            <div
              className={cn(stylesContent.contentOTP, stylesContent.content)}
            >
              <p className={stylesContent.desc}>
                Email address you entered is associated with an existing
                account. Verify your email in order to{' '}
                {isEvent ? 'register' : 'apply'}.
              </p>

              <Input
                autoFocus
                type="otp"
                label="Verification code"
                placeholder="Enter your code here"
                {...register('otp', {
                  required: 'Required',
                })}
                isValid={!isInvalidOTP}
                className={cn(stylesContent.otpinput, {
                  [stylesContent.isInvalid]: isInvalidOTP,
                })}
                onKeyDown={handleInputKeyDown}
              />
            </div>
            <div className={cn(stylesContent.section, stylesContent.bottom)}>
              <div className={stylesContent.separator}></div>
              <div className={stylesContent.footer}>
                <ButtonV2
                  disabled={!!errors.otp || !otpValue}
                  isLoading={isSubmitting}
                  className={stylesContent.button}
                >
                  {isJoinWaitlist ? 'Join Waitlist' : 'Verify account'}
                </ButtonV2>
              </div>
            </div>
          </FormComponent>
        </div>
      ) : (
        <>
          <IconButton
            size="xsmall"
            iconName="close"
            className={styles.cancelIcon}
            onClick={() => onReject()}
          />
          <VerifyOTP
            email={email as string}
            onComplete={onResolve}
            isLoginPopup={true}
            isJobPage={true}
          />
        </>
      )}
    </Modal>
  );
};

export const verifyOTPModalInstance = create(ModalInstance);
