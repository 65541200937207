import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { Icon, Link } from 'components';
import { registrationDetailsModalInstance } from 'components/candidate/RegistrationDetailsModal';
import { ButtonV3 } from 'components/ComponentV2';
import { ActionMenu } from 'components/ActionMenu/ActionMenu.component';
import { applyConfirmationModalInstance } from 'components/candidate/ApplyConfirmationModal';
import {
  TouchpointNotifications,
  NotificationType,
} from 'components/candidate/Notifications/TouchpointNotifications.component';
import { useCommon } from 'hooks/useCommon';
import { FLAG_STATUS_CODES, useCandidate } from 'hooks/useCandidate';
import { useApp } from 'lib/contexts/save-in-app-context';
import {
  TouchpointType,
  TouchpointOpportunity,
  TouchpointStatus,
} from 'lib/models/touchpoint';
import { ApplicationResponseModalEnum } from 'lib/models/candidate-applications';

import { OpportunitiesResponse, TouchpointState } from 'lib/models/opportunity';
import { EventTouchpointable } from 'lib/models/event';
import { parseResponse } from 'lib/utils/parser';
import { useAuth } from 'lib/providers/AuthProvider';
import { useCandidateDashboard } from 'lib/providers/CandidateDashboardProvider';
import { CHROME_EXTENSION_STORE_LINK } from 'utils/urls';
import { getEventButtonText } from 'utils/format';
import { externalApplyModalInstance } from '../ExternalApplyModal';

import styles from '../Card.module.scss';

type Step = {
  id: number;
  title: string;
};
const steps: Record<TouchpointType, Step[]> = {
  [TouchpointType.Event]: [
    { id: 1, title: 'Saved' },
    { id: 2, title: 'Registration Started' },
    { id: 3, title: 'Registered' },
  ],
  [TouchpointType.Job]: [
    { id: 1, title: 'Saved' },
    { id: 2, title: 'Application Started' },
    { id: 3, title: 'Applied' },
  ],
  [TouchpointType.Internship]: [
    { id: 1, title: 'Saved' },
    { id: 2, title: 'Application Started' },
    { id: 3, title: 'Applied' },
  ],
};

export interface ApplicationProgressProps {
  type: TouchpointType;
  opportunity: TouchpointOpportunity;
  status: string;
  isExtensionDownloaded?: boolean;
  touchpoint_id: string;
  touchpoint_title: string;
  touchpoint_slug: string;
  apply_url: string | null;
  is_eligible_for_auto_apply: boolean;
  mutateApi?: () => Promise<OpportunitiesResponse[] | undefined>;
  touchpointable?: EventTouchpointable | null;
  state?: TouchpointState | null;
  applications?: number;
}

export const ApplicationProgress: React.FC<ApplicationProgressProps> = memo(
  ({
    opportunity,
    status,
    type,
    isExtensionDownloaded = true,
    touchpoint_id,
    apply_url,
    mutateApi,
    touchpointable,
    state,
    applications,
    is_eligible_for_auto_apply,
  }: ApplicationProgressProps) => {
    const { refetchCandidateDashboard } = useCandidateDashboard();
    const { isCandidate, candidate } = useAuth();
    const touchpointNotificationInstance = TouchpointNotifications();

    const {
      extension_installed,
      profile_complete,
      show_chrome_externsion_banner,
      show_complete_profile_banner,
      external_registrated_event_count,
      external_applied_non_event_count,
    } = candidate || {};

    const touchpointableResponse = parseResponse(
      touchpointable as EventTouchpointable
    );
    const { setWriteApplicationModalType, handleAutofillApplicationsClick } =
      useApp();
    const { onApplyClick, applyOpportunity, sendInvalidURLReport } =
      useCommon();
    const { redirectToCoverLetterBuilder, updateCount } = useCandidate();

    const stepList = steps[type];
    const [currentStatus, setCurrentStatus] = useState<string>('');

    useEffect(() => setCurrentStatus(status), [status]);

    const isStarted = useMemo(
      () => currentStatus === TouchpointStatus.STARTED,
      [currentStatus]
    );
    const isApplied = useMemo(
      () => currentStatus === TouchpointStatus.APPLIED,
      [currentStatus]
    );

    const isExternalOpportunity =
      opportunity === TouchpointOpportunity.EXTERNAL;

    const getActiveStep = (status: string) => {
      switch (status) {
        case TouchpointStatus.SAVED:
          return 1;
        case TouchpointStatus.STARTED:
          return 2;
        case TouchpointStatus.APPLIED:
        default:
          return 3;
      }
    };

    const {
      enforce_capacity,
      waitlist,
      enforce_waitlist_for_all,
      capacity,
      is_capacity_reached,
    } = touchpointableResponse;

    const willJoinWaitlist =
      enforce_waitlist_for_all || (is_capacity_reached && waitlist);

    const showTouchpointNotification = useCallback(() => {
      if (apply_url) {
        touchpointNotificationInstance({
          notificationType: NotificationType.EXTERNAL_APPLY,
          type: type,
          eventCount: external_registrated_event_count,
          nonEventCount: external_applied_non_event_count,
        });
        if (type === TouchpointType.Event) {
          updateCount({
            external_registrated_event_count:
              external_registrated_event_count! + 1,
          });
        } else {
          updateCount({
            external_applied_non_event_count:
              external_applied_non_event_count! + 1,
          });
        }
      } else {
        if (type === TouchpointType.Event) {
          touchpointNotificationInstance({
            notificationType: willJoinWaitlist
              ? NotificationType.INTERNAL_EVENT_WAITLIST
              : NotificationType.INTERNAL_EVENT_REGISTERED,
          });
        } else {
          touchpointNotificationInstance({
            notificationType: NotificationType.INTERNAL_JOB_APPLY,
            type: type,
          });
        }
      }
    }, [apply_url, type, touchpointable]);

    const redirectToExtLink = async () => {
      if (touchpoint_id) {
        window.open(apply_url || '', '_blank');
        try {
          const flag = await applyConfirmationModalInstance({
            isEvent: type === TouchpointType.Event,
          });
          if (flag === FLAG_STATUS_CODES.CONFIRM_APPLY_YES) {
            const response = await applyOpportunity(touchpoint_id);
            if (response) {
              showTouchpointNotification();
              setCurrentStatus(TouchpointStatus.APPLIED);
              mutateApi && mutateApi();
            }
          } else if (flag === FLAG_STATUS_CODES.CONFIRM_APPLY_INCORRECT_LINK) {
            sendInvalidURLReport({ id: touchpoint_id });
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const onRegisterEvent = async () => {
      if (touchpoint_id) {
        await onApplyClick({ touchpointId: touchpoint_id });
        setCurrentStatus(TouchpointStatus.STARTED);
        mutateApi && mutateApi();
      }
      if (apply_url) {
        if (
          (isCandidate &&
            !show_chrome_externsion_banner &&
            !show_complete_profile_banner) ||
          (!show_complete_profile_banner && extension_installed) ||
          (!show_chrome_externsion_banner && profile_complete) ||
          (extension_installed && profile_complete)
        ) {
          redirectToExtLink();
        } else {
          try {
            await externalApplyModalInstance({ apply_url });
            redirectToExtLink();
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        const flag = await registrationDetailsModalInstance({
          touchpointId: touchpoint_id,
          className: styles.registrationModal,
        });
        if (
          flag === FLAG_STATUS_CODES.NEW_USER_REGISTER_SUCCESS ||
          flag === FLAG_STATUS_CODES.EXISTING_USER_REGISTER_SUCCESS
        ) {
          showTouchpointNotification();
          setCurrentStatus(TouchpointStatus.APPLIED);
          mutateApi && mutateApi();
        }
      }
    };
    const onRegisterEventMark = async () => {
      await applyOpportunity(touchpoint_id);
      setCurrentStatus(TouchpointStatus.APPLIED);
      showTouchpointNotification();
      mutateApi && mutateApi();
      refetchCandidateDashboard();
    };
    const onApplyOpportunity = () => onRegisterEvent();
    const onApplyOpportunityMark = () => onRegisterEventMark();

    const menuOptions = [
      {
        id: '1',
        label: 'Get questions replies',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          setWriteApplicationModalType({
            type: ApplicationResponseModalEnum.questionResponse,
            application: null,
          });
        },
      },
      {
        id: '2',
        label: 'Write cover letter',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          redirectToCoverLetterBuilder(touchpoint_id);
        },
      },
      {
        id: '3',
        label: 'Get chrome extension',
        onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
          event.preventDefault();
          event.stopPropagation();
          handleAutofillApplicationsClick();
        },
      },
    ];

    const StepIndicator = ({ id, title }: { id: number; title: string }) => {
      const isActive = id <= getActiveStep(currentStatus);
      return (
        <div key={id} className={styles.step}>
          <div className={styles.heading}>
            <span
              className={cn(styles.icon, {
                [styles.iconText]: isActive,
              })}
            >
              {isActive ? (
                <Icon iconName="icon_check" size="xxsmallplus" />
              ) : (
                id
              )}
            </span>
            <span className={styles.title}>{title}</span>
          </div>
          <div
            className={cn(styles.progressBar, {
              [styles.active]: isActive,
            })}
          ></div>
        </div>
      );
    };

    const registrationCardContent = (
      <div className={styles.progressContent}>
        <div>
          <p className={styles.title}>
            {isStarted ? 'NEXT: Register' : 'NEXT: Start registration'}
          </p>
          {isStarted ? (
            <p className={styles.description}>
              Complete your registration or mark as registered if you already
              did.
            </p>
          ) : (
            <p className={styles.description}>
              {isExternalOpportunity
                ? 'Register on their website.'
                : 'Register with a few clicks directly on Huzzle.'}
            </p>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <ButtonV3
            size="medium"
            startIcon={
              isStarted
                ? undefined
                : isExternalOpportunity
                ? 'icon_launch'
                : 'icon_send-message'
            }
            iconSize={isStarted ? undefined : 'mediumplus'}
            type="button"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              onRegisterEvent();
            }}
          >
            {getEventButtonText(
              state || null,
              enforce_capacity || false,
              capacity || 0,
              (touchpointable && capacity - (applications || 0)) || undefined,
              waitlist || false,
              enforce_waitlist_for_all || false
            )}
          </ButtonV3>
          {isStarted ? (
            <ButtonV3
              size="medium"
              color="secondary"
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onRegisterEventMark();
              }}
            >
              I’ve registered
            </ButtonV3>
          ) : null}
        </div>
      </div>
    );

    const applicationCardContent = (
      <div className={styles.progressContent}>
        <div>
          <p className={styles.title}>
            {isStarted ? 'NEXT: Apply' : 'NEXT: Start application'}
          </p>
          {isStarted ? (
            <p className={styles.description}>
              Complete your application or mark as applied if you already did.
            </p>
          ) : !isExtensionDownloaded ? (
            <p className={styles.description}>
              Check application process and get support here or{' '}
              <span>
                <Link
                  href={CHROME_EXTENSION_STORE_LINK}
                  target="_blank"
                  className={styles.downloadlink}
                >
                  Download Huzzle Extension
                </Link>
              </span>{' '}
              and autofill application.
            </p>
          ) : (
            <p className={styles.description}>
              {isExternalOpportunity
                ? 'Check application process and get support here or autofill application with extension.'
                : 'Check application and apply with a few clicks directly on Huzzle.'}
            </p>
          )}
        </div>
        <div className={styles.buttonGroup}>
          {isStarted ? (
            <>
              <ButtonV3
                size="medium"
                type="button"
                startIcon="icon_bolt-filled"
                startIconClassName={cn({
                  [styles['quick-apply']]: is_eligible_for_auto_apply,
                })}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onApplyOpportunity();
                }}
              >
                {is_eligible_for_auto_apply ? 'Quick Apply' : 'Apply'}
              </ButtonV3>
              <ButtonV3
                size="medium"
                color="secondary"
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  onApplyOpportunityMark();
                }}
              >
                I’ve applied
              </ButtonV3>
            </>
          ) : (
            <ButtonV3
              type="button"
              size="medium"
              startIcon={'icon_bolt-filled'}
              startIconClassName={cn({
                [styles['quick-apply']]: is_eligible_for_auto_apply,
              })}
              iconSize="mediumplus"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                onApplyOpportunity();
              }}
            >
              {is_eligible_for_auto_apply ? 'Quick Apply' : 'Apply'}
            </ButtonV3>
          )}
          <ActionMenu
            actionElement={
              <Icon
                iconName="icon_magic-wand"
                className={styles.magicWandIcon}
              />
            }
            options={menuOptions}
            className={styles.trigger}
            listClassName={styles.triggerAlign}
          />
        </div>
      </div>
    );

    return (
      <>
        <div className={styles.progress}>
          <div className={styles.progressContainer}>
            {stepList.map(({ id, title }) => (
              <StepIndicator key={id} id={id} title={title} />
            ))}
          </div>
          {isApplied
            ? null
            : type === TouchpointType.Event
            ? registrationCardContent
            : applicationCardContent}
        </div>
      </>
    );
  }
);

ApplicationProgress.displayName = 'ApplicationProgress';
