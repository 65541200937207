import React, { memo, useMemo, useState } from 'react';
import cn from 'classnames';
import format from 'date-fns/format';

import { Icon, IconButton, Link } from 'components';
import { Col, Row } from 'components/layout';
import ActionMenu, { ActionOption } from 'components/ActionMenu';
import Salary from 'components/Salary';
import Chip from 'components/Chip';
import Modal from 'components/Modal';
import { ButtonV2 } from 'components/form';
import {
  TouchpointType,
  TouchpointOpportunity,
  TouchpointStatus,
} from 'lib/models/touchpoint';
import { parseArrayResponse } from 'lib/utils/parser';
import { CitiesResponse } from 'lib/models/city';
import { CohostAttributes } from 'lib/models/cohost';
import { FollowingAttributes } from 'lib/models/following';
import { OpportunitiesResponse, TouchpointState } from 'lib/models/opportunity';
import { EventTouchpointable } from 'lib/models/event';
import { getCardLabel, getHostLogos, getHostNames } from 'utils/format';
import Deadline from '../../Deadline';
import DatePosted from '../../DatePosted';
import CardLogo from '../CardLogo';
import styles from '../Card.module.scss';
import { ApplicationProgress } from './ApplicationProgress.component';

export interface TouchpointCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** url for company/society icon */
  icon_url: string;
  id: string;
  slug: string;
  /** title of the opportunity */
  title: string;
  /** name of the company/society */
  createdBy: string;
  /** start date of the opportunity */
  startDate: string;
  created_at: string;
  application_deadline: string;
  maximum_salary?: number | null;
  minimum_salary?: number | null;
  pay_currency?: string;
  is_verified: boolean;
  touchpointable_type: TouchpointType;
  isExpired: boolean;
  menuOptions?: Array<ActionOption>;
  triggerIconClassName?: string;
  listClassName?: string;
  /** public url for opportunity/company/society */
  url: string;
  onClick?: () => void;
  showType?: boolean;
  isOnboarding?: boolean;
  kind?: string;
  isEasyApply?: boolean;
  onNewTab?: boolean;
  chipClassName?: string;
  huzzle_verified: boolean;
  isNewSet: boolean;
  cities: CitiesResponse | null;
  showManagerProgress: boolean;
  cohost_applications: Array<CohostAttributes> | null;
  following: FollowingAttributes | null;
  apply_url: string | null;
  mutateApi?: () => Promise<OpportunitiesResponse[] | undefined>;
  extension_installed: boolean;
  isStarted?: boolean | null;
  activeStatus?: string | null;
  showOptionFullContent?: boolean;
  optionHeight?: number | null;
  touchpointable?: EventTouchpointable | null;
  state?: TouchpointState | null;
  applications?: number;
  color?: 'white' | 'secondary';
  is_eligible_for_auto_apply: boolean;
}

export const TouchpointCard: React.FC<TouchpointCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    icon_url,
    id,
    slug,
    title,
    createdBy,
    startDate,
    maximum_salary,
    minimum_salary,
    pay_currency,
    created_at,
    application_deadline,
    is_verified,
    touchpointable_type,
    isExpired,
    menuOptions,
    triggerIconClassName,
    listClassName,
    url,
    onClick,
    showType = false,
    isOnboarding = false,
    kind,
    isEasyApply = false,
    onNewTab = false,
    chipClassName,
    showManagerProgress = false,
    huzzle_verified,
    isNewSet,
    cities,
    cohost_applications,
    following,
    apply_url,
    mutateApi,
    extension_installed,
    isStarted = false,
    activeStatus,
    showOptionFullContent,
    optionHeight,
    touchpointable,
    applications,
    state,
    color = 'white',
    is_eligible_for_auto_apply,
  }: TouchpointCardProps) => {
    const contentClassNames = cn(
      {
        [styles.expired]: isExpired,
      },
      styles.Card,
      { [`${styles[`Card-${color}`]}`]: true },
      className
    );

    const verifiedClassName = cn({ [styles.huzzleVerified]: isNewSet });
    const [showLocationModal, setLocationModal] = useState(false);

    const startDateTimeValue = startDate
      ? format(new Date(startDate), 'MMM d, h:mma')
      : null;

    const initials = createdBy ? createdBy.split(' ')[0] : '';
    const location = cities ? parseArrayResponse(cities) : [];
    const isMultipleLocationPresent = location.length >= 2;
    const allLocations = location.map((location) => location.name);

    const locationString = useMemo(() => {
      return (
        allLocations.slice(0, -1).join(', ') + ' and ' + allLocations.slice(-1)
      );
    }, [allLocations]);

    const locationDetail = useMemo(() => {
      return location?.length > 0 ? (
        <Chip
          key={id}
          className={cn(styles.kind, styles.location, chipClassName)}
        >
          {location.length ? (
            <span className={styles.name}>
              {location[0]?.name}
              {location.length >= 2 ? `, +${location.length - 1}` : null}
            </span>
          ) : null}
          {isMultipleLocationPresent ? (
            <IconButton
              iconName="chevron-down"
              onClick={(e) => {
                e.preventDefault();
                setLocationModal(true);
              }}
              size="xxsmall"
              className={styles.chipButton}
            />
          ) : null}
        </Chip>
      ) : null;
    }, [location]);

    const hostsInfo = useMemo(
      () => getHostNames({ createdBy, cohost_applications }),
      [cohost_applications, createdBy]
    );

    const cohostsLogoDetails = useMemo(
      () =>
        cohost_applications ? getHostLogos({ cohost_applications }) : null,
      [cohost_applications]
    );
    const modal = (
      <Modal
        isOpen={showLocationModal}
        onRequestClose={() => setLocationModal(false)}
        isFullScreen={false}
        className={styles.locationModal}
        contentClassName={styles.locationModalContent}
      >
        <p className={styles.emoji}>📍️</p>
        <IconButton
          iconName="close"
          onClick={() => setLocationModal(false)}
          className={styles.close}
        />
        <p className={styles.title}>Locations</p>
        <p className={styles.detail}>
          This {touchpointable_type} is available in{' '}
          <mark>{locationString}</mark>
        </p>
        <p className={styles.detail}>
          You will be able to choose location to which you&apos;re applying when
          you start your application.{' '}
        </p>
        <ButtonV2 onClick={() => setLocationModal(false)} isFullWidth={true}>
          OK
        </ButtonV2>
      </Modal>
    );

    const cardContent = (
      <div
        className={contentClassNames}
        style={style}
        data-testid="TouchpointCard"
      >
        <Row columnGap={12} wrap={false} isFullWidthRow>
          <CardLogo
            is_verified={is_verified}
            huzzle_verified={huzzle_verified}
            verifiedClassName={verifiedClassName}
            logo_url={icon_url}
            initials={initials}
            altLogoText={createdBy ? `Logo of ${createdBy}` : ''}
            cohostsLogoDetails={cohostsLogoDetails}
          />
          <Row justify="space-between" isFullWidthRow={true} wrap={false}>
            <Col span={isOnboarding ? 20 : 24} sm={isOnboarding ? 20 : 24}>
              <Row align="center" className={styles.header}>
                {showType ? (
                  <>
                    <p
                      className={cn(styles.type, {
                        [styles.event]:
                          touchpointable_type === TouchpointType.Event,
                        [styles.job]:
                          touchpointable_type === TouchpointType.Job,
                        [styles.internship]:
                          touchpointable_type === TouchpointType.Internship,
                      })}
                    >
                      {getCardLabel(touchpointable_type)}
                    </p>
                  </>
                ) : null}
                {showType && startDate ? (
                  <div className={styles.dot}>•</div>
                ) : null}
                {startDate && touchpointable_type === TouchpointType.Event ? (
                  <>
                    <p
                      className={cn(styles.date, {
                        [styles.newDate]: isNewSet,
                      })}
                    >
                      {startDateTimeValue}
                    </p>
                  </>
                ) : null}
              </Row>
              <p
                className={cn(styles.title, {
                  [styles.newSet]: isNewSet,
                })}
              >
                {title}
              </p>
              <div
                className={cn(styles.info, {
                  [styles.newSet]: isNewSet,
                })}
              >
                {isOnboarding ? (
                  <div className={styles.touchpointType}>
                    <p
                      className={cn(styles.text, { [styles.newSet]: isNewSet })}
                    >
                      {touchpointable_type}
                    </p>
                    <Icon
                      iconName="icon_dot"
                      className={cn(styles.dotSvg, {
                        [styles.newSetDot]: isNewSet,
                      })}
                    />
                  </div>
                ) : null}
                <p className={cn(styles.text, styles.hostsInfo)}>{hostsInfo}</p>
                {created_at && (
                  <div className={styles.createdAtInfo}>
                    <div className={styles.dot}>•</div>
                    <DatePosted
                      className={styles.text}
                      created_at={created_at}
                    />
                  </div>
                )}
              </div>
              {kind ||
              minimum_salary ||
              maximum_salary ||
              application_deadline ||
              isEasyApply ||
              isExpired ||
              location?.length > 0 ? (
                <Row align="center" className={styles.detail}>
                  {kind &&
                  isOnboarding &&
                  touchpointable_type !== TouchpointType.Event ? (
                    <Chip
                      className={cn(
                        styles.kind,
                        { [styles.newSet]: isNewSet },
                        chipClassName
                      )}
                    >
                      {kind}
                    </Chip>
                  ) : null}
                  {locationDetail}

                  {minimum_salary || maximum_salary ? (
                    <Salary
                      minimum_salary={minimum_salary}
                      maximum_salary={maximum_salary}
                      pay_currency={pay_currency}
                      className={cn(
                        styles.salary,
                        {
                          [styles.kind]: isOnboarding,
                          [styles.newSet]: isNewSet,
                        },
                        chipClassName
                      )}
                    />
                  ) : null}
                  {is_eligible_for_auto_apply ? (
                    <Chip
                      className={cn(
                        styles.kind,
                        styles.easyApply,
                        {
                          [styles.newSet]: isNewSet,
                        },
                        chipClassName
                      )}
                    >
                      <Icon size="xsmallplus" iconName="icon_bolt-filled" />
                      Quick Apply
                    </Chip>
                  ) : null}
                  {!isExpired && application_deadline && (
                    <Deadline
                      application_deadline={application_deadline}
                      className={styles.deadline}
                    />
                  )}
                  {isExpired ? (
                    <Chip className={cn(styles.expiredChip, chipClassName)}>
                      ⌛{' '}
                      {touchpointable_type === TouchpointType.Event
                        ? 'Ended'
                        : 'Closed'}
                    </Chip>
                  ) : null}
                </Row>
              ) : null}
            </Col>
            <Col>
              {menuOptions && (
                <ActionMenu
                  triggerIconClassName={triggerIconClassName}
                  listClassName={listClassName}
                  options={menuOptions}
                  showOptionFullContent={showOptionFullContent}
                  optionHeight={optionHeight}
                />
              )}
            </Col>
          </Row>
        </Row>
        {showManagerProgress ? (
          <ApplicationProgress
            type={touchpointable_type}
            touchpointable={touchpointable}
            state={state}
            apply_url={apply_url}
            applications={applications}
            is_eligible_for_auto_apply={is_eligible_for_auto_apply}
            opportunity={
              (touchpointable_type === TouchpointType.Event && !apply_url) ||
              isEasyApply
                ? TouchpointOpportunity.INTERNAL
                : TouchpointOpportunity.EXTERNAL
            }
            isExtensionDownloaded={extension_installed}
            status={
              activeStatus
                ? activeStatus
                : isStarted || following === null
                ? TouchpointStatus.STARTED
                : TouchpointStatus.SAVED
            }
            touchpoint_id={id}
            touchpoint_title={title}
            touchpoint_slug={slug}
            mutateApi={mutateApi}
          />
        ) : null}
      </div>
    );

    return url ? (
      <Link
        href={url}
        target={onNewTab ? '_blank' : '_self'}
        onAnchorClick={onClick}
        underlineOnHover={false}
        className={styles.link}
      >
        {cardContent}
        {showLocationModal ? modal : null}
      </Link>
    ) : (
      cardContent
    );
  }
);

TouchpointCard.displayName = 'TouchpointCard';
