import React, { useMemo } from 'react';

import cn from 'classnames';
import { Icon, IconButton } from 'components';
import { ActionMenu } from 'components/ActionMenu/ActionMenu.component';
import { ButtonV3 } from 'components/ComponentV2';
import { Tooltip } from 'components/Tooltip/Tooltip.component';
import { useResponsive } from 'hooks/useResponsive';
import { CandidateApplicationType } from 'lib/models/candidate-applications';

import styles from './ApplicationBanner.module.scss';

interface ApplicationBannerProps {
  className?: string;
  onClose: () => void;
  openResponseModal: () => void;
  openCoverLetterModal: (applications: CandidateApplicationType) => void;
  applications: CandidateApplicationType[];
}

export const ApplicationBanner: React.FC<ApplicationBannerProps> = (
  props: ApplicationBannerProps
) => {
  const {
    className,
    onClose,
    openResponseModal,
    openCoverLetterModal,
    applications,
  } = props;
  const screens = useResponsive();

  const actionMenuOptions = useMemo(() => {
    const abc = applications.map((application: CandidateApplicationType) => {
      return {
        id: application.id,
        label: application.title,
        onClick: () => openCoverLetterModal(application),
      };
    });
    return abc;
  }, [applications]);

  return (
    <div className={cn(styles.ApplicationsBanner, className)}>
      <div className={styles.bannerInfo}>
        <Icon
          iconName="icon_magic-wand"
          className={styles.magicIcon}
          size={screens.sm ? 'large' : 'medium'}
        />
        <p>
          We can help write your cover letter or answer application questions.
        </p>
        {screens.sm && (
          <IconButton
            className={styles.closeBannerIcon}
            iconName="close"
            onClick={onClose}
            size="small"
          />
        )}
      </div>
      <div className={styles.bannerAction}>
        <ActionMenu
          options={actionMenuOptions}
          className={styles.coverLetterAction}
          actionElement={
            <ButtonV3 size="small" color="secondary" endIcon="icon_arrow-down">
              Get cover letter
            </ButtonV3>
          }
          listClassName={styles.coverLetterMenuList}
        />
        <Tooltip
          className={styles.bannerTooltip}
          content="Copy and paste questions from job applications and we'll write the response for you"
          color="dark"
        >
          <ButtonV3 size="small" color="secondary" onClick={openResponseModal}>
            Get questions replies
          </ButtonV3>
        </Tooltip>
        {!screens.sm && (
          <IconButton
            className={styles.closeBannerIcon}
            iconName="close"
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
};
