import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import cn from 'classnames';
import { Image } from 'components';
import { ButtonV3 } from 'components/ComponentV2';
import IconButton from 'components/IconButton';
import { Checkbox } from 'components/form';
import { useCandidate } from 'hooks/useCandidate';
import { useCandidateProfile } from 'hooks/useCandidateProfile';
import { PAGE_ROUTES } from 'lib/page-routes';
import { useAuth } from 'lib/providers/AuthProvider';
import { CHROME_EXTENSION_STORE_LINK } from 'utils/urls';
import { availableAutofillDomain } from 'utils/common.utils';
import styles from './ExternalApply.module.scss';

interface ExternalApplyProps {
  onClose: () => void;
  onApplyOpportunity: () => void;
  isProfileComplete: boolean;
  setShowNextModal: Dispatch<SetStateAction<boolean>>;
  apply_url: string;
}

export const ExternalApply: React.FC<ExternalApplyProps> = (
  props: ExternalApplyProps
) => {
  const {
    onClose,
    onApplyOpportunity,
    isProfileComplete,
    setShowNextModal,
    apply_url,
  } = props;

  const { candidate } = useAuth();
  const { isProfileCompExceptDemographics } = useCandidateProfile();
  const [checkProfileCompleted, setCheckProfileCompleted] = useState(false);

  const {
    extension_installed,
    show_chrome_externsion_banner,
    show_complete_profile_banner,
  } = candidate || {};

  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showExtensionModal, setShowExtensionModal] = useState(false);

  const isJobInAutofill = useMemo(() => {
    for (const domain of availableAutofillDomain) {
      if (apply_url?.includes(domain)) {
        return true;
      }
    }
    return false;
  }, [apply_url]);

  const { updateCandidateShowProfileModal, updateChromeExtensionShowModal } =
    useCandidate();

  useEffect(() => {
    if (checkProfileCompleted && isProfileCompExceptDemographics) {
      if (!extension_installed && show_chrome_externsion_banner) {
        setShowNextModal(true);
      } else {
        onClose();
        setShowNextModal(false);
      }
    }
  }, [isProfileCompExceptDemographics, checkProfileCompleted]);

  const isProfileUpdated = async () => {
    await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
    await new Promise((resolve) => {
      const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
          document.removeEventListener(
            'visibilitychange',
            handleVisibilityChange
          );
          resolve(true);
        }
      };
      document.addEventListener('visibilitychange', handleVisibilityChange);
      if (document.visibilityState === 'visible') handleVisibilityChange();
    });
    await new Promise((resolve) => setTimeout(() => resolve(true), 1000));
    setCheckProfileCompleted(true);
  };
  const redirectToProfile = async () => {
    if (showProfileModal) {
      await updateCandidateShowProfileModal({ show: false });
    }
    window.open(PAGE_ROUTES.CANDIDATE_PROFILE, '_blank');
    isProfileUpdated();
  };
  const redirectToHuzzleExt = async () => {
    if (showProfileModal) {
      await updateCandidateShowProfileModal({ show: false });
    }
    if (showExtensionModal) {
      await updateChromeExtensionShowModal({ show: false });
    }
    window.open(CHROME_EXTENSION_STORE_LINK, '_blank');
    setShowNextModal(false);
  };

  const handleApplyAnyway = async () => {
    if (showProfileModal) {
      await updateCandidateShowProfileModal({ show: false });
    }
    if (showExtensionModal) {
      await updateChromeExtensionShowModal({ show: false });
    }
    if (
      !isProfileComplete &&
      !extension_installed &&
      show_chrome_externsion_banner
    ) {
      setShowNextModal(true);
    } else {
      onApplyOpportunity();
      setShowNextModal(false);
    }
  };
  const modalContent = useMemo(() => {
    if (!isProfileComplete) {
      return (
        <p>
          Complete your profile in 5 minutes and never fill another application
          again! We&apos;ll autofill applications using the information you
          provide.
        </p>
      );
    } else if (isJobInAutofill) {
      return (
        <p>
          <span className={styles.blueText}>
            <b>This job is supported!</b>{' '}
          </span>
          Download our Chrome Extension to apply to this job and many more with
          one click.
        </p>
      );
    } else {
      return (
        <p>
          Download the Huzzle Chrome Extension to apply to millions of jobs with
          one click!
        </p>
      );
    }
  }, [isProfileComplete, isJobInAutofill]);

  return (
    <div className={styles.ExternalApply}>
      <div className={styles.header}>
        <div className={styles.headerWrapper}>
          <p className={styles.title}>
            {!isProfileComplete
              ? 'Complete Profile'
              : 'Apply to Millions of Jobs with 1 Click'}
          </p>
          <IconButton
            size="small"
            iconName="close"
            onClick={async () => {
              onClose();
              setShowNextModal(false);
              if (showProfileModal) {
                await updateCandidateShowProfileModal({
                  show: false,
                });
              }
              if (showExtensionModal) {
                await updateChromeExtensionShowModal({
                  show: false,
                });
              }
            }}
          />
        </div>
      </div>
      <div className={styles.imageContainer}>
        <Image
          src={
            !isProfileComplete
              ? '/images/show-profile.png'
              : '/images/application.png'
          }
          height={180}
        />
        <div className={styles.contentContainer}>{modalContent}</div>
        {show_complete_profile_banner && !isProfileComplete && (
          <Checkbox
            checked={showProfileModal}
            className={cn(styles.modalCheckbox)}
            onChange={() => setShowProfileModal(!showProfileModal)}
          >
            Don’t show this popup again
          </Checkbox>
        )}
        {show_chrome_externsion_banner && isProfileComplete && (
          <Checkbox
            checked={showExtensionModal}
            className={cn(styles.modalCheckbox)}
            onChange={() => setShowExtensionModal(!showExtensionModal)}
          >
            Don’t show this popup again
          </Checkbox>
        )}
      </div>

      <div className={styles.ExternalApplyContentWrapper}>
        <div className={styles.ExternalApplyContent}>
          <ButtonV3 color="secondary" isFullWidth onClick={handleApplyAnyway}>
            {!isProfileComplete ? 'Apply Anyway' : 'Apply Manually'}
          </ButtonV3>
          <ButtonV3
            isFullWidth
            onClick={
              !isProfileComplete ? redirectToProfile : redirectToHuzzleExt
            }
          >
            {!isProfileComplete ? 'Complete Profile' : 'Autofill Application'}
          </ButtonV3>
        </div>
      </div>
    </div>
  );
};
