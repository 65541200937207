import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
  useEffect,
} from 'react';
import useSWRInfinite from 'swr/infinite';
import useSWR from 'swr';
import queryString from 'query-string';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import useDebounce from 'hooks/useDebounce';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { get, post, remove } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { parsePaginatedResponse, parseArrayResponse } from 'lib/utils/parser';
import {
  OpportunitiesResponse,
  OpportunityAttributes,
} from 'lib/models/opportunity';
import { Notice } from 'lib/models/notice';
import {
  RecentSearchResponse,
  RecentSearchAttributes,
  RecentSearchesResponse,
} from 'lib/models/recent-search';
import { useAuth } from 'lib/providers/AuthProvider';
import { PAGE_ROUTES } from 'lib/page-routes';
import { TouchpointType } from 'lib/models/touchpoint';
import { useNotification } from './useNotification';
import { SelectedFilterOptionLabels, SelectedFilterOptions } from './useFilter';

export const PER_PAGE_COUNT_2 = 25;

export enum Size {
  micro = '0',
  small = '1',
  medium = '2',
  large = '3',
  big = '4',
}

export interface ReturnType {
  resetFilter: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  size: number;
  setSize: (size: number) => void;
  hasMoreRecords: boolean;
  isLoading: boolean;
  opportunities: Array<OpportunityAttributes>;
  recentSearches: Array<RecentSearchAttributes>;
  mutateRecentSearches: () => void;
  addRecentSearch: ({
    searchable_type,
    searchable_id,
  }: {
    searchable_type: string;
    searchable_id: string;
    headers: Headers;
  }) => void;
  removeRecentSearch: ({
    headers,
    id,
  }: {
    headers: Headers;
    id: string;
  }) => Promise<Notice | undefined>;
  removeAllRecentSearches: ({
    headers,
  }: {
    headers: Headers;
  }) => Promise<Notice | undefined>;
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
  isLoadingMore: boolean | undefined;
  setTouchpointType: (type: TouchpointType) => void;
  touchpointType: string;
}

export const useOpportunitiesSearch = (): ReturnType => {
  const notificationInstance = useNotification();
  const { headers } = useRecoilValue(httpHeadersState);
  const router = useRouter();
  const { isCandidate } = useAuth();
  const [search, setSearch] = useState('');
  const [touchpointType, setTouchpointType] = useState<TouchpointType | string>(
    ''
  );
  const trimmedSearch = search.trim();
  const debouncedSearch = useDebounce(trimmedSearch, 500);
  const { kind, touchpointableType } = router.query;
  const [selectedOptions, setSelectedOptions] = useState<SelectedFilterOptions>(
    {}
  );
  const selectedLabelsRef = useRef<SelectedFilterOptionLabels>({});

  useEffect(() => {
    if (router.pathname === PAGE_ROUTES.EVENTS_SEARCH) {
      setTouchpointType(TouchpointType.Event);
    } else if (router.pathname === PAGE_ROUTES.JOBS_SEARCH) {
      setTouchpointType(TouchpointType.Job);
    } else if (router.pathname === PAGE_ROUTES.INTERNSHIPS_SEARCH) {
      setTouchpointType(TouchpointType.Internship);
    }
  }, [router]);

  const resetFilter = useCallback(() => {
    setSearch('');
    selectedLabelsRef.current = {};
    setSelectedOptions({});
  }, []);

  useEffect(() => {
    setSelectedOptions({ ...selectedOptions, q: debouncedSearch as string });
  }, [debouncedSearch, touchpointableType]);

  const workTypeOptions = useMemo(() => {
    if (Array.isArray(selectedOptions?.work_type)) {
      const workTypes = selectedOptions?.work_type;
      const options = workTypes.map((item) => `&work_type=${item}`).join('');
      return options;
    } else return '';
  }, [selectedOptions]);

  const getOpportunitiesKey = (pageIndex: number) => {
    // Removing work_type from selectedOptions
    // as this needs to be sent as the above format instead of arrays
    const {
      work_type: _work_type,
      is_online_selected: _is_online_selected,
      country_ids,
      ...rest
    } = selectedOptions;

    const params = {
      q: debouncedSearch,
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT_2,
      remote_country_ids: country_ids,
      ...rest,
    };
    const qs = queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
    const path =
      touchpointableType || touchpointType
        ? kind
          ? `${
              API_ROUTES.CAMPAIGNS
            }?${qs}&with_details=true&touchpointable_type=${
              touchpointableType || touchpointType
            }&kind=${kind}${workTypeOptions}`
          : `${
              API_ROUTES.CAMPAIGNS
            }?${qs}&with_details=true&touchpointable_type=${
              touchpointableType || touchpointType
            }${workTypeOptions}`
        : null;
    return path;
  };
  const {
    data: opportunitiesResponse,
    error: opportunitiesError,
    size,
    setSize,
  } = useSWRInfinite<OpportunitiesResponse>(getOpportunitiesKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  const opportunities = useMemo(() => {
    return parsePaginatedResponse<OpportunityAttributes>(opportunitiesResponse);
  }, [opportunitiesResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstOpportunityList = Array.isArray(opportunitiesResponse)
      ? opportunitiesResponse[0]
      : null;
    if (firstOpportunityList) {
      return firstOpportunityList.meta.total > size * PER_PAGE_COUNT_2;
    } else {
      return false;
    }
  }, [opportunitiesResponse, size]);

  const addRecentSearch = async ({
    searchable_type,
    searchable_id,
    headers,
  }: {
    searchable_type: string;
    searchable_id: string;
    headers: Headers;
  }) => {
    const body = {
      recent_search: {
        searchable_type,
        searchable_id,
      },
    };
    try {
      await post<RecentSearchResponse>(
        API_ROUTES.RECENT_SEARCHES,
        body,
        headers
      );
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeRecentSearch = async ({
    headers,
    id,
  }: {
    headers: Headers;
    id: string;
  }) => {
    const pathname = `${API_ROUTES.RECENT_SEARCHES}/${id}`;
    try {
      const response = await remove<Notice>(pathname, headers);
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const removeAllRecentSearches = async ({ headers }: { headers: Headers }) => {
    const pathname = `${API_ROUTES.RECENT_SEARCHES}/delete_all`;
    try {
      const response = await remove<Notice>(pathname, headers);
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const {
    data: recentSearchResponse,
    mutate: mutateRecentSearches,
    error: recentSearchError,
  } = useSWR<RecentSearchesResponse>(
    isCandidate ? [API_ROUTES.RECENT_SEARCHES, headers] : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );
  const recentSearches =
    parseArrayResponse<RecentSearchAttributes>(recentSearchResponse);

  const isLoadingInitialData =
    (!opportunitiesResponse && !opportunitiesError) ||
    (isCandidate && !recentSearchResponse && !recentSearchError);

  const isLoadingMore =
    isLoadingInitialData ||
    (size > 0 &&
      opportunitiesResponse &&
      typeof opportunitiesResponse[size - 1] === 'undefined');

  return {
    resetFilter,
    search,
    setSearch,
    size,
    setSize,
    hasMoreRecords,
    isLoading: isLoadingInitialData,
    opportunities,
    recentSearches,
    mutateRecentSearches,
    addRecentSearch,
    removeRecentSearch,
    removeAllRecentSearches,
    selectedOptions,
    setSelectedOptions,
    selectedLabelsRef,
    isLoadingMore,
    setTouchpointType,
    touchpointType,
  };
};
