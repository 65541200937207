import { useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useRecoilValue } from 'recoil';
import queryString from 'query-string';

import { ManagerFilterType } from 'pages/candidate/manager/Manager.component';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { FollowingsResponse, FollowingAttributes } from 'lib/models/following';
import { parsePaginatedResponse } from 'lib/utils/parser';
import { SelectedFilterOptions } from './useFilter';

export interface ReturnType {
  followings: FollowingAttributes[];
  isLoading: boolean;
  isLoadingMore?: boolean;
  hasMoreRecords: boolean;
  followingsMetaInfo: { total?: number };
  size: number;
  setSize: (size: number) => void;
  isValidating: boolean;
  mutate: () => void;
}

const PER_PAGE_COUNT = 5;

export const useManagerFollowings = ({
  eventType,
  activeFilters,
  search,
}: {
  eventType: string;
  activeFilters?: SelectedFilterOptions | null;
  search?: string | null;
}): ReturnType => {
  const { headers } = useRecoilValue(httpHeadersState);

  const constructEndpoint = () => {
    const followedType =
      eventType === ManagerFilterType.Companies
        ? 'Company'
        : eventType === ManagerFilterType.Societies
        ? 'StudentSociety'
        : '';
    return followedType
      ? `${API_ROUTES.FOLLOW}?with_details=true&followable_types[]=${followedType}`
      : '';
  };

  const constructQueryString = (pageIndex: number) => {
    const { touchpoint_type, ...otherFilters } = activeFilters ?? {};

    const params = {
      q: search,
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT,
      ...otherFilters,
    };

    return queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
  };

  const getFollowingKey = (pageIndex: number) => {
    if (eventType) {
      return [
        `${constructEndpoint()}&${constructQueryString(pageIndex)}`,
        headers,
      ];
    }
    return null;
  };

  const {
    data: followingResponse,
    error: followingError,
    size,
    setSize,
    mutate,
    isValidating,
  } = useSWRInfinite<FollowingsResponse>(getFollowingKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  const followings = useMemo(() => {
    if (!followingResponse) return [];
    const parsedFollowing =
      parsePaginatedResponse<FollowingAttributes>(followingResponse);
    return parsedFollowing;
  }, [followingResponse]);

  const followingsMetaInfo = useMemo(() => {
    return followingResponse?.[0]?.meta || {};
  }, [followingResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstFollowingList = Array.isArray(followingResponse)
      ? followingResponse[0]
      : null;
    if (firstFollowingList) {
      return (firstFollowingList?.meta?.total || 0) > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [followingResponse, size]);

  const isLoading = !(followingResponse || followingError);

  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      followingResponse &&
      typeof followingResponse[size - 1] === 'undefined');

  return {
    followings,
    isLoading,
    isLoadingMore,
    hasMoreRecords,
    followingsMetaInfo,
    size,
    setSize,
    isValidating,
    mutate,
  };
};
