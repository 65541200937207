import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';

import { Icon, Image } from 'components';
import { Button, Checkbox, Input } from 'components/form';
import { useResponsive } from 'hooks/useResponsive';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Option } from 'lib/models/option';

import styles from './MultiSelect.module.scss';

export type Position = 'top' | 'bottom';

export interface MultiSelectProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** title */
  title: string;
  /** values */
  values: Array<string>;
  /** options */
  options: Array<Option>;
  /** onChange handler */
  onChange: (values: Array<string>) => void;
  /** is it searchable? */
  isSearchable?: boolean;
  /** alignment */
  alignment?: 'left' | 'right' | 'center';
  /** placeholder text for search */
  placeholder?: string;
  /** className for dropdown body */
  dropdownClassName?: string;
  /** show selected values count ?*/
  showCount?: boolean;
  disableOptions?: boolean;
  customButton?: React.ElementType;
  fullWidth?: boolean;
  triggerClassName?: string;
  hasBorder?: boolean;
}

export const MultiSelect: React.FC<MultiSelectProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    title,
    values,
    options,
    onChange,
    alignment = 'left',
    placeholder,
    isSearchable,
    showCount = true,
    dropdownClassName,
    disableOptions = false,
    customButton: Component,
    fullWidth = false,
    triggerClassName,
    hasBorder = false,
  }: MultiSelectProps) => {
    const contentClassNames = cn(
      {
        [styles.MultiSelect]: true,
        [styles.MultiSelectFullWidth]: fullWidth,
      },
      className
    );

    const screens = useResponsive();
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const trimmedSearch = search.trim();

    const dropdownClassNames = cn(
      {
        [styles.MultiSelectDropdown]: true,
        [styles.MultiSelectDropdownAlignLeft]: alignment === 'left',
        [styles.MultiSelectDropdownAlignRight]: alignment === 'right',
        [styles.MultiSelectDropdownAlignCenter]: alignment === 'center',
        [styles.MultiSelectDropdownFullWidth]: fullWidth,
      },
      dropdownClassName
    );

    const multiSelectRef = useRef(null);

    const onClose = useCallback(() => setOpen(false), []);

    useOnClickOutside({ ref: multiSelectRef, onOutsideClick: onClose });

    const onValueChange = (value: string) => {
      if (values.includes(value)) {
        const newValues = values.filter((val) => val !== value);
        onChange(newValues);
      } else {
        onChange([...values, value]);
      }
    };

    const onClear = () => {
      onChange([]);
    };

    useEffect(() => {
      if (open === false) {
        setSearch('');
      }
    }, [open]);
    const filteredOptions = useMemo(() => {
      return isSearchable
        ? options.filter(
            (option) =>
              option.label.toLowerCase().indexOf(trimmedSearch.toLowerCase()) >
              -1
          )
        : options;
    }, [trimmedSearch, options, isSearchable]);

    return (
      <section
        className={contentClassNames}
        style={style}
        data-testid="MultiSelect"
        ref={multiSelectRef}
      >
        {Component ? (
          <Component onClick={() => setOpen(!open)} />
        ) : (
          <button
            className={cn(
              styles.MultiSelectTrigger,
              {
                [styles.MultiSelectTriggerOpen]: open,
                [styles.MultiSelectTriggerFullWidth]: fullWidth,
                [styles.MultiSelectTriggerHasBorder]: hasBorder,
              },
              triggerClassName
            )}
            onClick={() => setOpen(!open)}
            type="button"
          >
            <p className={styles.valuesEllipsis}>{title}</p>
            <Icon
              iconName="chevron-down"
              size={screens.sm ? 'xsmall' : 'small'}
              className={cn(styles.MultiSelectIcon, {
                [styles.MultiSelectIconReverse]: open,
              })}
            />
            {showCount ? (
              <div className={styles.MultiSelectSelectedCount}>
                {values.length ? <span>{values.length}</span> : null}
              </div>
            ) : null}
          </button>
        )}
        {open ? (
          <div className={dropdownClassNames}>
            {isSearchable ? (
              <div className={styles.MultiSelectDropdownSearch}>
                <Input
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  className={styles.MultiSelectDropdownSearchInput}
                  placeholder={placeholder}
                  startIcon="search"
                  startIconSize="small"
                  endIconSize="xsmall"
                  endIcon={search ? 'cancel' : undefined}
                  onEndIconClick={() => setSearch('')}
                />
              </div>
            ) : null}
            <ul className={styles.MultiSelectDropdownOptions}>
              {filteredOptions.map(({ id, value, label, avatar }) => {
                const checked = values.includes(value);
                const isDisabled = disableOptions
                  ? !values.includes(value)
                  : false;
                return (
                  <li
                    key={id}
                    className={cn(styles.MultiSelectDropdownOption, {
                      [styles.MultiSelectDropdownOptionSelected]: checked,
                    })}
                    onClick={() => !isDisabled && onValueChange(value)}
                  >
                    <Checkbox
                      checked={checked}
                      readOnly
                      className={cn(styles.MultiSelectDropdownOptionCheckbox, {
                        [styles.MultiSelectDropdownOptionCheckboxDisabled]:
                          isDisabled,
                      })}
                      disabled={isDisabled}
                    >
                      {avatar ? <Image src={avatar} alt={label} /> : null}
                      <span>{label}</span>
                    </Checkbox>
                  </li>
                );
              })}
            </ul>
            {filteredOptions.length === 0 ? (
              <p className={styles.MultiSelectDropdownNoResults}>
                No results found.
              </p>
            ) : null}
            <div className={styles.MultiSelectDropdownFooter}>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={onClear}
                type="button"
              >
                Clear
              </Button>
            </div>
          </div>
        ) : null}
      </section>
    );
  }
);

MultiSelect.displayName = 'MultiSelect';
