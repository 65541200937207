import React, { ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { IconName } from 'components';

import { ButtonV3, DropdownV3 } from 'components/ComponentV2';
import { PAGE_ROUTES } from 'lib/page-routes';
import { FiltersMode } from '../index.page';
import { ManagerFilterType } from '../../Manager.component';

import styles from '../ManagerFilters.module.scss';

const TouchpointsFilters = ({
  mode,
  selectedTouchpoint,
  onClickFilter,
  resetFilter,
}: {
  mode: FiltersMode;
  selectedTouchpoint?: string;
  onClickFilter: (key: string, value: string) => void;
  resetFilter: () => void;
}): ReactElement => {
  const router = useRouter();

  const touchPointFiltersList = [
    {
      id: '1',
      label: 'Saved',
      iconName: 'bookmark' as IconName,
      value: ManagerFilterType.Saved,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
    {
      id: '2',
      label: 'Started',
      iconName: 'clock' as IconName,
      value: ManagerFilterType.Started,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
    {
      id: '3',
      label: 'Applied',
      iconName: 'icon_briefcase' as IconName,
      value: ManagerFilterType.Applied,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
    {
      id: '4',
      label: 'Registered',
      iconName: 'icon_party' as IconName,
      value: ManagerFilterType.Registered,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
    {
      id: '5',
      label: 'Communities',
      iconName: 'icon_multiple-11' as IconName,
      value: ManagerFilterType.Societies,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
    {
      id: '6',
      label: 'Companies',
      iconName: 'office' as IconName,
      value: ManagerFilterType.Companies,
      onClick: (value: string) => onClickFilter('touchpoint_type', value),
    },
  ];

  const selectedFilterIcon = useMemo(() => {
    const labelsList = touchPointFiltersList
      ?.filter((item) => {
        if (selectedTouchpoint === item.value) return item.iconName;
      })
      .map((item) => item.iconName);
    return labelsList?.join(', ') || '';
  }, [selectedTouchpoint]);

  return (
    <>
      {mode === FiltersMode.Dropdown ? (
        <DropdownV3
          label=""
          value={selectedTouchpoint || ''}
          onChange={(value) => onClickFilter('touchpoint_type', value)}
          options={touchPointFiltersList || []}
          selectedValueLabel={selectedTouchpoint || ''}
          isValid={true}
          className={styles.dropdown}
          dropdownBtnClassName={styles.dropdownButton}
          iconClassName={styles.icon}
          startIcon={(selectedFilterIcon as IconName) || undefined}
          startIconClassName={styles.startIcon}
          displayShowAllButton={true}
          onClickShowAll={() => {
            resetFilter();
            router.push(PAGE_ROUTES.MANAGER);
          }}
        />
      ) : (
        <>
          {touchPointFiltersList.map(
            ({ id, label, iconName, value, onClick }) => (
              <ButtonV3
                key={id}
                className={cn(styles.ManagerFiltersMultiselectWrapper)}
                type="button"
                startIcon={(iconName as IconName) || undefined}
                iconSize={'medium'}
                color={'silent'}
                onClick={() => onClick(value)}
              >
                <p className={styles.ManagerFiltersMultiselectButtonTitle}>
                  {label}
                </p>
              </ButtonV3>
            )
          )}
        </>
      )}
    </>
  );
};

export default TouchpointsFilters;
