import cn from 'classnames';
import { noop } from 'lodash';
import { Trans, useTranslation } from 'next-i18next';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Icon, IconButton, Link } from 'components';
import { InputV2 } from 'components/ComponentV2';
import CVUpload from 'components/candidate/CVUpload';
import {
  ButtonV2,
  DropdownV2,
  Form as FormComponent,
  TextArea,
} from 'components/form';
import { Row } from 'components/layout';
import { FLAG_STATUS_CODES, useCandidate } from 'hooks/useCandidate';
import { useCommon } from 'hooks/useCommon';
import { useNotification } from 'hooks/useNotification';
import { API_ROUTES } from 'lib/api-routes';
import { useCommonList } from 'lib/contexts/common-list-context';
import { useApp } from 'lib/contexts/save-in-app-context';
import { TUserApiResponse } from 'lib/models/User.model';
import { QuestionState } from 'lib/models/answers';
import { CandidateResponse } from 'lib/models/candidate';
import { OpportunityAttributes } from 'lib/models/opportunity';
import { TouchpointType } from 'lib/models/touchpoint';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { REGEX } from 'lib/utils/regex';
import { TERMS_OF_USE } from 'utils/urls';
import styles from '../RegistrationForm.module.scss';
import { verifyOTPModalInstance } from '../VerifyOTPModal';
import MultiSelect from './MultiSelect';
import SingleSelect from './SingleSelect';

interface Inputs {
  first_name: string;
  last_name: string;
  email: string;
  graduation_year: number;
  linkedInURL: string;
}

export interface RegistrationFormProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  touchpoint: OpportunityAttributes;
  onComplete: (value: FLAG_STATUS_CODES) => void;
  isCandidate?: boolean;
  isJoinWaitlist: boolean;
  showBackbutton?: boolean;
  handleBackButton?: () => void;
  onClose: () => void;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    touchpoint,
    onComplete,
    isCandidate = false,
    isJoinWaitlist,
    showBackbutton,
    handleBackButton,
    onClose,
  }: RegistrationFormProps) => {
    const contentClassNames = cn(
      {
        [styles.RegistrationForm]: true,
      },
      className
    );
    const [tCommon] = useTranslation('common');

    const notificationInstance = useNotification();
    const { updateUserApiResponse, candidate: userInfo } = useAuth();
    const {
      education_histories,
      university: universityResponse,
      degree: degreeResponse,
      subject: subjectResponse,
      graduation_year,
      first_name,
      last_name,
      linkedin_url: linkedIn_url,
      resume_url,
    } = userInfo || {};

    const { loginCandidate, updateCandidateResume, isNewCandidate } =
      useCandidate();
    const { registerEvent } = useCommon();
    const { setEmail } = useApp();
    const {
      universities,
      subjects: subjectList,
      graduateDateOptions,
      degrees,
    } = useCommonList();

    const [shouldVerifyOTP, setVerifyOTP] = useState(false);
    const [graduationYear, setGraduationYear] = useState<number>();
    const [universityId, setUniversityId] = useState('');
    const [subjectId, setSubjectId] = useState('');
    const [degreeId, setDegreeId] = useState('');
    const [answer, setAnswer] = useState<
      Array<Record<string, string | string[]>>
    >([]);
    const [resume, setResumeFile] = useState<Record<string, string> | null>(
      null
    );

    const {
      register,
      handleSubmit,
      setValue,
      getValues,
      formState: { isSubmitting, isSubmitted, errors, isValid },
    } = useForm({ mode: 'all' });

    const educationHistories = useMemo(() => {
      const university = parseResponse(universityResponse);
      const degree = parseResponse(degreeResponse);
      const subject = parseResponse(subjectResponse);
      const educationHistoriesData = parseArrayResponse(education_histories);

      if (!educationHistoriesData || educationHistoriesData.length === 0) {
        return {};
      }

      const {
        id,
        university_id,
        university: universityData,
        subject_id,
        university_name,
        degree_id,
      } = educationHistoriesData[0];
      const parsedUniversityData = parseResponse(universityData);
      setUniversityId(university_id || university?.id);
      setSubjectId(subject_id || subject?.id);
      setDegreeId(degree_id || degree?.id);
      setGraduationYear(graduation_year || graduationYear);
      return {
        subject_id: subject_id || subjectId,
        degree_id: degree_id || degreeId,
        id,
        universityName:
          parsedUniversityData?.name || university_name || university?.name,
      };
    }, [education_histories]);

    useEffect(() => {
      if (linkedIn_url) setValue('linkedInURL', linkedIn_url);
    }, [linkedIn_url]);

    const {
      id: touchpoint_id,
      touchpointable_type,
      university_required,
      study_subject_required,
      degree_required,
      study_year_required,
      cv_required,
      linkedin_profile_required,
      university_required_state,
      study_year_required_state,
      degree_required_state,
      study_subject_required_state,
      cv_required_state,
      linkedin_profile_required_state,
      applicable_questions: questionsResponse,
    } = touchpoint || {};

    const selectedUniversityLabel =
      universities.find((university) => university.id === universityId)
        ?.label || '';

    const selectedSubjectLabel =
      subjectList.find((subject) => subject.id === subjectId)?.label || '';

    const selectedDegreeLabel =
      degrees.find((degree) => degree.id === degreeId)?.label || '';

    const questions = useMemo(() => {
      return parseArrayResponse(questionsResponse);
    }, [questionsResponse]);

    const noOfReqdCustomQuestionPresent = (
      questions &&
      questions.filter(({ state }) => state === QuestionState.REQUIRED)
    ).length;

    const noOfCustomQuestionPresent = questions && questions.length;

    const onAnswerChangeHandler = (
      id: string,
      newAnswer: string | string[],
      state: string,
      isOptions?: boolean
    ) => {
      const newAnswerSet = answer.filter((item) => item.question_id !== id);

      if (newAnswer && newAnswer.length) {
        setAnswer([
          ...newAnswerSet,
          {
            question_id: id,
            [isOptions ? 'options' : 'value']: newAnswer,
            state,
          },
        ]);
      } else {
        setAnswer([...newAnswerSet]);
      }
    };

    const isEvent = touchpointable_type === TouchpointType.Event;

    const customQuestionList = useMemo(() => {
      return (
        questions &&
        questions.map(({ id, title, state, question_type, options }) => {
          return (
            <div key={id} className={styles.customQuestionContainer}>
              <p className={styles.labelTitle}>
                {title}
                {state === QuestionState.OPTIONAL ? (
                  <span className={styles.optionalLabel}>
                    ({tCommon('registration-form.optional')})
                  </span>
                ) : null}
              </p>

              {question_type === 'single_choice' && (
                <SingleSelect
                  key={id}
                  options={options.map((option: string) => {
                    return { id: option, value: option, label: option };
                  })}
                  title={'Choose your answer...'}
                  value={
                    (answer.filter((ans) => ans.question_id === id)[0]
                      ?.options[0] as string) || ''
                  }
                  onChange={(value) => {
                    onAnswerChangeHandler(id || '', [value], state, true);
                  }}
                />
              )}
              {question_type === 'yes_no' && (
                <SingleSelect
                  key={id}
                  options={[
                    { id: 'yes', value: 'yes', label: 'Yes' },
                    { id: 'no', value: 'no', label: 'No' },
                  ]}
                  value={
                    (answer.filter((ans) => ans.question_id === id)[0]
                      ?.options[0] as string) || ''
                  }
                  onChange={(value) => {
                    onAnswerChangeHandler(id || '', [value], state, true);
                  }}
                  className={cn(styles.dropdown, styles.list)}
                  title={'Choose Yes or No'}
                />
              )}
              {question_type === 'multiple_choice' && (
                <>
                  <MultiSelect
                    title={
                      (
                        answer.filter((ans) => ans.question_id === id)[0]
                          ?.options as string[]
                      )?.length
                        ? (
                            answer.filter((ans) => ans.question_id === id)[0]
                              ?.options as string[]
                          )?.join(', ')
                        : 'Choose your answer(s)...'
                    }
                    placeholder="Search"
                    options={options.map((option: string) => {
                      return { id: option, value: option, label: option };
                    })}
                    values={
                      (answer.filter((ans) => ans.question_id === id)[0]
                        ?.options as string[]) || []
                    }
                    onChange={(value) =>
                      onAnswerChangeHandler(id || '', value, state, true)
                    }
                    showCount={false}
                    fullWidth={true}
                    triggerClassName={`${styles.multiSelectTrigger} ${
                      (
                        answer.filter((ans) => ans.question_id === id)[0]
                          ?.options as string[]
                      )?.length && styles.selectedValue
                    }`}
                    hasBorder
                    className={styles.list}
                  />
                </>
              )}

              {question_type === 'numeric' && id && (
                <InputV2
                  placeholder={title}
                  onChange={(event) => {
                    const value = event.target.value
                      .replace(/[^0-9.]/g, '')
                      .replace(/(\..*)\./g, '$1');
                    onAnswerChangeHandler(id || '', value, state);
                  }}
                  value={
                    (answer.filter((ans) => ans.question_id === id)[0]
                      ?.value as string) || ''
                  }
                  className={cn(styles.input)}
                />
              )}
              {(question_type === 'paragraph' ||
                question_type === 'short_answer') && (
                <>
                  <TextArea
                    rows={1}
                    placeholder={'Type your answer here'}
                    onChange={(event) =>
                      onAnswerChangeHandler(id || '', event.target.value, state)
                    }
                    isValid={true}
                    className={cn(styles.textarea, {
                      [styles.paragraph]: question_type === 'paragraph',
                    })}
                    textAreaClassName={styles.textareaBio}
                    value={
                      answer.filter((ans) => ans.question_id === id)[0]
                        ?.value as string
                    }
                  />
                </>
              )}
            </div>
          );
        })
      );
    }, [questions, answer]);

    const onResumeSelectDone = ({
      name,
      file,
    }: {
      name: string;
      file: string;
    }) => {
      setResumeFile({ name, file });
    };

    const requiredAnswers =
      answer?.filter((ans) => ans?.state === QuestionState.REQUIRED) ?? [];

    const isInvalid =
      ((linkedin_profile_required ||
        linkedin_profile_required_state === QuestionState.REQUIRED) &&
        !(getValues('linkedInURL') || linkedIn_url)) ||
      ((university_required ||
        university_required_state === QuestionState.REQUIRED) &&
        !universityId) ||
      ((study_subject_required ||
        study_subject_required_state === QuestionState.REQUIRED) &&
        !subjectId) ||
      ((degree_required || degree_required_state === QuestionState.REQUIRED) &&
        !degreeId) ||
      ((cv_required || cv_required_state === QuestionState.REQUIRED) &&
        !resume &&
        !resume_url) ||
      ((study_year_required ||
        study_year_required_state == QuestionState.REQUIRED) &&
        !graduationYear) ||
      (noOfReqdCustomQuestionPresent > 0 &&
        requiredAnswers.length !== noOfReqdCustomQuestionPresent);

    const getCandidateData = async () => {
      try {
        const { data } = await apiInstance.get<CandidateResponse>(
          API_ROUTES.CANDIDATE
        );
        if (data) {
          const user = parseResponse(data);
          const {
            first_name,
            last_name,
            degree,
            university,
            resume_url,
            linkedin_url,
            subject,
          } = user;
          const candidateInfo = {
            candidateFirstName: first_name,
            candidateLastName: last_name,
            candidateResumeUrl: resume_url,
            candidateLinkedInUrl: linkedin_url,
            candidateSubjectId: subject?.data?.id,
            candidateUniversityId: university?.data?.id,
            candidateDegreeId: degree?.data?.id,
            candidate_graduation_year: graduationYear,
          };
          return candidateInfo;
        }
      } catch (error) {
        console.error(error);
      }
    };

    const onRegisterEvent = async ({
      data,
      isExistingUser,
    }: {
      data: Inputs;
      isExistingUser?: boolean;
    }) => {
      try {
        const { email, first_name, last_name, linkedInURL } = data || {};
        if (isExistingUser) {
          const candidateInfo = await getCandidateData();
          if (candidateInfo) {
            const {
              candidateFirstName,
              candidateLastName,
              candidate_graduation_year,
              candidateLinkedInUrl,
              candidateSubjectId,
              candidateUniversityId,
              candidateDegreeId,
            } = candidateInfo;

            const applicationResponse = await registerEvent({
              touchpoint_id,
              touchpointable_type,
              first_name: !candidateFirstName ? first_name : undefined,
              last_name: !candidateLastName ? last_name : undefined,
              email,
              education_histories_id: educationHistories.id || '',
              is_privacy_terms_accepted: true,
              graduation_year:
                (study_year_required ||
                  (study_year_required_state &&
                    study_year_required_state !== QuestionState.OFF)) &&
                !candidate_graduation_year
                  ? graduationYear
                  : undefined,
              linkedInURL:
                (linkedin_profile_required ||
                  (linkedin_profile_required_state &&
                    linkedin_profile_required_state !== QuestionState.OFF)) &&
                !candidateLinkedInUrl
                  ? linkedInURL
                  : undefined,
              subject_id:
                (study_subject_required ||
                  (study_subject_required_state &&
                    study_subject_required_state !== QuestionState.OFF)) &&
                !candidateSubjectId
                  ? subjectId
                  : undefined,
              degree_id:
                (degree_required ||
                  (degree_required_state &&
                    degree_required_state !== QuestionState.OFF)) &&
                !candidateDegreeId
                  ? degreeId
                  : undefined,
              university_id:
                (university_required ||
                  (university_required_state &&
                    university_required_state !== QuestionState.OFF)) &&
                !candidateUniversityId
                  ? universityId
                  : undefined,
              resume_filename: undefined,
              resume_file: undefined,
              answer:
                noOfCustomQuestionPresent > 0 && answer.length > 0
                  ? answer
                  : undefined,
              isJoinWaitlist,
            });
            return applicationResponse;
          }
        } else {
          const applicationResponse = await registerEvent({
            touchpoint_id,
            touchpointable_type,
            first_name,
            last_name,
            email,
            is_privacy_terms_accepted: true,
            graduation_year:
              study_year_required ||
              (study_year_required_state &&
                study_year_required_state !== QuestionState.OFF)
                ? graduationYear
                : undefined,
            linkedInURL:
              linkedin_profile_required ||
              (linkedin_profile_required_state &&
                linkedin_profile_required_state !== QuestionState.OFF)
                ? linkedInURL
                : undefined,
            subject_id:
              study_subject_required ||
              (study_subject_required_state &&
                study_subject_required_state !== QuestionState.OFF)
                ? subjectId
                : undefined,
            degree_id:
              degree_required ||
              (degree_required_state &&
                degree_required_state !== QuestionState.OFF)
                ? degreeId
                : undefined,
            university_id:
              university_required ||
              (university_required_state &&
                university_required_state !== QuestionState.OFF)
                ? universityId
                : undefined,
            // resume_filename: (cv_required|| cv_required_state !== QuestionState.OFF) && resume ? resume.name : undefined,
            // resume_file: (cv_required|| cv_required_state !== QuestionState.OFF) && resume ? resume.file : undefined,
            resume_filename: undefined,
            resume_file: undefined,
            answer:
              noOfCustomQuestionPresent > 0 && answer.length > 0
                ? answer
                : undefined,
            isJoinWaitlist,
          });
          if (
            (cv_required ||
              (cv_required_state && cv_required_state !== QuestionState.OFF)) &&
            resume &&
            resume.name &&
            resume.file
          ) {
            await updateCandidateResume({
              name: resume ? resume.name : '',
              file: resume ? resume.file : '',
            });
          }

          return applicationResponse;
        }
      } catch (error) {
        console.error(error);
      }
    };

    /** For non signed in users:
     * New user - Signed out job / internship application --> OTP Required
     * Signed in user - job / internship application --> OTP is shown before application can be made (Husein gave designs for this yday) if the person hasn’t verified email yet
     * New user - Signed out event registration --> OTP not required
     * Existing user - Signed out event registration --> OTP Required to verify it’s the same person
     * */
    const onSubmitForm: SubmitHandler<Inputs> = async (data) => {
      let applicationResponse;
      if (isInvalid) {
        return;
      } else {
        if (isCandidate) {
          applicationResponse = await onRegisterEvent({ data });
          if (applicationResponse) {
            onComplete(FLAG_STATUS_CODES.EXISTING_USER_REGISTER_SUCCESS);
          } else {
            onComplete(FLAG_STATUS_CODES.REGISTER_FAILURE);
          }
        } else {
          try {
            const { email } = data;
            const response = await loginCandidate({
              email,
              source: 'touchpoint_registration',
              touchpointable_type,
            });
            if (isNewCandidate(response) && isEvent) {
              updateUserApiResponse(response as TUserApiResponse);
              applicationResponse = await onRegisterEvent({ data });
              if (applicationResponse) {
                onComplete(FLAG_STATUS_CODES.NEW_USER_REGISTER_SUCCESS);
              } else {
                onComplete(FLAG_STATUS_CODES.REGISTER_FAILURE);
              }
            } else {
              setEmail(email);
              setVerifyOTP(true);
              await verifyOTPModalInstance({ isJoinWaitlist });
              const applicationResponse = await onRegisterEvent({
                data,
                isExistingUser: true,
              });
              if (applicationResponse) {
                onComplete(FLAG_STATUS_CODES.EXISTING_USER_REGISTER_SUCCESS);
              } else {
                onComplete(FLAG_STATUS_CODES.REGISTER_FAILURE);
              }
            }
          } catch (error) {
            // Do not show error when otp modal is closed and also remove backdrop
            if (error) {
              console.error(error);
              notificationInstance.handleExceptionError(error as Error);
            }
          } finally {
            setVerifyOTP(false);
            onClose && onClose();
          }
        }
      }
    };

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="RegistrationForms"
      >
        <FormComponent
          id="course-form"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmitForm)}
          style={shouldVerifyOTP ? { display: 'none' } : undefined}
        >
          <div className={cn(styles.section, styles.top)}>
            <div className={styles.header}>
              <IconButton
                iconName="chevron-left"
                onClick={handleBackButton && handleBackButton}
                className={cn(styles.back, {
                  [styles.backButtonHidden]: !showBackbutton,
                })}
                iconClassName={styles.backIcon}
              />
              <p className={styles.title}>
                {isJoinWaitlist
                  ? tCommon('registration-form.join-waitlist')
                  : isEvent
                  ? tCommon('registration-form.register')
                  : tCommon('registration-form.apply')}
              </p>
              <IconButton
                size="xsmall"
                iconName="close"
                className={styles.cancelIcon}
                onClick={onClose}
              />
            </div>
          </div>

          <div className={styles.content}>
            {isCandidate ? (
              <p className={styles.desc}>
                {tCommon('registration-form.registration-desc')}
              </p>
            ) : (
              <p className={styles.desc}>
                {isEvent
                  ? tCommon('registration-form.register-for-event')
                  : tCommon('registration-form.application')}
                {tCommon('registration-form.require-details')}
              </p>
            )}
            <div className={styles.question}>
              {isCandidate && first_name ? null : (
                <InputV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.first-name')}
                  placeholder={tCommon('registration-form.enter-first-name')}
                  {...register('first_name', {
                    required: 'Required',
                  })}
                  isInvalid={errors.first_name}
                  helperText={errors.first_name?.message}
                  className={cn(styles.input)}
                />
              )}
              {isCandidate && last_name ? null : (
                <InputV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.last-name')}
                  placeholder={tCommon('registration-form.enter-last-name')}
                  {...register('last_name', {
                    required: 'Required',
                  })}
                  isInvalid={errors.last_name}
                  helperText={errors.last_name?.message}
                  className={cn(styles.input)}
                />
              )}
              {isCandidate ? null : (
                <InputV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.email-address')}
                  placeholder={tCommon('registration-form.enter-email-address')}
                  {...register('email', {
                    required: 'Required',
                    pattern: {
                      value: REGEX.EMAIL,
                      message: 'Enter a valid e-mail address',
                    },
                  })}
                  isInvalid={errors.email}
                  helperText={errors.email?.message}
                  className={cn(styles.input)}
                />
              )}
              {(university_required ||
                (university_required_state &&
                  university_required_state !== QuestionState.OFF)) &&
              !educationHistories.universityName ? (
                <DropdownV2
                  labelClassName={styles.label}
                  title={tCommon('registration-form.university')}
                  label={tCommon('registration-form.select-university')}
                  value={universityId}
                  onChange={(value) => setUniversityId(value)}
                  options={universities}
                  isSearchable={true}
                  searchPlaceholder={tCommon('search')}
                  selectedValueLabel={selectedUniversityLabel}
                  isValid={
                    !(
                      isSubmitted &&
                      !universityId &&
                      (university_required ||
                        university_required_state === QuestionState.REQUIRED)
                    )
                  }
                  helperText="Required"
                  noResultsOption="Other"
                  className={cn(styles.input, styles.list)}
                  showOptionalLabel={
                    university_required_state === QuestionState.OPTIONAL
                  }
                  optionsClassName={styles.dropdownV2Options}
                />
              ) : null}
              {(degree_required ||
                (degree_required_state &&
                  degree_required_state !== QuestionState.OFF)) &&
              !educationHistories.degree_id ? (
                <DropdownV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.select-degree')}
                  title={tCommon('registration-form.degree')}
                  value={degreeId}
                  onChange={(value) => setDegreeId(value)}
                  options={degrees}
                  selectedValueLabel={selectedDegreeLabel}
                  isValid={
                    !(
                      isSubmitted &&
                      !degreeId &&
                      (degree_required_state ||
                        degree_required_state === QuestionState.REQUIRED)
                    )
                  }
                  helperText="Required"
                  className={cn(styles.input, styles.list)}
                  showOptionalLabel={
                    degree_required_state === QuestionState.OPTIONAL
                  }
                  optionsClassName={styles.dropdownV2Options}
                />
              ) : null}
              {(study_subject_required ||
                (study_subject_required_state &&
                  study_subject_required_state !== QuestionState.OFF)) &&
              !educationHistories.subject_id ? (
                <DropdownV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.select-subject')}
                  title={tCommon('registration-form.subject')}
                  value={subjectId}
                  onChange={(value) => setSubjectId(value)}
                  options={subjectList}
                  isSearchable={true}
                  searchPlaceholder={tCommon('search')}
                  selectedValueLabel={selectedSubjectLabel}
                  isValid={
                    !(
                      isSubmitted &&
                      !subjectId &&
                      (study_subject_required ||
                        study_subject_required_state === QuestionState.REQUIRED)
                    )
                  }
                  helperText="Required"
                  className={cn(styles.input, styles.list)}
                  showOptionalLabel={
                    study_subject_required_state === QuestionState.OPTIONAL
                  }
                  optionsClassName={styles.dropdownV2Options}
                />
              ) : null}
              {(study_year_required ||
                (study_year_required_state &&
                  study_year_required_state !== QuestionState.OFF)) &&
              !graduation_year ? (
                <DropdownV2
                  label={tCommon('registration-form.select-graduation-year')}
                  labelClassName={styles.label}
                  title={tCommon('registration-form.graduation-year')}
                  value={graduationYear?.toString() || ''}
                  onChange={(value) => setGraduationYear(parseInt(value))}
                  options={graduateDateOptions}
                  searchPlaceholder={tCommon('search')}
                  selectedValueLabel={graduationYear?.toString() || ''}
                  className={cn(styles.input, styles.list)}
                  showOptionalLabel={
                    study_year_required_state === QuestionState.OPTIONAL
                  }
                  optionsClassName={styles.dropdownV2Options}
                  isValid={
                    !(
                      isSubmitted &&
                      !graduationYear &&
                      (study_year_required ||
                        study_year_required_state === QuestionState.REQUIRED)
                    )
                  }
                />
              ) : null}
              {(linkedin_profile_required ||
                (linkedin_profile_required_state &&
                  linkedin_profile_required_state !== QuestionState.OFF)) &&
              !linkedIn_url ? (
                <InputV2
                  labelClassName={styles.label}
                  label={tCommon('registration-form.linkedin-profile-link')}
                  placeholder={tCommon(
                    'registration-form.add-linkedin-profile-link'
                  )}
                  {...register('linkedInURL', {
                    required:
                      linkedin_profile_required ||
                      linkedin_profile_required_state === QuestionState.REQUIRED
                        ? 'Required'
                        : false,
                    pattern: {
                      value: REGEX.URL,
                      message: 'Enter a valid url',
                    },
                  })}
                  isInvalid={errors.linkedInURL}
                  helperText={errors.linkedInURL?.message}
                  className={styles.input}
                  showOptionalLabel={
                    linkedin_profile_required_state === QuestionState.OPTIONAL
                  }
                />
              ) : null}
              {(cv_required ||
                (cv_required_state &&
                  cv_required_state !== QuestionState.OFF)) &&
              !resume_url ? (
                <>
                  <p className={styles.labelTitle}>
                    CV
                    {cv_required_state === QuestionState.OPTIONAL ? (
                      <span className={styles.optionalLabel}>
                        {' '}
                        ({tCommon('registration-form.optional')})
                      </span>
                    ) : null}
                  </p>
                  {resume ? (
                    <p className={styles.resume}>{resume.name}</p>
                  ) : (
                    <CVUpload
                      onDone={noop}
                      showWithoutModal={true}
                      onResumeSelectDone={onResumeSelectDone}
                    />
                  )}
                </>
              ) : null}
              {customQuestionList}
              {!isCandidate && (
                <Row align="start" wrap={false} className={styles.policy}>
                  <Icon
                    iconName="tick-mark-circle"
                    size="large"
                    className={styles.tick}
                  />
                  <p className={styles.policyTitle}>
                    <Trans t={tCommon} i18nKey="register-form.terms">
                      I accept the&nbsp;
                      <span>
                        <Link href={TERMS_OF_USE}>Terms of Service&nbsp;</Link>
                        and&nbsp;
                        <Link href={TERMS_OF_USE}>Privacy Policy.</Link>
                      </span>
                    </Trans>
                  </p>
                </Row>
              )}
            </div>
          </div>
          <div className={cn(styles.section, styles.bottom)}>
            <div className={styles.separator}></div>
            <div className={styles.footer}>
              <ButtonV2
                isLoading={isSubmitting}
                disabled={!isValid || isInvalid}
                className={styles.button}
              >
                {isEvent
                  ? isJoinWaitlist
                    ? tCommon('registration-form.join-waitlist')
                    : tCommon('registration-form.complete-registration')
                  : tCommon('registration-form.submit-application')}
              </ButtonV2>
            </div>
          </div>
        </FormComponent>
      </div>
    );
  }
);

RegistrationForm.displayName = 'RegistrationForm';
