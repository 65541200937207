import { saveAs } from 'file-saver';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { API_ROUTES } from 'lib/api-routes';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { TCandidate, TUserApiResponse } from 'lib/models/User.model';
import {
  AccomplishmentResponseAttributes,
  ExperienceAttributes,
  ExtraCurricularAttributes,
} from 'lib/models/candidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { apiInstance } from 'lib/utils/axios';
import { patch } from 'lib/utils/http';
import { useNotification } from './useNotification';

export enum USERINFO_LABELS {
  BASIC_INFO = 'Basic info',
  EDUCATION = 'Education',
  EXPERIENCE = 'Experience',
  EXTRA_CURRICULAR_ACTIVITIES = 'Extra curricular activities',
  SKILLS = 'Skills and achievements',
  DEMOGRAPHIC_INFO = 'Demographic information',
}

export interface UserInfoStatusType {
  label: USERINFO_LABELS;
  text: string;
  desc: string;
  recommended: boolean;
  completed: boolean;
}

export interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface DemographicInfoType {
  gender?: string;
  ethnicities?: Array<string> | undefined;
  physical_disability?: string;
  congnitive_disability?: string;
  socio_economic_status?: string;
}

export interface SchoolDetailsType {
  id: string;
  type: string;
  school_name: string;
  school_type: string;
  start_date: string;
  end_date: string;
  description: string;
  location: string;
  isNew: boolean;
}

/* Making few fields as optional in UniversityDetailsType,
 *  they are reqd in build profile
 *  but are optional in Onboarding flow
 */
export interface UniversityDetailsType {
  id: string;
  type: string;
  university: {
    id?: string;
    name: string;
  };
  degree: {
    id: string;
    name: string;
  };
  subject: {
    id: string;
    name: string;
  };
  study_year: string | null;
  start_date?: string;
  end_date?: string;
  description?: string;
  isEndDateFuture?: boolean;
  currently_studying?: boolean;
  isNew: boolean;
  degree_name?: string;
  location?: string;
  graduation_year?: number;
}

//New education university attributes
//TODO check if we can used the one in candidate models
export interface UniversityAttributes {
  id: string;
  start_date?: string;
  end_date?: string;
  description?: string | string[];
  university_id?: string;
  university_name?: string;
  degree_id: string;
  subject_id: string;
  subject_name: string;
  education_type: string;
  currently_studying?: boolean;
  current_study_year: number | null;
  degree_name?: string;
  location?: string;
}

export interface SchoolAttributes {
  id: string;
  start_date?: string;
  end_date?: string;
  description?: string | string[];
  school_name: string;
  school_type: string;
  education_type: string;
  location?: string;
}

type EducationAttributes = UniversityAttributes | SchoolAttributes;

export interface DestroyAttributes {
  id: string;
  _destroy: true;
}
export const useCandidateProfile = () => {
  const notificationInstance = useNotification();
  const { headers, plainHeaders } = useRecoilValue(httpHeadersState);
  const { updateUserApiResponse, candidate } = useAuth();

  const [candidateDemographicInfo, setCandidateDemographicInfo] =
    useState<DemographicInfoType>({
      gender: '',
      ethnicities: [],
      physical_disability: '',
      congnitive_disability: '',
      socio_economic_status: '',
    });

  const {
    gender,
    ethnicities,
    physical_disability,
    congnitive_disability,
    socio_economic_status,
  } = candidate || {};

  useEffect(() => {
    if (candidate) {
      setCandidateDemographicInfo({
        gender,
        ethnicities: ethnicities?.data?.map((item) => item.id),
        congnitive_disability,
        physical_disability,
        socio_economic_status,
      });
    }
  }, [candidate]);

  const generateCV = async () => {
    try {
      const { first_name, last_name } = candidate || {};
      const { NEXT_PUBLIC_API_HOST_URL } = process.env;
      plainHeaders.set('Content-Type', 'application/pdf');
      plainHeaders.set('Accept', 'application/pdf');
      plainHeaders.set('Response-Type', 'application/pdf');
      const response = await fetch(
        `${NEXT_PUBLIC_API_HOST_URL}${API_ROUTES.CANDIDATE_DOWNLOAD_CV}`,
        {
          method: 'POST',
          headers: plainHeaders,
        }
      );
      const data = await response.blob();
      const blobURL = window.URL.createObjectURL(data);
      const fileName = `${first_name}-${last_name}-CV.pdf`;
      await saveAs(blobURL, fileName);
      notificationInstance.success({
        title: 'CV downloaded!',
        message: 'Your CV has been downloaded successfully.',
      });
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateUniversity = async (info: UniversityDetailsType) => {
    const {
      id,
      isNew,
      university,
      degree,
      subject,
      start_date,
      end_date,
      description,
      study_year,
      degree_name,
      location,
      currently_studying,
      graduation_year,
    } = info;
    const body = {
      candidate: {
        graduation_year: graduation_year ? Number(graduation_year) : undefined,
        education_histories_attributes: [
          {
            id: isNew ? '' : id,
            start_date,
            end_date,
            description: !Array.isArray(description)
              ? description
                  ?.split('\n')
                  .map((item) => item.replace(/•/g, '').trim())
              : description,
            ...(university.id && { university_id: university.id }),
            ...(!university.id && { university_name: university.name }),
            degree_id: degree.id,
            subject_id: subject.id,
            subject_name: subject.name,
            education_type: 'university',
            currently_studying: currently_studying,
            current_study_year: study_year ? Number(study_year) : null,
            ...(degree_name && { degree_name }),
            location,
          },
        ],
      },
    };
    try {
      const response = await patch<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body,
        headers
      );
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const updateCandidate = async (
    candidate: Partial<Omit<TCandidate, 'resume'>> & {
      remove_avatar?: boolean;
      avatar?: { file: string };
      graduation_year?: number;
      education_histories_attributes?:
        | EducationAttributes[]
        | DestroyAttributes;
      work_histories_attributes?: ExperienceAttributes[] | DestroyAttributes;
      extra_curricular_activities_attributes?:
        | ExtraCurricularAttributes[]
        | DestroyAttributes;
      accomplishments_attributes?: (
        | AccomplishmentResponseAttributes
        | DestroyAttributes
      )[];
      ethnicity_ids?: Array<string>;
      language_ids?: Array<string>;
      resume?: { _destroy?: true; name?: string; file?: string };
    },
    hideNotification?: boolean,
    isDeleted?: boolean
  ) => {
    const body = { candidate };

    if (!hideNotification)
      notificationInstance.acknowledge({
        message: isDeleted ? 'Deleting' : 'Saving',
        variant: 'micro',
        borderRounded: true,
        icon: 'icon_info',
      });
    try {
      const { data } = await apiInstance.patch<TUserApiResponse>(
        API_ROUTES.CANDIDATE,
        body
      );
      if (!hideNotification)
        notificationInstance.acknowledge({
          message: isDeleted ? 'Deleted' : 'Saved',
          variant: 'micro',
          borderRounded: true,
          icon: 'icon_info',
        });
      updateUserApiResponse(data);
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const [userInfoStatus, setUserInfoStatus] = useState<
    Array<{
      label: USERINFO_LABELS;
      text: string;
      desc: string;
      recommended: boolean;
      completed: boolean;
    }>
  >([
    {
      label: USERINFO_LABELS.BASIC_INFO,
      text: 'Basic info',
      desc: 'Add your profile photo, first and last name.',
      recommended: true,
      completed: false,
    },
    {
      label: USERINFO_LABELS.EDUCATION,
      text: 'Add education',
      desc: 'Add your study details, GCSEs and any career related courses.',
      recommended: true,
      completed: false,
    },
    {
      label: USERINFO_LABELS.EXPERIENCE,
      text: 'Add experience',
      desc: "Add details about your experiences and we'll write the description for you or suggest improvements.",
      recommended: true,
      completed: false,
    },
    {
      label: USERINFO_LABELS.EXTRA_CURRICULAR_ACTIVITIES,
      text: 'Add extra curricular activities',
      desc: 'Add activities such as societies, volunteering or leadership roles.',
      recommended: false,
      completed: false,
    },
    {
      label: USERINFO_LABELS.SKILLS,
      text: 'Add skills, achievements & interests',
      desc: 'Add awards, technical skills or any achievements you want to highlight.',
      recommended: false,
      completed: false,
    },
    {
      label: USERINFO_LABELS.DEMOGRAPHIC_INFO,
      text: 'Add demographic information',
      desc: 'Add gender, ethnicity and other info for better discovery by recruiters.',
      recommended: false,
      completed: false,
    },
  ]);

  const toggleUserInfoCompleteStatus = useCallback(
    (label: USERINFO_LABELS, value: boolean) => {
      setUserInfoStatus((prevStatus) => {
        return prevStatus.map((item) => {
          if (item.label === label) {
            return {
              ...item,
              completed: value,
            };
          }
          return item;
        });
      });
    },
    []
  );

  const completedTopics = userInfoStatus.filter(
    (item) => item.completed
  ).length;
  const percentCompleted = Math.round((completedTopics / 6) * 100);

  const basicInfoComplete = userInfoStatus.some(
    (status) => status.label === USERINFO_LABELS.BASIC_INFO && status.completed
  );
  const educationComplete = userInfoStatus.some(
    (status) => status.label === USERINFO_LABELS.EDUCATION && status.completed
  );
  const experienceComplete = userInfoStatus.some(
    (status) => status.label === USERINFO_LABELS.EXPERIENCE && status.completed
  );
  const extraCurricularComplete = userInfoStatus.some(
    (status) =>
      status.label === USERINFO_LABELS.EXTRA_CURRICULAR_ACTIVITIES &&
      status.completed
  );
  const skillsComplete = userInfoStatus.some(
    (status) => status.label === USERINFO_LABELS.SKILLS && status.completed
  );
  const demographiclInfoComplete = userInfoStatus.some(
    (status) =>
      status.label === USERINFO_LABELS.DEMOGRAPHIC_INFO && status.completed
  );

  const isProfileCompExceptDemographics =
    !!candidate &&
    !!candidate.first_name &&
    !!candidate.last_name &&
    !!candidate.languages.data.length &&
    !!candidate.education_histories.data.length &&
    !!candidate.work_histories.data.length &&
    !!candidate.extra_curricular_activities.data.length &&
    !!candidate.accomplishments.data.length;

  return {
    candidateDemographicInfo,
    setCandidateDemographicInfo,
    generateCV,
    toggleUserInfoCompleteStatus,
    userInfoStatus,
    completedTopics,
    percentCompleted,
    basicInfoComplete,
    educationComplete,
    experienceComplete,
    extraCurricularComplete,
    skillsComplete,
    demographiclInfoComplete,
    updateCandidate,
    updateUniversity,
    isProfileCompExceptDemographics,
  };
};

export type CandidateProfileReturnType = ReturnType<typeof useCandidateProfile>;
