import {
  useCallback,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
} from 'react';
import useSWRInfinite from 'swr/infinite';
import queryString from 'query-string';

import useDebounce from 'hooks/useDebounce';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import { parsePaginatedResponse } from 'lib/utils/parser';
import { CompaniesResponse, CompanyAttributes } from 'lib/models/company';
import { SelectedFilterOptionLabels, SelectedFilterOptions } from './useFilter';

const PER_PAGE_COUNT = 50;

export interface ReturnType {
  companies: Array<CompanyAttributes>;
  resetFilter: () => void;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  size: number;
  setSize: (size: number) => void;
  hasMoreRecords: boolean;
  isLoading: boolean;
  selectedOptions: SelectedFilterOptions;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
  isLoadingMore: boolean | undefined;
}

export const useCompaniesSearch = (): ReturnType => {
  const [search, setSearch] = useState('');
  const trimmedSearch = search.trim();
  const debouncedSearch = useDebounce(trimmedSearch, 500);
  const [selectedOptions, setSelectedOptions] = useState<SelectedFilterOptions>(
    {}
  );
  const selectedLabelsRef = useRef<SelectedFilterOptionLabels>({});

  const resetFilter = useCallback(() => {
    setSearch('');
    selectedLabelsRef.current = {};
    setSelectedOptions({});
  }, []);

  const getCompaniesKey = (pageIndex: number) => {
    const params = {
      name: debouncedSearch,
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT,
      ...selectedOptions,
    };
    const qs = queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
    return `${API_ROUTES.COMPANY_LIST}?${qs}&with_details=true`;
  };

  const {
    data: companiesResponse,
    error: companiesError,
    size,
    setSize,
  } = useSWRInfinite<CompaniesResponse>(getCompaniesKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: false,
  });

  const isLoading = !companiesResponse && !companiesError;

  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      companiesResponse &&
      typeof companiesResponse[size - 1] === 'undefined');

  const companies = useMemo(() => {
    return parsePaginatedResponse<CompanyAttributes>(companiesResponse);
  }, [companiesResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstCompanyList = Array.isArray(companiesResponse)
      ? companiesResponse[0]
      : null;
    if (firstCompanyList) {
      return firstCompanyList.meta.total > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [companiesResponse, size]);

  return {
    companies,
    resetFilter,
    search,
    setSearch,
    size,
    setSize,
    hasMoreRecords,
    isLoading,
    selectedOptions,
    setSelectedOptions,
    selectedLabelsRef,
    isLoadingMore,
  };
};
