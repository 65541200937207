import React, { useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';

import { useRouter } from 'next/router';
import { IconButton } from 'components';
import Modal from 'components/Modal';
import { CompaniesSearchProvider } from 'lib/contexts/companies-search-context';
import { OpportunitiesSearchProvider } from 'lib/contexts/opportunities-search-context';
import Filter from '../Filter';

import styles from './FilterModal.module.scss';

const ModalInstance: React.FC<InstanceProps<void>> = ({
  isOpen,
  onResolve,
  onReject,
}) => {
  const router = useRouter();

  useEffect(() => {
    router.beforePopState(() => {
      onReject();
      return true;
    });

    return () => {
      router.beforePopState(() => {
        onReject();
        return true;
      });
    };
  }, [router]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
      className={styles.filterModal}
    >
      <IconButton
        size="small"
        iconName="close"
        className={styles.cancelIcon}
        onClick={() => onReject()}
      />
      <CompaniesSearchProvider>
        <OpportunitiesSearchProvider>
          <Filter onDone={onResolve} />
        </OpportunitiesSearchProvider>
      </CompaniesSearchProvider>
    </Modal>
  );
};

export const filterModalInstance = create(ModalInstance);
