import React, { useContext, createContext } from 'react';
import { noop } from 'lodash';
import {
  useOpportunitiesSearch as useOpportunitiesSearchInternal,
  ReturnType as useOpportunitiesSearchReturnType,
} from 'hooks/useOpportunitiesSearch';

const initialContext: useOpportunitiesSearchReturnType = {
  opportunities: [],
  resetFilter: noop,
  search: '',
  setSearch: noop,
  size: 0,
  setSize: () => noop,
  hasMoreRecords: false,
  isLoading: false,
  recentSearches: [],
  mutateRecentSearches: noop,
  addRecentSearch: noop,
  removeRecentSearch: async () => undefined,
  removeAllRecentSearches: async () => undefined,
  selectedOptions: {},
  setSelectedOptions: noop,
  selectedLabelsRef: { current: {} },
  isLoadingMore: false,
  setTouchpointType: noop,
  touchpointType: '',
};

const OpportunitiesSearchContext =
  createContext<useOpportunitiesSearchReturnType>(initialContext);

export const useOpportunitiesSearch = (): useOpportunitiesSearchReturnType =>
  useContext(OpportunitiesSearchContext);

export const OpportunitiesSearchProvider: React.FC = ({ children }) => {
  const value = useOpportunitiesSearchInternal();

  return (
    <OpportunitiesSearchContext.Provider value={value}>
      {children}
    </OpportunitiesSearchContext.Provider>
  );
};
