import React, { memo } from 'react';
import cn from 'classnames';

import { Link } from 'components';
import { Col, Row } from 'components/layout';
import ActionMenu, { ActionOption } from 'components/ActionMenu';
import styles from '../Card.module.scss';
import CardLogo from '../CardLogo';

export interface EntityCardProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** url for company/society icon */
  icon_url: string;
  /** name of the company/society */
  title: string;
  /** address of the company/society */
  address: string;
  is_verified: boolean;
  huzzle_verified: boolean;
  industry?: string;
  universityName?: string;
  menuOptions?: Array<ActionOption>;
  triggerIconClassName?: string;
  listClassName?: string;
  url: string;
  onClick?: () => void;
  /** Card class name */
  cardClassName?: string;
}

export const EntityCard: React.FC<EntityCardProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    icon_url,
    title,
    address,
    is_verified,
    industry,
    universityName,
    menuOptions,
    triggerIconClassName,
    listClassName,
    url,
    onClick,
    huzzle_verified = false,
    cardClassName,
  }: EntityCardProps) => {
    const contentClassNames = cn(
      {
        [styles.Card]: true,
      },
      {
        [styles.EntityCard]: true,
      },
      className
    );

    const initials = title ? title.split(' ')[0] : '';

    return (
      <Link
        href={url}
        onAnchorClick={onClick}
        underlineOnHover={false}
        className={styles.link}
      >
        <div
          className={contentClassNames}
          style={style}
          data-testid="EntityCard"
        >
          <CardLogo
            is_verified={is_verified || false}
            logo_url={icon_url}
            initials={initials}
            huzzle_verified={huzzle_verified || false}
            cohostsLogoDetails={null}
          />
          <Row
            className={cn(cardClassName)}
            align="center"
            justify="space-between"
            isFullWidthRow={true}
          >
            <Col span={24} sm={24}>
              <p className={styles.title}>{title}</p>
              <Row align="center" className={styles.info}>
                <p className={cn(styles.text, styles.textCompany)}>
                  {industry}
                </p>
                <p className={cn(styles.text)}>{universityName}</p>
                {address && (industry || universityName) && (
                  <div className={styles.dot}>•</div>
                )}
                {address ? (
                  <div className={cn(styles.text, styles.textCompany)}>
                    {address}
                  </div>
                ) : null}
              </Row>
            </Col>
            <Col>
              {menuOptions && (
                <ActionMenu
                  triggerIconClassName={triggerIconClassName}
                  listClassName={listClassName}
                  options={menuOptions}
                />
              )}
            </Col>
          </Row>
        </div>
      </Link>
    );
  }
);

EntityCard.displayName = 'EntityCard';
