import React, { useEffect, useMemo } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { useRouter } from 'next/router';
import { AxiosResponse } from 'axios';
import useSWR from 'swr';
import cn from 'classnames';

import { Spinner } from 'components';
import Modal from 'components/Modal';
import { FLAG_STATUS_CODES } from 'hooks/useCandidate';
import { useAuth } from 'lib/providers/AuthProvider';
import { API_ROUTES } from 'lib/api-routes';
import { parseResponse } from 'lib/utils/parser';
import { OpportunityResponse } from 'lib/models/opportunity';
import { useApp } from 'lib/contexts/save-in-app-context';
import { apiInstance } from 'lib/utils/axios';
import RegistrationForm from './RegistrationForm';
import SignedInUser from './SignedInUser';

import styles from './RegistrationDetailsModal.module.scss';

interface ModalInstanceProps {
  touchpointId: string;
  isJoinWaitlist?: boolean;
  className?: string;
}

const ModalInstance: React.FC<
  ModalInstanceProps & InstanceProps<FLAG_STATUS_CODES>
> = ({
  isOpen,
  onResolve,
  onReject,
  touchpointId,
  isJoinWaitlist = false,
  className,
}) => {
  const router = useRouter();
  const { isCandidate } = useAuth();
  const { handleRemoveRoute } = useApp();

  useEffect(() => {
    router.beforePopState(() => {
      onReject();
      handleRemoveRoute();
      return true;
    });

    return () => {
      router.beforePopState(() => {
        onReject();
        handleRemoveRoute();
        return true;
      });
    };
  }, [router]);

  const pathname = `${API_ROUTES.TOUCHPOINTS}/${touchpointId}`;

  const { data: touchpointResponse } = useSWR<
    AxiosResponse<OpportunityResponse>
  >(pathname, apiInstance.get);
  const touchpoint = parseResponse(touchpointResponse?.data);

  const formContent = useMemo(() => {
    return isCandidate ? (
      <SignedInUser
        touchpoint={touchpoint}
        onComplete={onResolve}
        isJoinWaitlist={isJoinWaitlist}
        onClose={() => onReject()}
      />
    ) : (
      <RegistrationForm
        touchpoint={touchpoint}
        onComplete={onResolve}
        isJoinWaitlist={isJoinWaitlist}
        onClose={() => onReject()}
      />
    );
  }, [touchpoint, isJoinWaitlist]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onReject}
      contentClassName={styles.container}
      className={cn(styles.modal, className)}
    >
      {touchpoint ? (
        formContent
      ) : (
        <div className={styles.loader}>
          <Spinner size="medium" />
        </div>
      )}
    </Modal>
  );
};

export const registrationDetailsModalInstance = create(ModalInstance);
