import React, { memo, useEffect } from 'react';
import cn from 'classnames';

import { useRouter } from 'next/router';
import { Button, ButtonV2 } from 'components/form';
import { FLAG_STATUS_CODES } from 'hooks/useCandidate';
import { PAGE_ROUTES } from 'lib/page-routes';
import styles from './ApplyConfirmation.module.scss';

export interface ApplyConfirmationProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onComplete: (value: FLAG_STATUS_CODES) => void;
  onCancel: () => void;
  isEvent: boolean;
}

export const ApplyConfirmation: React.FC<ApplyConfirmationProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onComplete,
    onCancel,
    isEvent,
  }: ApplyConfirmationProps) => {
    const contentClassNames = cn(
      {
        [styles.ApplyConfirmation]: true,
      },
      className
    );
    const router = useRouter();
    const isEventPage = router.pathname === PAGE_ROUTES.EVENT_PUBLIC || isEvent;

    useEffect(() => {
      if (
        ![
          PAGE_ROUTES.EVENT_PUBLIC,
          PAGE_ROUTES.JOB_PUBLIC,
          PAGE_ROUTES.INTERNSHIP_PUBLIC,
          PAGE_ROUTES.MATCHING_EXPERIENCE_PAGE,
          PAGE_ROUTES.CANDIDATE_MATCHES_SUCCESS,
          PAGE_ROUTES.SEARCH_RESULTS,
          PAGE_ROUTES.CANDIDATE_DASHBOARD,
          PAGE_ROUTES.MANAGER,
        ].includes(router.pathname)
      ) {
        onCancel();
      }
    }, [router.pathname]);

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="ApplyConfirmation"
      >
        <p className={styles.emoji}>👻</p>
        <p className={styles.title}>
          {isEventPage ? 'Did you register?' : 'Did you apply?'}
        </p>
        <ButtonV2
          onClick={() => onComplete(FLAG_STATUS_CODES.CONFIRM_APPLY_YES)}
          isFullWidth={true}
          className={styles.option}
        >
          <span>✅</span>
          {isEventPage ? 'Yes, registered' : 'Yes, applied'}
        </ButtonV2>
        <ButtonV2
          isFullWidth={true}
          onClick={onCancel}
          className={styles.option}
        >
          <span>😶</span>
          Not yet
        </ButtonV2>
        <Button
          variant="text"
          onClick={() =>
            onComplete(FLAG_STATUS_CODES.CONFIRM_APPLY_INCORRECT_LINK)
          }
          className={styles.optionText}
        >
          The link doesn’t work
        </Button>
      </div>
    );
  }
);

ApplyConfirmation.displayName = 'ApplyConfirmation';
