import cn from 'classnames';
import React, { memo, useCallback, useMemo, useRef, useState } from 'react';

import { Icon } from 'components';

import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Option } from 'lib/models/option';

import styles from './SingleSelect.module.scss';

export type DropdownOption = Option & {
  groupEnd?: boolean;
  isDelete?: boolean;
  isDefault?: boolean;
  industry_ids?: Array<string>;
};

export interface SingleSelectProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  /** title */
  title: string;
  /** values */
  value: string;
  /** options */
  options: Array<DropdownOption>;
  /** onChange handler */
  onChange: (value: string) => void;
  /** close dropdown on select */
  closeOnSelect?: boolean;
  /** disabled */
  disabled?: boolean;
}

export const SingleSelect: React.FC<SingleSelectProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    options,
    title,
    value,
    onChange,
    closeOnSelect = true,
    disabled,
  }: SingleSelectProps) => {
    const contentClassNames = cn(styles.SingleSelect, className);
    const singleSelectRef = useRef(null);
    const [open, setOpen] = useState(false);

    const onClose = useCallback(() => setOpen(false), []);

    useOnClickOutside({ ref: singleSelectRef, onOutsideClick: onClose });

    const onSelect = (value: string) => {
      onChange(value);
      if (closeOnSelect) onClose();
    };

    const selectedValueLabel = useMemo(() => {
      return options.find((option) => option.value === value)?.label;
    }, [options, value]);

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="SingleSelect"
        ref={singleSelectRef}
      >
        <button
          className={styles.trigger}
          type="button"
          aria-disabled={disabled}
          onClick={() => setOpen(!open)}
        >
          {selectedValueLabel ? (
            <span className={styles.selectedValue}>{selectedValueLabel}</span>
          ) : (
            <span className={styles.buttonLabel}>{title}</span>
          )}
          {!disabled && (
            <Icon
              iconName="chevron-down"
              className={cn(styles.chevron, {
                [styles.chevronReverse]: open,
              })}
            />
          )}
        </button>
        {open ? (
          <div className={styles.SingleSelectDropdown}>
            <ul className={styles.options}>
              {options.map((option) => {
                const { id, label, groupEnd } = option;
                const checked = value === option.value;
                return (
                  <li
                    key={id}
                    className={cn(styles.option, {
                      [styles.groupEnd]: groupEnd,
                    })}
                    onClick={() => onSelect(option.value)}
                  >
                    <span>{label}</span>
                    {checked && (
                      <Icon
                        iconName="valid-check-mark"
                        className={styles.checkMark}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
);

SingleSelect.displayName = 'SingleSelect';
