import React, { memo, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Icon, Spinner } from 'components';
import companyFilter from 'components/candidate/Filter/companyFilter.json';
import societyFilter from 'components/candidate/Filter/societyFilter.json';
import opportunityFilter from 'components/candidate/Filter/opportunityFilter.json';
import { ButtonV2, Input } from 'components/form';
import SelectionCheckbox from 'components/form/SelectionCheckbox';
import SelectionTickMark from 'components/form/SelectionTickMark';
import { fieldType, useFilter } from 'hooks/useFilter';
import { useNotification } from 'hooks/useNotification';
import { useResponsive } from 'hooks/useResponsive';
import { ManagerFilterType } from 'pages/candidate/manager/Manager.component';
import { useSocietiesSearch } from 'lib/contexts/societies-search-context';
import { useOpportunitiesSearch } from 'lib/contexts/opportunities-search-context';
import { PAGE_ROUTES } from 'lib/page-routes';
import { Option } from 'lib/models/option';
import { useCompaniesSearch } from 'lib/contexts/companies-search-context';
import { useCommonList } from 'lib/contexts/common-list-context';
import { useManagerFilters } from 'lib/contexts/manager-filters-context';
import { LocationFilter } from './LocationFilter/LocationFilter.component';

import styles from './Filter.module.scss';

export interface FilterProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  onDone: () => void;
}

export interface FilterType {
  name: string;
  field: string;
  type: string;
  isSearchable: boolean;
  param: string;
}

type JSONValue = string | number | boolean | JSONObject | JSONArray;

interface JSONObject {
  [x: string]: JSONValue;
}

type JSONArray = Array<JSONValue>;

export const Filter: React.FC<FilterProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    onDone,
  }: FilterProps) => {
    const router = useRouter();
    const { touchpointableType: type, status, entity } = router?.query;
    const notificationInstance = useNotification();
    const screens = useResponsive();

    const contentClassNames = cn(
      {
        [styles.Filter]: true,
      },
      className
    );

    const {
      getOptions,
      selectedOptions,
      setSelectedOptions,
      total: totalResults,
      selectedLabelsRef,
    } = useFilter();
    const { cityCountryList } = useCommonList();

    const [options, setOptions] = useState<Array<Option>>([]);
    const [totalOptions, setTotalOptions] = useState<Array<Option>>([]);
    const [filterSelected, setFilterSelected] = useState<FilterType | null>(
      null
    );
    const [isOptionsLoading, setOptionsLoading] = useState(false);

    const [search, setSearch] = useState('');
    const trimmedSearch = search.trim();

    const {
      setSelectedOptions: setSelectedCompanyOptions,
      selectedLabelsRef: selectedCompanyLabelsRef,
    } = useCompaniesSearch();
    const {
      setSelectedOptions: setSelectedSocietyOptions,
      selectedLabelsRef: selectedSocietyLabelsRef,
    } = useSocietiesSearch();
    const {
      setSelectedOptions: setSelectedOpportunitiesOptions,
      selectedLabelsRef: selectedOpportunitiesLabelsRef,
      touchpointType,
    } = useOpportunitiesSearch();
    const {
      setSelectedOptions: setSelectedManagerOptions,
      selectedLabelsRef: selectedManagerLabelsRef,
    } = useManagerFilters();

    const touchpointableType = type || touchpointType;

    const filterNames = useMemo(() => {
      if (router.pathname === PAGE_ROUTES.MANAGER) {
        if (entity === ManagerFilterType.Companies) {
          return companyFilter;
        } else if (entity === ManagerFilterType.Societies) {
          return societyFilter;
        } else if (typeof entity === 'string') {
          return opportunityFilter?.[entity as keyof typeof opportunityFilter];
        }
      } else if (router.pathname === PAGE_ROUTES.COMPANIES_SEARCH)
        return companyFilter;
      else if (router.pathname === PAGE_ROUTES.SOCIETIES_SEARCH)
        return societyFilter;
      else
        return opportunityFilter?.[
          touchpointableType as keyof typeof opportunityFilter
        ];
    }, [router, status, entity]);

    const onFilterTypeClickHandler = async (
      name: string,
      field: string,
      type: string,
      isSearchable: boolean,
      param: string
    ) => {
      setFilterSelected({ name, field, type, isSearchable, param });
      setOptionsLoading(true);
      try {
        const list = await getOptions(field);
        if (list && list.length > 0) {
          setOptions(list);
          setTotalOptions(list);
          setOptionsLoading(false);
        }
      } catch (error) {
        notificationInstance.handleExceptionError(error);
      }
    };

    const getFilterNames = () => {
      return filterNames?.map((item) => {
        const { id, name, field, type, isSearchable, param } = item;
        if (field === 'remotePreference') return;

        const isChecked = !!selectedOptions[param];
        const refLabels = selectedLabelsRef.current[param];
        const labels = Array.isArray(refLabels)
          ? refLabels.join(', ')
          : refLabels;

        return (
          <div key={id}>
            <div
              className={styles.listRow}
              onClick={() =>
                type === 'toggle'
                  ? onToggleTickmark(param, !isChecked)
                  : onFilterTypeClickHandler(
                      name,
                      field,
                      type,
                      isSearchable,
                      param
                    )
              }
            >
              {type === 'toggle' ? (
                <SelectionTickMark
                  checked={isChecked}
                  onChange={() => onToggleTickmark(param, !isChecked)}
                  className={styles.verified}
                >
                  <p className={styles.name}>{name}</p>
                </SelectionTickMark>
              ) : (
                <>
                  <p className={styles.name}>{name}</p>
                  <div className={styles.listValues}>
                    <p className={styles.values}>{labels}</p>
                    <Icon
                      iconName="chevron-right"
                      size="large"
                      className={styles.chevronIcon}
                    />
                  </div>
                </>
              )}
            </div>
            <hr className={styles.separator}></hr>
          </div>
        );
      });
    };

    const onChevronClick = () => {
      setFilterSelected(null);
      setOptions([]);
      setTotalOptions([]);
      setSearch('');
      if (filterSelected && filterSelected.field === 'remotePreference') {
        setSelectedOptions((options) => ({
          ...options,
          is_online_selected: false,
        }));
        onFilterTypeClickHandler(
          'Location',
          'location',
          'multi-select-tick',
          true,
          'city_ids'
        );
      }
    };

    const onClearSelection = () => {
      if (!filterSelected) return;

      const { param, type } = filterSelected;
      const isSingleSelect =
        type === fieldType.SINGLE_SELECT_TICK ||
        type === fieldType.SINGLE_SELECT_CHIP;

      setSelectedOptions((options) => {
        selectedLabelsRef.current[param] = '';
        return {
          ...options,
          [param]: isSingleSelect ? '' : [],
          is_online: undefined,
        };
      });
    };

    const onClearAllSelection = () => {
      selectedLabelsRef.current = {};
      const { q, touchpoint_type, touchpointable_types } = selectedOptions;

      if (router.pathname === PAGE_ROUTES.MANAGER) {
        setSelectedOptions({ q, touchpoint_type, touchpointable_types });
      } else {
        setSelectedOptions({ q });
      }
    };

    const onOptionCheckboxClick = (value: string) => {
      if (!filterSelected) return;
      const { param, type } = filterSelected;
      const isSingleSelect =
        type === fieldType.SINGLE_SELECT_TICK ||
        type === fieldType.SINGLE_SELECT_CHIP;

      setSelectedOptions((selectedOptions) => {
        const values = selectedOptions[param];
        let newValues: Array<string>;

        if (isSingleSelect) {
          const option = options.find((option) => option.value === value);
          selectedLabelsRef.current[param] = option?.label || '';

          return {
            ...selectedOptions,
            [param]: value,
            touchpointable_type: touchpointableType,
          };
        }

        if (Array.isArray(values)) {
          newValues = values.filter((val) => val !== value);
          if (newValues.length === values.length) {
            newValues = [...values, value];
          }
        } else {
          newValues = [value];
        }

        const labels = newValues.map((value) => {
          const option = options.find((option) => option.value === value);
          return option?.label || '';
        });

        selectedLabelsRef.current[param] = labels;

        return {
          ...selectedOptions,
          [param]: newValues,
          touchpointable_type: touchpointableType,
        };
      });
    };

    useEffect(() => {
      if (
        selectedOptions?.city_ids &&
        Array.isArray(selectedOptions?.city_ids)
      ) {
        const labels = selectedOptions?.city_ids.map((value) => {
          const option = cityCountryList.find((option) => option.id === value);
          return option?.label || '';
        });
        selectedLabelsRef.current['city_ids'] = labels;
      }
    }, [selectedOptions?.city_ids]);

    useEffect(() => {
      if (selectedOptions?.is_online_selected === true) {
        setSelectedOptions((options) => ({
          ...options,
          touchpointable_type: touchpointableType,
        }));
        onFilterTypeClickHandler(
          'Remote from',
          'remotePreference',
          'multi-select-tick',
          false,
          'country_ids'
        );
      }
    }, [selectedOptions?.is_online_selected]);

    const onToggleTickmark = (param: string, value: boolean) => {
      if (value) {
        setSelectedOptions((options) => {
          return { ...options, [param]: value };
        });
      } else {
        setSelectedOptions((options) => {
          const { [param]: _, ...rest } = options;
          return { ...rest };
        });
      }
    };

    const getSearchedItem = (name: string) => {
      return name.toLowerCase().indexOf(trimmedSearch.toLowerCase()) > -1;
    };

    const inputBox = (
      <Input
        className={styles.input}
        placeholder="Search"
        startIcon="search"
        startIconSize="medium"
        endIcon={search ? 'cancel' : null}
        endIconSize="xsmall"
        value={search}
        onChange={(event) => setSearch(event.target.value)}
        onEndIconClick={() => (search ? setSearch('') : null)}
      />
    );

    const onShowResults = () => {
      if (router.pathname === PAGE_ROUTES.MANAGER) {
        selectedManagerLabelsRef.current = { ...selectedLabelsRef.current };
        setSelectedManagerOptions(selectedOptions);
      } else if (router.pathname === PAGE_ROUTES.COMPANIES_SEARCH) {
        selectedCompanyLabelsRef.current = { ...selectedLabelsRef.current };
        setSelectedCompanyOptions(selectedOptions);
      } else if (router.pathname === PAGE_ROUTES.SOCIETIES_SEARCH) {
        selectedSocietyLabelsRef.current = { ...selectedLabelsRef.current };
        setSelectedSocietyOptions(selectedOptions);
      } else {
        selectedOpportunitiesLabelsRef.current = {
          ...selectedLabelsRef.current,
        };
        setSelectedOpportunitiesOptions(selectedOptions);
      }
      onDone();
    };

    useEffect(() => {
      if (search.length > 0) {
        const filteredOptions = totalOptions.filter((option) => {
          return getSearchedItem(option.label);
        });
        setOptions(filteredOptions);
      } else {
        setOptions(totalOptions);
      }
    }, [search]);

    const content =
      filterSelected && filterSelected.type !== 'toggle' ? (
        <>
          <div className={styles.back} onClick={onChevronClick}>
            <Icon
              iconName="chevron-left"
              size={screens.sm ? 'xlarge' : 'large'}
            />
            {!screens.sm && (
              <p className={styles.backTitle}>
                {filterSelected.field === 'remotePreference'
                  ? 'Location'
                  : 'Filters'}
              </p>
            )}
          </div>
          <p className={styles.title}>{filterSelected.name}</p>
          {filterSelected.field === 'location' ||
          filterSelected.field === 'remotePreference' ? (
            <LocationFilter
              selectedOptions={selectedOptions}
              setSelectedOptions={setSelectedOptions}
            />
          ) : (
            <>
              {filterSelected.isSearchable === true &&
              filterSelected.type === fieldType.MULTI_SELECT_TICK
                ? inputBox
                : null}
              <div
                className={cn(styles.list, {
                  [styles.listMultiselectchip]:
                    filterSelected.type === fieldType.MULTI_SELECT_CHIP,
                })}
              >
                {filterSelected.isSearchable === true &&
                filterSelected.type === fieldType.MULTI_SELECT_CHIP
                  ? inputBox
                  : null}
                {isOptionsLoading ? (
                  <div className={styles.spinner}>
                    <Spinner />
                  </div>
                ) : null}
                {filterSelected.type === fieldType.SINGLE_SELECT_CHIP
                  ? options.map((option) => {
                      const value = selectedOptions[filterSelected.param] || '';
                      return typeof value === 'string' ? (
                        <SelectionCheckbox
                          key={option.value}
                          checked={option.value === value}
                          onChange={() => onOptionCheckboxClick(option.value)}
                          className={styles.selectionCheckbox}
                        >
                          {option.label}
                        </SelectionCheckbox>
                      ) : null;
                    })
                  : null}
                {filterSelected.type === fieldType.MULTI_SELECT_CHIP
                  ? options.map((option) => {
                      const values =
                        selectedOptions[filterSelected.param] || [];
                      return Array.isArray(values) ? (
                        <SelectionCheckbox
                          key={option.value}
                          checked={values.some(
                            (value) => value === option.value
                          )}
                          onChange={() => onOptionCheckboxClick(option.value)}
                          className={styles.selectionCheckbox}
                        >
                          {option.label}
                        </SelectionCheckbox>
                      ) : null;
                    })
                  : null}
                {filterSelected.type === fieldType.MULTI_SELECT_TICK
                  ? options.map((option) => {
                      const values =
                        selectedOptions[filterSelected.param] || [];
                      return Array.isArray(values) ? (
                        // Modifying from option.value to option.id as both id and value have same values
                        <div key={option.id}>
                          <SelectionTickMark
                            checked={values.some(
                              (value) => value === option.id
                            )}
                            onChange={() => onOptionCheckboxClick(option.id)}
                            className={styles.selectionTickmark}
                            isTickSquare={true}
                          >
                            {option.label}
                          </SelectionTickMark>
                          <hr
                            className={styles.selectionTickmarkSeparator}
                          ></hr>
                        </div>
                      ) : null;
                    })
                  : null}
                {filterSelected.type === fieldType.SINGLE_SELECT_TICK
                  ? options.map((option) => {
                      const value = selectedOptions[filterSelected.param] || '';

                      return typeof value === 'string' ? (
                        <div key={option.value}>
                          <SelectionTickMark
                            checked={option.value === value}
                            onChange={() => onOptionCheckboxClick(option.value)}
                            className={styles.selectionTickmark}
                          >
                            {option.label}
                          </SelectionTickMark>
                          <hr
                            className={styles.selectionTickmarkSeparator}
                          ></hr>
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {screens.sm && selectedOptions ? (
            <button className={styles.titleClear} onClick={onClearAllSelection}>
              Clear all
            </button>
          ) : null}
          <p className={styles.title}>Filter</p>
          <div className={styles.list}>{getFilterNames()}</div>
        </>
      );

    return (
      <div className={contentClassNames} style={style} data-testid="Filter">
        <div className={styles.content}>{content}</div>
        {screens.sm ? (
          <div className={styles.footer}>
            {totalResults !== undefined ? (
              <ButtonV2
                isFullWidth={true}
                onClick={onShowResults}
                className={styles.footerButtonsMobile}
              >
                Show {totalResults} results
              </ButtonV2>
            ) : (
              <p className={cn(styles.footerButtonsMobile, styles.filtering)}>
                Filtering...
              </p>
            )}
          </div>
        ) : (
          <div className={styles.footer}>
            <div className={styles.footerSeparator}></div>
            <div className={styles.footerButtons}>
              {filterSelected ? (
                <button onClick={onClearSelection} className={styles.clear}>
                  Clear selected
                </button>
              ) : (
                <button onClick={onClearAllSelection} className={styles.clear}>
                  Clear all
                </button>
              )}
              {totalResults !== undefined ? (
                <ButtonV2
                  width={180}
                  onClick={onShowResults}
                  className={styles.result}
                >
                  Show {totalResults} results
                </ButtonV2>
              ) : (
                <p className={cn(styles.result, styles.filtering)}>
                  Filtering...
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

Filter.displayName = 'Filter';
