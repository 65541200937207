import { ReactElement } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useNotification } from 'hooks/useNotification';
import { useResponsive } from 'hooks/useResponsive';
import { ManagerFilterType } from 'pages/candidate/manager/Manager.component';
import { PAGE_ROUTES } from 'lib/page-routes';
import { TouchpointType } from 'lib/models/touchpoint';

import styles from './TouchpointNotifications.module.scss';

interface NotificationsProps {
  notificationType: NotificationType;
  type?: string;
  eventCount?: number;
  nonEventCount?: number;
  followCount?: number;
  name?: string;
  approvalReq?: boolean;
}

export enum NotificationType {
  INTERNAL_EVENT_REGISTERED = 'internalEventRegistered',
  INTERNAL_EVENT_WAITLIST = 'internalEventWaitlist',
  INTERNAL_JOB_APPLY = 'internalJobApply',
  EXTERNAL_APPLY = 'externalApply',
  SAVED_TOUCHPOINT = 'savedTouchpoint',
  SOCIETY_JOIN = 'societyJoin',
  COMPANY_FOLLOW = 'companyFollow',
}

export const TouchpointNotifications = () => {
  const notificationInstance = useNotification();
  const screens = useResponsive();
  const router = useRouter();
  const [t] = useTranslation('common');

  let titleData: string;
  let messageData: string | ReactElement;

  const touchpointNotificationInstance = ({
    notificationType,
    type,
    eventCount,
    nonEventCount,
    followCount,
    name,
    approvalReq,
  }: NotificationsProps) => {
    switch (notificationType) {
      case NotificationType.INTERNAL_EVENT_REGISTERED: {
        titleData = `${t('candidate-notifications.registered-title')}`;
        messageData = `${t('candidate-notifications.registered-message')}`;
        break;
      }
      case NotificationType.INTERNAL_EVENT_WAITLIST: {
        titleData = `${t('candidate-notifications.waitlist-title')}`;
        messageData = `${t('candidate-notifications.waitlist-message')}`;
        break;
      }
      case NotificationType.INTERNAL_JOB_APPLY: {
        titleData = `${t('candidate-notifications.applied')}`;
        messageData = (
          <Trans t={t} i18nKey="candidate-notifications.check-email">
            Check your email and application manager for updates
            <span
              className={styles['notification']}
              onClick={() =>
                router.push(
                  `${PAGE_ROUTES.MANAGER}?status=${ManagerFilterType.Applied}&entity=${type}`
                )
              }
            ></span>
          </Trans>
        );
        break;
      }
      case NotificationType.EXTERNAL_APPLY: {
        if (type === TouchpointType.Event) {
          if (eventCount && eventCount > 2) {
            titleData = `${t('candidate-notifications.registered-title')}`;
          } else {
            const linkURL = `${PAGE_ROUTES.MANAGER}?status=${ManagerFilterType.Registered}`;
            messageData = (
              <div className={styles['message-only']}>
                <Trans
                  t={t}
                  i18nKey="candidate-notifications.external-register-new"
                >
                  You have registered to this event! Find and track your events
                  in the Manage section.
                  <span
                    className={styles['notification']}
                    onClick={() => router.push(linkURL)}
                  ></span>
                </Trans>
              </div>
            );
          }
        } else {
          if (nonEventCount && nonEventCount > 2) {
            titleData = `${t('candidate-notifications.external-apply')}`;
          } else {
            const linkURL = `${PAGE_ROUTES.MANAGER}?status=${ManagerFilterType.Applied}&entity=${type}`;
            messageData = (
              <div className={styles['message-only']}>
                <Trans
                  t={t}
                  i18nKey={'candidate-notifications.external-apply-new'}
                >
                  You have applied to this job! Find and track your jobs in the
                  Manage section.
                  <span
                    className={styles['notification']}
                    onClick={() => router.push(linkURL)}
                  ></span>
                </Trans>
              </div>
            );
          }
        }
        break;
      }
      case NotificationType.SAVED_TOUCHPOINT: {
        if (type === TouchpointType.Event) {
          if (eventCount && eventCount > 2) {
            titleData = `${t('candidate-notifications.saved-event')}`;
          } else {
            messageData = (
              <div className={styles['message-only']}>
                <Trans t={t} i18nKey="candidate-notifications.saved-event-new">
                  You have saved this event! Find and track your events in the
                  Manage section.
                  <span
                    className={styles['notification']}
                    onClick={() => router.push(PAGE_ROUTES.MANAGER)}
                  ></span>
                </Trans>
              </div>
            );
          }
        } else {
          if (nonEventCount && nonEventCount < 2) {
            titleData = `${t('candidate-notifications.saved-job')}`;
          } else {
            messageData = (
              <div className={styles['message-only']}>
                <Trans t={t} i18nKey={'candidate-notifications.saved-job-new'}>
                  You have saved this job! Find and track your jobs in the
                  Manage section.
                  <span
                    className={styles['notification']}
                    onClick={() => router.push(PAGE_ROUTES.MANAGER)}
                  ></span>
                </Trans>
              </div>
            );
          }
        }
        break;
      }
      case NotificationType.SOCIETY_JOIN: {
        titleData = approvalReq
          ? `${t('candidate-notifications.request-sent')}`
          : `${t('candidate-notifications.joined')} ${name}`;
        if (followCount && followCount > 2) {
          messageData = '';
        } else {
          messageData = (
            <Trans
              t={t}
              i18nKey="candidate-notifications.community-message-desc"
            >
              You will get updates directly to your inbox. Find and track your
              communities in the Manage section.
              <span
                className={styles['notification']}
                onClick={() =>
                  router.push(
                    `${PAGE_ROUTES.MANAGER}?status=Following&entity=Communities`
                  )
                }
              ></span>
            </Trans>
          );
        }
        break;
      }
      case NotificationType.COMPANY_FOLLOW: {
        titleData = `${t('candidate-notifications.followed')} ${name}`;
        if (followCount && followCount > 2) {
          messageData = '';
        } else {
          messageData = (
            <Trans t={t} i18nKey="candidate-notifications.company-message-desc">
              You will get updates directly to your inbox. Find and track your
              companies in the Manage section.
              <span
                className={styles['notification']}
                onClick={() =>
                  router.push(
                    `${PAGE_ROUTES.MANAGER}?status=Following&entity=Companies`
                  )
                }
              ></span>
            </Trans>
          );
        }
      }
    }

    notificationInstance.information({
      timeout: 5000,
      title: titleData,
      message: messageData || '',
      showClose: screens.sm ? false : true,
      variant: 'small',
      borderRounded: false,
    });
  };

  return touchpointNotificationInstance;
};
