import React, {
  ReactElement,
  useState,
  useRef,
  MutableRefObject,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { IconButton } from 'components';
import { useResponsive } from 'hooks/useResponsive';
import {
  SelectedFilterOptionLabels,
  SelectedFilterOptions,
} from 'hooks/useFilter';
import { ManagerFilterType } from '../Manager.component';
import TouchpointsFilters from './TouchpointsFilters';
import OpportunityFilters, { OPPORTUNITY_TYPE } from './OpportunityFilters';

import styles from './ManagerFilters.module.scss';
import Filters from './Filters';

export type MultiRefs = {
  listRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
};

export enum FiltersMode {
  Dropdown = 'dropdown',
  List = 'list',
}

const ManagerFilters = ({
  className,
  filters,
  onChangeFilter,
  onClickFilter,
  resetFilter,
  setSelectedOptions,
  selectedLabelsRef,
}: {
  className?: string;
  filters?: SelectedFilterOptions | undefined;
  onClickFilter: (key: string, value: string) => void;
  onChangeFilter: (key: string, value: Array<string>, labels: string[]) => void;
  resetFilter: () => void;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
}): ReactElement => {
  const screens = useResponsive();
  const router = useRouter();
  const { status, entity } = router?.query;

  const [showPrevArrow, setShowPrevArrow] = useState(false);
  const [showNextArrow, setShowNextArrow] = useState(true);

  const filtersListRef = useRef<HTMLDivElement | null>(null);
  const filterTriggerRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const listRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (filtersListRef.current) {
        const { current } = filtersListRef;
        setShowPrevArrow(current.scrollLeft > 0);
        setShowNextArrow(
          current.scrollLeft + current.clientWidth < current.scrollWidth
        );
      }
    };
    // Call it once to set initial state
    handleScroll();
    // Add event listener
    filtersListRef.current?.addEventListener('scroll', handleScroll);
    return () => {
      // Cleanup listener on unmount
      filtersListRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [router, filters, status, entity]);

  useEffect(() => {
    if (showPrevArrow && filtersListRef.current) {
      const { current } = filtersListRef;

      // Move the scroll all the way to the right
      current.scrollLeft = current.scrollWidth - current.clientWidth;
    }
  }, [filters, showPrevArrow]);

  const handlePrevClick = () => {
    if (filtersListRef.current) {
      const { current } = filtersListRef;
      const newScrollPosition = Math.max(
        0,
        current.scrollLeft - current.clientWidth
      );
      current.scrollLeft = newScrollPosition;
    }
  };

  const handleNextClick = () => {
    if (filtersListRef.current) {
      const { current } = filtersListRef;
      const newScrollPosition = current.scrollLeft + current.clientWidth;
      current.scrollLeft = newScrollPosition;
    }
  };

  const assignRefs = (index: number, el: MultiRefs | null) => {
    if (!el) return;

    filterTriggerRefs.current[index] = el.buttonRef?.current;
    listRef.current = el.listRef?.current;

    if (!listRef.current) return;

    const currentTriggerRef = filterTriggerRefs.current[index];
    const rect = currentTriggerRef?.getBoundingClientRect() || {
      height: 0,
      left: 0,
      width: 0,
      right: 0,
    };

    if (screens.sm_desktop_down) {
      listRef.current.style.top = '60px';
      listRef.current.style.left = '15px';
      listRef.current.style.right = '0px';
      return;
    } else {
      const isFollowAbleType =
        selectedTouchpoint === ManagerFilterType.Societies ||
        selectedTouchpoint === ManagerFilterType.Companies;
      const offSetLeft = currentTriggerRef?.offsetLeft || 0;
      const startIndex =
        isFollowAbleType || selectedOpportunity === OPPORTUNITY_TYPE.INTERNSHIP
          ? 0
          : 1;

      listRef.current.style.top = `${rect.height + 20}px`;

      if (index === startIndex) {
        const scrollLeft = filtersListRef.current?.scrollLeft || 0;
        const adjustedOffsetLeft = offSetLeft - scrollLeft;
        listRef.current.style.left = `${adjustedOffsetLeft}px`;
      } else {
        const listElementRect = listRef.current.getBoundingClientRect();
        const remainingSpace = rect.left - listElementRect.left;
        if (remainingSpace >= 100) {
          listRef.current.style.left = `${remainingSpace}px`;
        } else {
          return;
        }
      }
    }
  };

  const selectedTouchpoint = Array.isArray(filters?.['touchpoint_type'])
    ? filters?.['touchpoint_type'][0]
    : filters?.['touchpoint_type'];

  const selectedOpportunity = Array.isArray(filters?.['touchpointable_types'])
    ? filters?.['touchpointable_types'][0]
    : filters?.['touchpointable_types'];

  return (
    <div className={styles.ManagerFiltersWrapper}>
      <div
        className={cn(styles.ManagerFilters, className, {
          [styles.ManagerFiltersLeft]: showPrevArrow,
          [styles.ManagerFiltersRight]: !showPrevArrow && showNextArrow,
        })}
      >
        {showPrevArrow ? (
          <div
            className={cn(
              styles.ManagerFiltersSlideShowNavigation,
              styles.ManagerFiltersSlideShowNavigationLeft
            )}
          >
            <IconButton
              iconName="arrow-button-left"
              size={'xsmallplus'}
              onClick={handlePrevClick}
              className={styles.ManagerFiltersSlideShowNavigationButton}
              type="button"
            />
          </div>
        ) : null}
        <div className={cn(styles.ManagerFiltersList)} ref={filtersListRef}>
          <TouchpointsFilters
            mode={selectedTouchpoint ? FiltersMode.Dropdown : FiltersMode.List}
            selectedTouchpoint={selectedTouchpoint as string}
            onClickFilter={onClickFilter}
            resetFilter={resetFilter}
          />
          {!selectedTouchpoint ||
          selectedTouchpoint === ManagerFilterType.Societies ||
          selectedTouchpoint === ManagerFilterType.Companies ? (
            <></>
          ) : (
            <>
              {selectedOpportunity ? null : (
                <div className={styles.seperator}></div>
              )}
              <OpportunityFilters
                mode={
                  selectedOpportunity ? FiltersMode.Dropdown : FiltersMode.List
                }
                selectedTouchpoint={selectedTouchpoint as string}
                selectedOpportunity={selectedOpportunity as string}
                onClickFilter={onClickFilter}
              />
            </>
          )}
          {selectedTouchpoint ? (
            <Filters
              filters={filters}
              onChangeFilter={onChangeFilter}
              assignRefs={assignRefs}
              setSelectedOptions={setSelectedOptions}
              selectedLabelsRef={selectedLabelsRef}
            />
          ) : (
            <></>
          )}
        </div>

        {!showPrevArrow && showNextArrow ? (
          <div
            className={cn(
              styles.ManagerFiltersSlideShowNavigation,
              styles.ManagerFiltersSlideShowNavigationRight
            )}
          >
            <IconButton
              size={'xsmallplus'}
              iconName="arrow-button-right"
              onClick={handleNextClick}
              className={styles.ManagerFiltersSlideShowNavigationButton}
              type="button"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ManagerFilters;
