import cn from 'classnames';
import { useRouter } from 'next/router';
import React, { memo, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'next-i18next';
import { IconButton } from 'components';
import { Button, ButtonV2 } from 'components/form';
import { Row } from 'components/layout';
import { useCommon } from 'hooks/useCommon';
import { FLAG_STATUS_CODES } from 'hooks/useCandidate';
import { API_ROUTES } from 'lib/api-routes';
import { OpportunityAttributes } from 'lib/models/opportunity';
import { post } from 'lib/utils/http';

import { QuestionState } from 'lib/models/answers';
import { TouchpointType } from 'lib/models/touchpoint';
import { PAGE_ROUTES } from 'lib/page-routes';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';
import { useApp } from 'lib/contexts/save-in-app-context';
import { useAuth } from 'lib/providers/AuthProvider';
import RegistrationForm from '../RegistrationForm';
import { verifyOTPModalInstance } from '../VerifyOTPModal';

import styles from './SignedInUser.module.scss';

export interface SignedInUserProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  touchpoint: OpportunityAttributes;
  onComplete: (value: FLAG_STATUS_CODES) => void;
  isJoinWaitlist: boolean;
  onClose: () => void;
}

export const SignedInUser: React.FC<SignedInUserProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    touchpoint,
    onComplete,
    isJoinWaitlist,
    onClose,
  }: SignedInUserProps) => {
    const contentClassNames = cn(
      {
        [styles.SignedInUser]: true,
      },
      className
    );
    const router = useRouter();
    const { applyOpportunity } = useCommon();
    const { setEmail } = useApp();
    const [t] = useTranslation('common');

    const [isLoading, setIsLoading] = useState(false);
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const { candidate: userInfo, user: userData, isAuthLoading } = useAuth();

    const {
      first_name,
      last_name,
      university: universityResponse,
      degree: degreeResponse,
      subject: subjectResponse,
      linkedin_url,
      study_year,
      resume_url,
      resume,
      graduation_year,
      education_histories,
    } = userInfo || {};

    const { email, is_confirmed } = userData || {};

    const {
      id: touchpoint_id,
      university_required,
      study_subject_required,
      degree_required,
      study_year_required,
      cv_required,
      linkedin_profile_required,
      university_required_state,
      study_year_required_state,
      degree_required_state,
      study_subject_required_state,
      cv_required_state,
      linkedin_profile_required_state,
      applicable_questions: questionsResponse,
    } = touchpoint || {};
    const isEvent = touchpoint?.touchpointable_type === TouchpointType.Event;

    useEffect(() => {
      async function init() {
        if (!is_confirmed && !isAuthLoading && !isEvent && email) {
          const data = {
            user: { email },
          };
          await post(API_ROUTES.CANDIDATE_CREATE, data);
          setEmail(email);
          await verifyOTPModalInstance({ isJoinWaitlist });
        }
      }

      try {
        init();
      } catch (error) {
        console.error('Error in initializing SignedInUser', error);
      }
    }, [isAuthLoading, is_confirmed]);

    const educationHistories = useMemo(() => {
      const university = parseResponse(universityResponse);
      const degree = parseResponse(degreeResponse);
      const subject = parseResponse(subjectResponse);
      const educationHistoriesData = parseArrayResponse(education_histories);

      if (!educationHistoriesData || educationHistoriesData.length === 0) {
        return {};
      }

      const {
        university_name,
        university: universityContainer,
        degree: degreeContainer,
        subject: subjectContainer,
      } = educationHistoriesData[0];
      const universityData = parseResponse(universityContainer);
      const degreeData = parseResponse(degreeContainer);
      const subjectData = parseResponse(subjectContainer);
      return {
        university_id: universityData?.id || university?.id,
        university_name:
          universityData?.name || university_name || university?.name,
        degree_id: degreeData?.id || degree?.id,
        degree_name: degreeData?.name || degree?.name,
        subject_id: subjectData?.id || subject?.id,
        subject_name: subjectData?.name || subject?.name,
        graduationYear: graduation_year,
      };
    }, [education_histories, userInfo]);

    const questions = useMemo(() => {
      return parseArrayResponse(questionsResponse);
    }, [questionsResponse]);

    const isCustomQuestionPresent =
      (
        questions &&
        questions.filter(({ state }) => state !== QuestionState.OFF)
      ).length > 0;

    const isInfoIncomplete =
      !first_name ||
      !last_name ||
      ((university_required ||
        university_required_state !== QuestionState.OFF) &&
        !educationHistories.university_id) ||
      ((study_subject_required ||
        study_subject_required_state !== QuestionState.OFF) &&
        !educationHistories.subject_id) ||
      ((degree_required || degree_required_state !== QuestionState.OFF) &&
        !educationHistories.degree_id) ||
      ((study_year_required ||
        study_year_required_state !== QuestionState.OFF) &&
        !educationHistories.graduationYear) ||
      ((linkedin_profile_required ||
        linkedin_profile_required_state !== QuestionState.OFF) &&
        !linkedin_url) ||
      ((cv_required || cv_required_state !== QuestionState.OFF) &&
        !resume_url) ||
      isCustomQuestionPresent;

    const onRegister = async () => {
      setIsLoading(true);
      const response = await applyOpportunity(touchpoint_id);
      if (response) {
        setIsLoading(false);
        onComplete(FLAG_STATUS_CODES.EXISTING_USER_REGISTER_SUCCESS);
      }
    };

    return (
      <div
        className={contentClassNames}
        style={style}
        data-testid="SignedInUser"
      >
        {!is_confirmed && !isEvent ? null : showRegistrationForm ? (
          <>
            <RegistrationForm
              touchpoint={touchpoint}
              onComplete={onComplete}
              isCandidate={true}
              isJoinWaitlist={isJoinWaitlist}
              showBackbutton={true}
              handleBackButton={() => setShowRegistrationForm(false)}
              onClose={onClose}
            />
          </>
        ) : (
          <>
            <div className={cn(styles.section, styles.top)}>
              <div className={styles.header}>
                <p className={styles.title}>
                  {isJoinWaitlist
                    ? t('signed-in-registration-detail-modal.join-waitlist')
                    : isEvent
                    ? t('signed-in-registration-detail-modal.register')
                    : t('signed-in-registration-detail-modal.apply')}
                </p>
                <IconButton
                  size="xsmall"
                  iconName="close"
                  className={styles.cancelIcon}
                  onClick={onClose}
                />
              </div>
            </div>
            <div className={styles.content}>
              <p className={styles.desc}>
                {isEvent
                  ? t('signed-in-registration-detail-modal.registration')
                  : t('signed-in-registration-detail-modal.application')}{' '}
                {t('signed-in-registration-detail-modal.heading')}
              </p>
              <Row align="center" justify="space-between">
                <p className={styles.profileTitle}>
                  {t('signed-in-registration-detail-modal.sub-heading')}
                </p>
                <Button
                  onClick={() => {
                    onComplete(FLAG_STATUS_CODES.REGISTER_FAILURE);
                    router.push(PAGE_ROUTES.CANDIDATE_PROFILE);
                  }}
                  variant="text"
                  className={styles.profileButton}
                >
                  {t('signed-in-registration-detail-modal.edit-in-profile')}
                </Button>
              </Row>
              <section className={styles.profileDetails}>
                {first_name || last_name ? (
                  <>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t('signed-in-registration-detail-modal.name')}
                      </p>
                      <p className={styles.value}>
                        {first_name} {last_name}
                      </p>
                    </Row>
                    <div className={styles.separator}></div>
                  </>
                ) : null}
                <Row
                  align="center"
                  justify="space-between"
                  className={styles.profileDetailsRow}
                >
                  <p className={styles.label}>
                    {t('signed-in-registration-detail-modal.email')}
                  </p>
                  <p className={styles.value}>{email}</p>
                </Row>
                {(university_required ||
                  university_required_state !== QuestionState.OFF) &&
                educationHistories.university_name ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t('signed-in-registration-detail-modal.university')}
                      </p>
                      <p className={styles.value}>
                        {educationHistories?.university_name}
                      </p>
                    </Row>
                  </>
                ) : null}
                {(study_subject_required ||
                  study_subject_required_state !== QuestionState.OFF) &&
                educationHistories.subject_id ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t('signed-in-registration-detail-modal.subject')}
                      </p>
                      <p className={styles.value}>
                        {educationHistories.subject_name}
                      </p>
                    </Row>
                  </>
                ) : null}
                {(degree_required ||
                  degree_required_state !== QuestionState.OFF) &&
                educationHistories.degree_id ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t('signed-in-registration-detail-modal.degree')}
                      </p>
                      <p className={styles.value}>
                        {educationHistories.degree_name}
                      </p>
                    </Row>
                  </>
                ) : null}
                {(study_year_required ||
                  study_year_required_state !== QuestionState.OFF) &&
                educationHistories.graduationYear ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t(
                          'signed-in-registration-detail-modal.graduation-year'
                        )}
                      </p>
                      <p className={styles.value}>
                        {educationHistories.graduationYear}
                      </p>
                    </Row>
                  </>
                ) : null}
                {(linkedin_profile_required ||
                  linkedin_profile_required_state !== QuestionState.OFF) &&
                linkedin_url ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t(
                          'signed-in-registration-detail-modal.linkedin-profile'
                        )}
                      </p>
                      <p className={styles.value}>{linkedin_url}</p>
                    </Row>
                  </>
                ) : null}
                {(cv_required || cv_required_state !== QuestionState.OFF) &&
                resume_url ? (
                  <>
                    <div className={styles.separator}></div>
                    <Row
                      align="center"
                      justify="space-between"
                      className={styles.profileDetailsRow}
                    >
                      <p className={styles.label}>
                        {t('signed-in-registration-detail-modal.cv')}
                      </p>
                      <p className={styles.value}>{resume?.filename}</p>
                    </Row>
                  </>
                ) : null}
              </section>
            </div>
            <div className={cn(styles.section, styles.bottom)}>
              <div className={styles.separator}></div>
              <div className={styles.footer}>
                {isInfoIncomplete ? (
                  <ButtonV2
                    onClick={() => setShowRegistrationForm(true)}
                    className={styles.button}
                  >
                    {t('signed-in-registration-detail-modal.next')}
                  </ButtonV2>
                ) : (
                  <ButtonV2
                    onClick={() => onRegister()}
                    isLoading={isLoading}
                    className={styles.button}
                  >
                    {isEvent
                      ? isJoinWaitlist
                        ? t('signed-in-registration-detail-modal.join-waitlist')
                        : t(
                            'signed-in-registration-detail-modal.complete-registration'
                          )
                      : t(
                          'signed-in-registration-detail-modal.submit-application'
                        )}
                  </ButtonV2>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);

SignedInUser.displayName = 'SignedInUser';
