import React, { ReactElement, useMemo } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { IconName } from 'components';
import { ButtonV3, DropdownV3 } from 'components/ComponentV2';

import { PAGE_ROUTES } from 'lib/page-routes';
import styles from '../ManagerFilters.module.scss';
import { FiltersMode } from '../index.page';
import { ManagerFilterType } from '../../Manager.component';

export enum OPPORTUNITY_TYPE {
  JOB = 'Job',
  INTERNSHIP = 'Internship',
  EVENT = 'Event',
}

const OpportunityFilters = ({
  mode,
  selectedTouchpoint,
  selectedOpportunity,
  onClickFilter,
}: {
  mode: FiltersMode;
  selectedTouchpoint: string;
  selectedOpportunity?: string;
  onClickFilter: (key: string, value: string) => void;
}): ReactElement => {
  const router = useRouter();

  const opportunityFilters = [
    ...(selectedTouchpoint !== ManagerFilterType.Registered
      ? [
          {
            id: '1',
            label: 'Jobs',
            iconName: 'icon_briefcase' as IconName,
            value: OPPORTUNITY_TYPE.JOB,
            onClick: (value: string) =>
              onClickFilter('touchpointable_types', value),
          },
          {
            id: '2',
            label: 'Internships',
            iconName: 'internship' as IconName,
            value: OPPORTUNITY_TYPE.INTERNSHIP,
            onClick: (value: string) =>
              onClickFilter('touchpointable_types', value),
          },
        ]
      : []),
    ...(selectedTouchpoint !== ManagerFilterType.Applied
      ? [
          {
            id: '3',
            label: 'Events',
            iconName: 'icon_party' as IconName,
            value: OPPORTUNITY_TYPE.EVENT,
            onClick: (value: string) =>
              onClickFilter('touchpointable_types', value),
          },
        ]
      : []),
  ];

  const selectedFilterIcon = useMemo(() => {
    const labelsList = opportunityFilters
      ?.filter((item) => {
        if (selectedOpportunity === item.value) return item.iconName;
      })
      .map((item) => item.iconName);
    return labelsList?.join(', ') || '';
  }, [selectedOpportunity]);

  const selectedFilterLabel = useMemo(() => {
    const labelsList = opportunityFilters
      ?.filter((item) => {
        if (selectedOpportunity === item.value) return item.label;
      })
      .map((item) => item.label);
    return labelsList?.join(', ') || '';
  }, [selectedOpportunity]);

  return (
    <>
      {mode === FiltersMode.Dropdown ? (
        <DropdownV3
          label=""
          value={selectedOpportunity || ''}
          onChange={(value) => onClickFilter('touchpointable_types', value)}
          options={opportunityFilters || []}
          selectedValueLabel={selectedFilterLabel || ''}
          isValid={true}
          className={styles.dropdown}
          dropdownBtnClassName={styles.dropdownButton}
          iconClassName={styles.icon}
          startIcon={(selectedFilterIcon as IconName) || undefined}
          startIconClassName={styles.startIcon}
          displayShowAllButton={true}
          onClickShowAll={() => {
            onClickFilter('touchpointable_types', '');
            router.push(`${PAGE_ROUTES.MANAGER}?status=${selectedTouchpoint}`);
          }}
        />
      ) : (
        <>
          {opportunityFilters.map(({ id, label, value, iconName, onClick }) => (
            <ButtonV3
              key={id}
              className={cn(styles.ManagerFiltersMultiselectWrapper)}
              type="button"
              startIcon={(iconName as IconName) || undefined}
              iconSize={'medium'}
              color={'silent'}
              onClick={() => onClick(value)}
            >
              <p className={styles.ManagerFiltersMultiselectButtonTitle}>
                {label}
              </p>
            </ButtonV3>
          ))}
        </>
      )}
    </>
  );
};

export default OpportunityFilters;
