import React, { memo } from 'react';
import cn from 'classnames';

import { Icon } from 'components';
import Avatar from 'components/Avatar';
import styles from './CardLogo.module.scss';

export interface CardLogoProps {
  /** Custom class name */
  className?: string;
  /** Custom style */
  style?: Record<string, unknown>;
  is_verified: boolean;
  logo_url: string;
  initials: string;
  verifiedClassName?: string;
  size?: 'small' | 'large';
  altLogoText?: string;
  huzzle_verified: boolean;
  cohostsLogoDetails: {
    icon_url: string;
    is_verified: boolean;
    huzzle_verified: boolean;
    initials: string;
  } | null;
}
// todo make changes to take huzzle verified and cohost avatars
export const CardLogo: React.FC<CardLogoProps> = memo(
  ({
    className = '', // custom class name
    style, // custom style
    is_verified,
    logo_url,
    initials,
    verifiedClassName = '',
    size = 'large',
    altLogoText = '',
    huzzle_verified,
    cohostsLogoDetails,
  }: CardLogoProps) => {
    const contentClassNames = cn(
      {
        [styles.CardLogo]: true,
        [styles.CardLogoSmall]: size === 'small',
      },
      className
    );

    const getCohostAvatar = () => {
      if (cohostsLogoDetails) {
        const { icon_url, is_verified, huzzle_verified, initials } =
          cohostsLogoDetails;
        return (
          <div className={cn(styles.logoContainer, styles.cohost)}>
            <Avatar
              icon={icon_url}
              name={initials}
              altText={initials}
              size="small"
              className={cn(styles.logo, verifiedClassName)}
            />
            {huzzle_verified ? (
              <Icon
                iconName="icon_star"
                size={'auto'}
                className={cn(styles.verifiedHuzzle, verifiedClassName)}
              />
            ) : is_verified ? (
              <Icon
                iconName="tick-mark-circle"
                size={'auto'}
                className={cn(styles.verified, verifiedClassName)}
              />
            ) : null}
          </div>
        );
      } else return null;
    };

    return (
      <div className={contentClassNames} style={style} data-testid="CardLogo">
        <div className={cn(styles.logoContainer, styles.host)}>
          <Avatar
            icon={logo_url}
            name={initials}
            altText={altLogoText}
            size="small"
            className={cn(styles.logo, verifiedClassName)}
          />
          {huzzle_verified ? (
            <Icon
              iconName="icon_star"
              size={'auto'}
              className={cn(styles.verifiedHuzzle, verifiedClassName)}
            />
          ) : is_verified ? (
            <Icon
              iconName="tick-mark-circle"
              size={'auto'}
              className={cn(styles.verified, verifiedClassName)}
            />
          ) : null}
        </div>
        {getCohostAvatar()}
      </div>
    );
  }
);

CardLogo.displayName = 'CardLogo';
