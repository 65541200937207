import { useMemo } from 'react';
import useSWRInfinite from 'swr/infinite';
import { useRecoilValue } from 'recoil';
import queryString from 'query-string';

import { ManagerFilterType } from 'pages/candidate/manager/Manager.component';
import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { get } from 'lib/utils/http';
import { API_ROUTES } from 'lib/api-routes';
import {
  ManagerOpportunitiesResponse,
  OpportunityAttributes,
} from 'lib/models/opportunity';
import { parsePaginatedResponse } from 'lib/utils/parser';
import { SelectedFilterOptions } from './useFilter';

export interface ReturnType {
  touchpoints: OpportunityAttributes[];
  isLoading: boolean;
  isLoadingMore?: boolean;
  hasMoreRecords: boolean;
  touchPointsMetaInfo: { total?: number; search_page?: string | null };
  size: number;
  setSize: (size: number) => void;
  isValidating: boolean;
  mutate: () => void;
}
const PER_PAGE_COUNT = 5;

export const useManagerTouchpoints = ({
  eventType,
  activeFilters,
  search,
}: {
  eventType: string;
  activeFilters?: SelectedFilterOptions | null;
  search?: string | null;
}): ReturnType => {
  const { headers } = useRecoilValue(httpHeadersState);

  const constructEndpoint = () => {
    let actionType = eventType.toLowerCase();
    if (eventType === ManagerFilterType.Saved_And_Started_Expired) {
      actionType = 'saved_and_started';
    } else if (eventType === ManagerFilterType.Registered) {
      actionType = 'accepted';
    } else if (eventType === ManagerFilterType.Saved_Expired) {
      actionType = 'saved';
    } else if (eventType === ManagerFilterType.Started_Expired) {
      actionType = 'started';
    }
    const actionTypeParam = `action_type=${actionType}`;

    const getTouchpointableTypesParam = () => {
      const touchpointType =
        Array.isArray(activeFilters?.touchpoint_type) &&
        activeFilters?.touchpoint_type[0];
      const touchpointableType =
        Array.isArray(activeFilters?.touchpointable_types) &&
        activeFilters?.touchpointable_types[0];

      if (eventType === ManagerFilterType.Applied) {
        return touchpointType === ManagerFilterType.Applied &&
          touchpointableType
          ? ''
          : '&touchpointable_types[]=Job&touchpointable_types[]=Internship';
      }
      if (eventType === ManagerFilterType.Registered) {
        return touchpointType === ManagerFilterType.Registered &&
          touchpointableType
          ? ''
          : '&touchpointable_types[]=Event';
      }
      return '';
    };

    if (
      eventType === ManagerFilterType.Applied ||
      eventType === ManagerFilterType.Registered
    ) {
      return `${
        API_ROUTES.CAMPAIGNS
      }?${actionTypeParam}${getTouchpointableTypesParam()}&with_past=true`;
    }
    if (
      [
        ManagerFilterType.Saved_And_Started_Expired,
        ManagerFilterType.Saved_Expired,
        ManagerFilterType.Started_Expired,
      ].includes(eventType as ManagerFilterType)
    ) {
      return `${API_ROUTES.CAMPAIGNS}?${actionTypeParam}&with_details=true&past=true`;
    }
    if (eventType) {
      return `${API_ROUTES.CAMPAIGNS}?${actionTypeParam}&with_details=true`;
    }
    return '';
  };

  const constructQueryString = (pageIndex: number) => {
    const { touchpoint_type, country_ids, ...otherFilters } =
      activeFilters ?? {};

    const params = {
      q: search,
      page: pageIndex + 1,
      per_page: PER_PAGE_COUNT,
      remote_country_ids: country_ids,
      ...otherFilters,
    };

    return queryString.stringify(params, {
      arrayFormat: 'bracket',
      skipEmptyString: true,
    });
  };

  const getTouchPointKey = (pageIndex: number) => {
    if (eventType) {
      return [
        `${constructEndpoint()}${
          eventType === ManagerFilterType.Expired ? '?' : '&'
        }${constructQueryString(pageIndex)}`,
        headers,
      ];
    }
    return null;
  };

  const {
    data: touchPointResponse,
    error: touchPointError,
    size,
    setSize,
    mutate,
    isValidating,
  } = useSWRInfinite<ManagerOpportunitiesResponse>(getTouchPointKey, get, {
    revalidateOnFocus: false,
    revalidateFirstPage: true,
  });

  const touchpoints = useMemo(() => {
    if (!touchPointResponse) return [];
    const parsedTouchPoints = parsePaginatedResponse(touchPointResponse);
    return parsedTouchPoints;
  }, [touchPointResponse]);

  const touchPointsMetaInfo = useMemo(() => {
    return touchPointResponse?.[0]?.meta || {};
  }, [touchPointResponse]);

  const hasMoreRecords = useMemo(() => {
    const firstTouchPointList = Array.isArray(touchPointResponse)
      ? touchPointResponse[0]
      : null;
    if (firstTouchPointList) {
      return (firstTouchPointList?.meta?.total || 0) > size * PER_PAGE_COUNT;
    } else {
      return false;
    }
  }, [touchPointResponse, size]);

  const isLoading = !(touchPointResponse || touchPointError);

  const isLoadingMore =
    isLoading ||
    (size > 0 &&
      touchPointResponse &&
      typeof touchPointResponse[size - 1] === 'undefined');

  return {
    touchpoints,
    isLoading,
    isLoadingMore,
    hasMoreRecords,
    touchPointsMetaInfo,
    size,
    setSize,
    isValidating,
    mutate,
  };
};
