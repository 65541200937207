export enum QuestionState {
  REQUIRED = 'required',
  OPTIONAL = 'optional',
  OFF = 'off',
  DELETE = 'delete',
  EDIT = 'edit',
}

export interface AnswerAttributes {
  id: string;
  name: string;
  value: string;
  question: QuestionResponse;
}

export interface QuestionAttributes {
  id?: string;
  title: string;
  placeholder?: string;
  state: QuestionState;
  question_type: string;
  options: string[];
  required: boolean;
}

export interface Answer {
  id: string;
  type: 'answer';
  attributes: AnswerAttributes;
}

export interface Questions {
  id: string;
  type: 'question';
  attributes: QuestionAttributes;
}

export interface AnswersResponse {
  data: Array<Answer>;
}

export interface AnswerResponse {
  data: Answer;
}

export interface QuestionResponse {
  data: Questions;
}

export interface QuestionsResponse {
  data: Array<Questions>;
}
