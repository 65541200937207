import React, {
  ReactElement,
  useMemo,
  MutableRefObject,
  Dispatch,
  SetStateAction,
} from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';

import { noop } from 'lodash';
import MultiSelect from 'components/MultiSelect';
import { ButtonV3, DropdownV3 } from 'components/ComponentV2';
import {
  SelectedFilterOptions,
  SelectedFilterOptionLabels,
  fieldType,
} from 'hooks/useFilter';
import { useCommonList } from 'lib/contexts/common-list-context';
import { Option } from 'lib/models/option';
import { PAGE_ROUTES } from 'lib/page-routes';
import { MultiRefs } from '../index.page';

import { ManagerFilterType } from '../../Manager.component';
import { OPPORTUNITY_TYPE } from '../OpportunityFilters';

import styles from '../ManagerFilters.module.scss';

const Filters = ({
  filters,
  onChangeFilter,
  assignRefs,
  setSelectedOptions,
  selectedLabelsRef,
}: {
  filters?: SelectedFilterOptions | undefined;
  onChangeFilter: (
    key: string,
    values: Array<string>,
    labels: string[]
  ) => void;
  assignRefs: (key: number, value: MultiRefs | null) => void;
  setSelectedOptions: Dispatch<SetStateAction<SelectedFilterOptions>>;
  selectedLabelsRef: MutableRefObject<SelectedFilterOptionLabels>;
}): ReactElement => {
  const router = useRouter();
  const { status, entity } = router?.query;

  const {
    topicOptions,
    universities,
    offeringOptions,
    industryOptions,
    employeesCountRange,
    datePostedList,
    areaOfResponsibilities,
    workModeList,
    workTypeList,
    cityCountryList,
    countries,
    eventCategoriesList,
    eventTypeOptions,
    internshipKindOptions,
  } = useCommonList();

  const getIdsFromValues = (
    options: Array<Option> | undefined,
    values: Array<string>
  ) => {
    return values.map((val) => {
      const option = options?.find((opt) => opt.value === val);
      return option?.id;
    });
  };

  const getValuesFromIds = (
    options: Array<Option> | undefined,
    values: Array<string>
  ) => {
    return values.map((val) => {
      const option = options?.find((opt) => opt.id === val);
      return option?.value;
    });
  };

  const getLabelsFromIds = (
    options: Array<Option> | undefined,
    values: Array<string>
  ) => {
    return values.map((val) => {
      const option = options?.find((opt) => opt.id === val);
      return option?.label;
    });
  };

  const getLabelsFromValues = (
    options: Array<Option> | undefined,
    values: string | boolean | string[] | undefined
  ) => {
    // Ensure values is an array of strings
    let stringValues: string[] = [];

    if (Array.isArray(values)) {
      stringValues = values;
    } else if (typeof values === 'string') {
      stringValues = [values];
    }

    if (options && Array.isArray(options) && stringValues.length > 0) {
      return getValuesFromIds(options, stringValues);
    }

    return [];
  };

  const selectedValueLabel = (
    options: Array<Option> | undefined,
    value: string
  ) => {
    const labelsList = options
      ?.filter((item) => {
        if (value === item.value) return item.label;
      })
      .map((item) => item.label);
    return labelsList?.join(', ') || '';
  };

  const societyFilter = [
    {
      title: 'Topics',
      type: fieldType.MULTI_SELECT_TICK,
      options: topicOptions,
      values: getLabelsFromValues(topicOptions, filters?.['topic_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(topicOptions, values);
        onChangeFilter('topic_ids', ids as string[], values);
      },
    },
    {
      title: 'University',
      type: fieldType.MULTI_SELECT_TICK,
      options: universities,
      values: getLabelsFromValues(universities, filters?.['university_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(universities, values);
        const labels = getLabelsFromIds(universities, values);
        onChangeFilter('university_ids', ids as string[], labels as string[]);
      },
    },
    {
      title: 'Offerings',
      type: fieldType.MULTI_SELECT_TICK,
      options: offeringOptions,
      values: getLabelsFromValues(offeringOptions, filters?.['offering_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(offeringOptions, values);
        onChangeFilter('offering_ids', ids as string[], values);
      },
    },
    {
      title: 'Verified',
      type: fieldType.TOGGLE,
      options: [],
      values: filters?.['verified'],
      isSearchable: false,
      onChange: () => noop,
    },
  ];

  const companyFilter = [
    {
      title: 'Industry',
      type: fieldType.MULTI_SELECT_TICK,
      options: industryOptions,
      values: getLabelsFromValues(industryOptions, filters?.['industry_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(industryOptions, values);
        onChangeFilter('industry_ids', ids as string[], values);
      },
    },
    {
      title: 'Company Size',
      type: fieldType.MULTI_SELECT_TICK,
      options: employeesCountRange,
      values: getLabelsFromValues(
        employeesCountRange,
        filters?.['employees_count']
      ),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(employeesCountRange, values);
        onChangeFilter('employees_count', ids as string[], values);
      },
    },
    {
      title: 'Verified',
      type: fieldType.TOGGLE,
      options: [],
      values: filters?.['verified'],
      isSearchable: false,
      onChange: () => noop,
    },
  ];

  const eventFilters = [
    {
      title: 'Date posted',
      type: fieldType.SINGLE_SELECT_TICK,
      options: datePostedList,
      values: filters?.['posted_after'],
      isSearchable: true,
      onChange: () => noop,
    },
    {
      title: 'Topics',
      type: fieldType.MULTI_SELECT_TICK,
      options: topicOptions,
      values: getLabelsFromValues(topicOptions, filters?.['topic_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(topicOptions, values);
        onChangeFilter('topic_ids', ids as string[], values);
      },
    },
    {
      title: 'Category',
      type: fieldType.MULTI_SELECT_TICK,
      options: eventCategoriesList,
      values: getLabelsFromValues(
        eventCategoriesList,
        filters?.['event_category_ids']
      ),
      isSearchable: false,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(eventCategoriesList, values);
        onChangeFilter('event_category_ids', ids as string[], values);
      },
    },
    {
      title: 'Event Type',
      type: fieldType.MULTI_SELECT_TICK,
      options: eventTypeOptions,
      values: filters?.['kinds'] || [],
      isSearchable: false,
      onChange: (values: Array<string>) => {
        onChangeFilter('kinds', values as string[], values);
      },
    },
    {
      title: 'Industry',
      type: fieldType.MULTI_SELECT_TICK,
      options: industryOptions,
      values: getLabelsFromValues(industryOptions, filters?.['industry_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(industryOptions, values);
        onChangeFilter('industry_ids', ids as string[], values);
      },
    },
    {
      title: 'Location',
      type: fieldType.MULTI_SELECT_TICK,
      options: cityCountryList,
      values: getLabelsFromValues(cityCountryList, filters?.['city_ids']),
      isSearchable: false,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(cityCountryList, values);
        onChangeFilter('city_ids', ids as string[], values);
      },
    },
    {
      title: 'Remote From',
      type: fieldType.MULTI_SELECT_TICK,
      options: countries,
      values: getLabelsFromValues(countries, filters?.['country_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(countries, values);
        onChangeFilter('country_ids', ids as string[], values);
      },
    },
  ];

  const jobsFilters = [
    {
      title: 'Date posted',
      type: fieldType.SINGLE_SELECT_TICK,
      options: datePostedList,
      values: filters?.['posted_after'],
      isSearchable: true,
      onChange: () => noop,
    },
    {
      title: 'Area of responsibility',
      type: fieldType.MULTI_SELECT_TICK,
      options: areaOfResponsibilities,
      values: getLabelsFromValues(
        areaOfResponsibilities,
        filters?.['area_of_responsibility_ids']
      ),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(areaOfResponsibilities, values);
        const labels = getLabelsFromIds(areaOfResponsibilities, values);
        onChangeFilter(
          'area_of_responsibility_ids',
          ids as string[],
          labels as string[]
        );
      },
    },
    {
      title: 'Location',
      type: fieldType.MULTI_SELECT_TICK,
      options: cityCountryList,
      values: getLabelsFromValues(cityCountryList, filters?.['city_ids']),
      isSearchable: false,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(cityCountryList, values);
        onChangeFilter('city_ids', ids as string[], values);
      },
    },
    {
      title: 'Remote From',
      type: fieldType.MULTI_SELECT_TICK,
      options: countries,
      values: getLabelsFromValues(countries, filters?.['country_ids']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(countries, values);
        const labels = getLabelsFromIds(countries, values);
        onChangeFilter('country_ids', ids as string[], labels as string[]);
      },
    },
    {
      title: 'Work mode',
      type: fieldType.MULTI_SELECT_TICK,
      options: workModeList,
      values: getLabelsFromValues(workModeList, filters?.['working_model']),
      isSearchable: true,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(workModeList, values);
        onChangeFilter('working_model', ids as string[], values);
      },
    },
    {
      title: 'Work Type',
      type: fieldType.MULTI_SELECT_TICK,
      options: workTypeList,
      values: getLabelsFromValues(workTypeList, filters?.['work_type']),
      isSearchable: false,
      onChange: (values: Array<string>) => {
        const ids = getIdsFromValues(workTypeList, values);
        onChangeFilter('work_type', ids as string[], values);
      },
    },
  ];

  const internshipFilters = [
    {
      title: 'Internship Type',
      type: fieldType.MULTI_SELECT_TICK,
      options: internshipKindOptions,
      values: filters?.['kinds'] || [],
      isSearchable: false,
      onChange: (values: Array<string>) => {
        onChangeFilter('kinds', values as string[], values);
      },
    },
    ...jobsFilters,
  ];

  const getFiltersList = () => {
    if (router.pathname === PAGE_ROUTES.MANAGER) {
      if (entity === ManagerFilterType.Companies) {
        return companyFilter;
      } else if (entity === ManagerFilterType.Societies) {
        return societyFilter;
      } else if (entity === OPPORTUNITY_TYPE.JOB) {
        return jobsFilters;
      } else if (entity === OPPORTUNITY_TYPE.INTERNSHIP) {
        return internshipFilters;
      } else if (entity === OPPORTUNITY_TYPE.EVENT) {
        return eventFilters;
      }
    }
    return [];
  };

  const filtersList = useMemo(
    () => getFiltersList(),
    [router, filters, status, entity]
  );

  return (
    <>
      {filtersList?.length ? <div className={styles.seperator}></div> : null}
      {filtersList.map(
        ({ title, options, values, onChange, isSearchable, type }, index) => {
          return type === fieldType.MULTI_SELECT_TICK ? (
            <MultiSelect
              key={title}
              title={title}
              options={(options as Array<Option>) || []}
              className={cn({
                [styles.MultiselectWrapper]: true,
              })}
              values={values as string[]}
              onChange={onChange}
              triggerClassName={cn(styles.Multiselect, {
                [styles.MultiselectSelected]:
                  Array.isArray(values) && values?.length > 0,
              })}
              isEllipsisLabel={false}
              triggerOpenClassName={styles.MultiselectOpen}
              buttonTitleClassName={styles.MultiselectButtonTitle}
              dropdownClassName={styles.multiDropdown}
              checkBoxClassName={styles.checkbox}
              iconClassName="icon_arrow-down"
              isSearchable={isSearchable}
              showCountWithLabel={true}
              showCount={false}
              ref={(el) => assignRefs(index, el)}
            />
          ) : type === fieldType.SINGLE_SELECT_TICK ? (
            <DropdownV3
              label={title}
              value={typeof values === 'string' ? values : ''}
              onChange={(value: string) => {
                const label = selectedValueLabel(options, value);
                selectedLabelsRef.current['posted_after'] = label;
                setSelectedOptions({ ...filters, posted_after: value });
              }}
              options={(options as Array<Option>) || []}
              selectedValueLabel={
                typeof values === 'string'
                  ? selectedValueLabel(options, values)
                  : ''
              }
              isValid={true}
              className={styles.dropdownV3}
              dropdownBtnClassName={cn(styles.dropdownButtonSecond, {
                [styles.dropdownButtonSecondSelected]:
                  typeof values === 'string' && values,
              })}
              iconClassName={styles.icon}
              showSelectionTick={true}
            />
          ) : type === fieldType.TOGGLE ? (
            <ButtonV3
              key={title}
              className={cn(styles.ManagerFiltersMultiselectWrapper)}
              type="button"
              color={values ? 'primary' : 'silent'}
              onClick={() => {
                setSelectedOptions({ ...filters, verified: !values });
              }}
            >
              <p className={styles.ManagerFiltersMultiselectButtonTitle}>
                {title}
              </p>
            </ButtonV3>
          ) : (
            <></>
          );
        }
      )}
    </>
  );
};

export default Filters;
